/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import withGracefulUnmount from 'react-graceful-unmount'

// react component used to create a calendar with events on it
import Agenda from "react-big-calendar";
import BigCalendar from "react-big-calendar";
import Datetime from "react-datetime";
import moment from "moment";

import Badge from "components/Badge/Badge.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardText from "components/Card/CardText.jsx";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Email from "@material-ui/icons/Email";
import Heading from "components/Heading/Heading.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ProductCard from "components/Products/ProductCard.jsx";
import Slide from "@material-ui/core/Slide";
import Snackbar from "components/Snackbar/Snackbar.jsx";

import api from "state/api";
import combineStyles from 'assets/jss/material-dashboard-pro-react/combineStyles.jsx'
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import productCardStyle from "assets/jss/material-dashboard-pro-react/views/productCardStyle.jsx";
import reportStyles from "assets/jss/material-dashboard-pro-react/views/reportStyles.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

const localizer = BigCalendar.momentLocalizer(moment);

const AgendaContainer = ({ onSelectEvent, classes }) => props => {
    return <ProductAgenda event={props} onSelectEvent={onSelectEvent} classes={classes} />;
}

const MonthlyContainer = ({ onSelectEvent, classes }) => props => {
    return <ProductMonthly event={props} onSelectEvent={onSelectEvent} classes={classes} />;
}

const ProductAgenda = React.memo((props) => {
  const { classes, event, onSelectEvent } = props;
  return (
      <div onClick={(e) => onSelectEvent(event.event)} className={classes.agendaEmbed}>
        <span>
          <strong>{event.title}</strong> [{event.event.sku}] {event.event.shipping_type}
        </span>
        <div className={classes.agendaCount}>
          {event.event.count}
        </div>
      </div>
  );
})

const ProductMonthly = React.memo((props) => {
  const { classes, event, onSelectEvent } = props;
  const end = moment(event.event.end).format('h:mm A');
  return (
      <div onClick={(e) => onSelectEvent(event.event)} className={classes.calendarEmbed}>
        <div className={classes.monthlyCount}>
          {event.event.count}
        </div>
        <div className={classes.shippingType}>
          {event.event.shipping_type.match(/\b\w/g).join('')}
        </div>
        <span>
          {event.title}
        </span>
        <div className={classes.monthlyExpiration}>
          {end}
        </div>
      </div>
  );
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class FulfillmentOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredProducts: [],
      length: 31,
      notification: null,
      products: [],
      selectedDate: new Date(),
      selectedReleaseDate: null,
      selectedReleaseDateClicked: null,
      selectedProduct: null,
      selectedView: 'month',
      settingRelease: false
    };
    this.onSelectEvent = this.onSelectEvent.bind(this);
    this.textEntered = "";
  }

  componentWillUnmount() {
    localStorage.setItem('FullfillmentOverview', JSON.stringify(this.state))
  }

  componentWillMount() {
    let rehydrate = JSON.parse(localStorage.getItem('FullfillmentOverview'));
    if (rehydrate) {
      rehydrate.notification = null;
      rehydrate.filteredProducts = [];
      rehydrate.products = [];
      rehydrate.selectedDate = new Date(rehydrate.selectedDate);
      rehydrate.selectedReleaseDate = rehydrate.selectedReleaseDate ? new Date(rehydrate.selectedReleaseDate) : null;
      rehydrate.selectedReleaseDateClicked = rehydrate.selectedReleaseDateClicked ? new Date(rehydrate.selectedReleaseDateClicked) : null;
      this.setState(rehydrate)
    }
  }

  onSelectEvent(event) {
    fetch(`/api/products/${event.id}/${event.shipping_type}`)
      .then(response => api.authCheck(response))
      .then(data => {
        var product = data['data'];

        // Break apart the permutations
        const uih = this;
        Object.keys(product.permutations).forEach(key => {
          var statuses = key.split("|");
          var fulfillmentStatus = statuses[0];
          var printTagStatus = statuses[1];
          var purchaseStatus = statuses[2];
          var p = product.permutations[key];
          var prod = {};

          if (p.line_items && p.line_items.length > 0) {
            product.released_at = p.line_items[0].released_at;
          }
          prod.status = fulfillmentStatus;
          prod.print_status = printTagStatus;
          prod.purchase_status = purchaseStatus;
          p.color = uih.calculateColor(prod);
          p.color = p.color.replace(/-([a-z])/g, (x, up) => up.toUpperCase());
        })

        this.setState({ selectedProduct: product });

        if (product.released_at != null) {
          this.setState({ selectedReleaseDate: moment(product.released_at, "YYYY-MM-DD HH:mm:ss Z") });
        }
       });
  }

  onNavigate = (newDate) => {
    // Add a few days in case we jumped to the end of a month due to the length
    const date = moment(newDate).add(5, 'days');
    if (this.state.selectedView == "agenda") {
      const firstDay = moment(date).startOf('month').toDate();
      const length = moment(date).daysInMonth();
      this.setState({ selectedDate: firstDay });
      this.setState({ length: length });
    } else {
      this.setState({ selectedDate: newDate });
    }

    this.loadEventsForMonthOfDate(date);
  }

  // Make sure the list view is displaying by month
  onView = (view) => {
    if (view == "agenda") {
      const date = this.state.selectedDate;
      const firstDay = moment(date).startOf('month').toDate();
      const length = moment(date).daysInMonth();
      this.setState({ selectedDate: firstDay });
      this.setState({ length: length });
    }
    this.setState({ selectedView: view });
  }

  calculateColor(prod) {
    var color = 'ready-to-process';
    if (prod.status.includes('Pending')) {
      if (prod.status.includes('Ready') || prod.status.includes('On Deck')) {
        color = 'expiring-today';
      } else if (prod.status.includes('Complete')) {
        color = 'purchasing-complete';
      } else {
        color = 'refund-required';
      }
    } else if (prod.status.includes('Complete')) {
      if (prod.status.includes('Ready')) {
        color = 'purchasing-complete';
      } else {
        color = 'print-tags-complete';
      }
    } else if (prod.status.includes('On Deck')) {
      if (prod.released_at != null) {
        color = 'expiring-tomorrow';
      } else {
        color = 'expired-pending';
      }
    }

    return color;
  }

  eventColors(event) {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor
    };
  }

  filterProducts(products) {
    if (this.textEntered.length == 0) {
      this.setState({filteredProducts: products})
    } else {
      let search = this.textEntered.toLowerCase()
      let filtered = this.state.products.filter(product => product.sku.toLowerCase().includes(search) || product.title.toLowerCase().includes(search))
      this.setState({filteredProducts: filtered})
    }
  }

  handleSearch(event) {
    const value = event.target.value;
    this.textEntered = value;
    setTimeout(() => {
      if (this.textEntered === value) {
        this.filterProducts(this.state.products);
      }
    }, 500 );
    return;
  }

  loadEventsForMonthOfDate(date) {
    const dateString = moment(date).format('YYYY-MM-DD');
    fetch(`/api/products?reportType=fulfillment&date=${dateString}`)
      .then(response => api.authCheck(response))
      .then(data => {
        var products = this.state.filteredProducts;
        data['data'].forEach(prod => {
          var color = this.calculateColor(prod);
          var sku = prod.skus.split("|").reduce((a,b) => { return a.length > b.length ? a : b; }); // Get longest SKU
          if ((sku.match(/-/g) || []).length > 1) {
            sku = sku ? sku.substring(0, sku.lastIndexOf("-")) : "";
          }

          // Figure out permutation count
          var permutations = [];
          var i;
          const statuses = prod.status.split("|");
          const printStatuses = prod.print_status.split("|");
          const purchaseStatuses = prod.purchase_status.split("|");
          for (i = 0; i < statuses.length && i < printStatuses.length && i < purchaseStatuses.length; i++) {
            const status = statuses[i];
            const printStatus = printStatuses[i];
            const purchaseStatus = purchaseStatuses[i];
            const perm = status + "|" + printStatus + "|" + purchaseStatus;
            if (!permutations.includes(perm)) {
              permutations.push(perm);
            }
          }
          const title = permutations.length > 1 ? `[${permutations.length}] ${prod.title}` : prod.title;

          const downProduct = {title: title, allDay: false, start: new Date(prod.end), end: new Date(prod.end), color: color,
                             id: prod.id, count: prod.quantity, sku: sku, published: prod.published, shipping_type: prod.shipping_type};
          products = products.filter(product => !(product.id === prod.id && product.shipping_type === prod.shipping_type));
          products.push(downProduct);
        });
        this.setState({ products: products });
        this.filterProducts(products);
       });
  }

  refreshCalendar() {
    this.loadEventsForMonthOfDate(this.state.selectedDate);
    this.onSelectEvent(this.state.selectedProduct);
  }

  componentDidMount() {
    this.loadEventsForMonthOfDate(this.state.selectedDate);
  }

  handleCloseReleaseModal() {
    this.setState({selectedReleaseDate: null});
    this.setState({selectedReleaseDateClicked: false});
    this.setState({settingRelease: false});
  }

  handleDateTimeChange(date) {
    this.setState({selectedReleaseDate: date});
    this.setState({selectedReleaseDateClicked: true});
  }

  handleFulfillmentStatusChoice(newStatus, lineItemIds) {
    const data = lineItemIds.map(id => ({id: id, app_fulfillment_status: newStatus}));
    const body = {changes: data};
    fetch('/api/lineItems', {
      method: 'PUT',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(body)
    })
    .then(response => api.authCheck(response))
    .then(data => {
      this.showNotification(`Fulfillment status has been changed to ${newStatus}.`);
      this.refreshCalendar()
    });
  }

  handleOpenReleaseModal() {
    this.setState({settingRelease: true});
  }

  handlePrintTagStatusChoice(newStatus, lineItemIds) {
    const data = lineItemIds.map(id => ({id: id, print_tags_status: newStatus}));
    const body = {changes: data};
    fetch('/api/lineItems', {
      method: 'PUT',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(body)
    })
    .then(response => api.authCheck(response))
    .then(data => {
      this.showNotification(`Print tag status has been changed to ${newStatus}.`);
      this.refreshCalendar()
    });
  }

  handlePurchasingStatusChoice(newStatus, lineItemIds) {
    const data = lineItemIds.map(id => ({id: id, purchasing_status: newStatus}));
    const body = {changes: data};
    fetch('/api/lineItems', {
      method: 'PUT',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(body)
    })
    .then(response => api.authCheck(response))
    .then(data => {
      this.showNotification(`Purchasing status has been changed to ${newStatus}.`);
      this.refreshCalendar()
    });
  }

  handleSaveReleaseModal() {
    this.handleCloseReleaseModal();

    let lineItemIds = [];
    const product = this.state.selectedProduct;
    const perms = product.permutations;
    Object.keys(perms).forEach(key => {
      let perm = perms[key];
      perm.line_items.forEach(item => {
        lineItemIds.push(item.id);
      });
    })

    var release = this.state.selectedReleaseDate.utcOffset('-0400').format('YYYY-MM-DD HH:mm:ss');
    const data = lineItemIds.map(id => ({id: id, released_at: release}));
    const body = {changes: data};
    fetch('/api/lineItems', {
      method: 'PUT',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(body)
    })
    .then(response => api.authCheck(response))
    .then(data => {
      this.showNotification(`Ready notification date has been set.`);
      this.refreshCalendar()
    });
  }

  showNotification(message) {
    this.setState({notification: message});
    setTimeout(
      function() {
        this.setState({notification: null});
      }.bind(this),
      3000
    );
  }

  renderReleaseModal() {
    const { classes } = this.props;
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal
        }}
        open={this.state.settingRelease}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.handleCloseReleaseModal()}
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
      >
        <DialogContent
          id="notice-modal-slide-description"
          className={classes.modalBody}
        >
        <p>
          This will affect line items currently in the system. Ready notifications will only go out for line items moved <b>On Deck</b>.
        </p>

        <Datetime
          className={classes.dateTime}
          defaultValue={moment().startOf('day').hour(12).minute(50)}
          value={this.state.selectedReleaseDate}
          inputProps={{ placeholder: "Choose a time", className: classes.dateTimeInput, readOnly: true }}
          onChange={(e) => this.handleDateTimeChange(e)}
          />
        </DialogContent>
        <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter }>
          { this.state.selectedReleaseDate && this.state.selectedReleaseDateClicked &&
            <Button
              onClick={() => this.handleSaveReleaseModal()}
              color="info"
              round>
              Set Ready Notification Date
            </Button>
          }
        </DialogActions>
      </Dialog>
    );
  }

  renderSelectedProductCard() {
    const { classes } = this.props;
    const product = this.state.selectedProduct;
    const collections = product.collections;
    const hasRelease = product.released_at != null;
    const released = hasRelease ? new Date().getTime() > new Date(product.released_at).getTime() : false;
    const releasedButtonClass = hasRelease && released ? classes.alertButton : classes.actionButton;

    const hasExpiration = product.expires_at != null;
    const expired = hasExpiration ? new Date().getTime() > new Date(product.expires_at).getTime() : false;
    const expiredButtonClass = hasExpiration && expired ? classes.alertButton : classes.actionButton;

    // Formatted dates
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const timeOptions = { timeStyle: "short" };
    const releasedWording = hasRelease ? (released ? "Notified: " : "Scheduled: ") : "Not scheduled";
    const releasedString = hasRelease ? new Date(product.released_at).toLocaleString('en-us', options) + " @ " : "";
    const releasedTimeString = hasRelease ? new Date(product.released_at).toLocaleTimeString('en-us', timeOptions) : "";

    const expiredWording = hasExpiration ? (expired ? "Expired: " : "Expiring: ") : "No expiration set";
    const expiredString = hasExpiration ? new Date(product.expires_at).toLocaleString('en-us', options) + " @ " : "";
    const expiredTimeString = hasExpiration ? new Date(product.expires_at).toLocaleTimeString('en-us', timeOptions) : "";

    return (
        <div>
          <Card className={classes.productCard}>
            <CardHeader className={classes.headerButtonContainer} text>
              <div className={classes.headerButtons}>
                <Button
                  simple
                  onClick={() => this.handleOpenReleaseModal()}
                  className={releasedButtonClass}>
                  {releasedWording} {releasedString}{releasedTimeString}
                </Button>
              </div>
            </CardHeader>
            <div className={classes.contentDiv}>
              <span className={classes.productInfoSpan}>
                {product['art_number'] > 0 ? (
                  <div>
                    <i>Art #: {product['art_number']}</i>
                  </div>
                ) : null }
                <span className={classes.textHeavy}>
                  {product['title']}
                </span>
                <div>
                  {product.shipping_type}
                </div>
                <span>
                  {expiredWording} {expiredString}{expiredTimeString}
                </span>
                <br/>
                <span>
                  {product.variants.length} Variant{product.variants.length > 1 && 's'}
                </span>
                {collections && collections.length > 0 ? (
                  <small className={classes.textSmall}>
                    Available to {collections}
                  </small>
                ) : (
                  <small className={classes.textSmall}>
                    Not available in any collections
                  </small>
                )}
                <div dangerouslySetInnerHTML={{__html: product['body_html']}} />
              </span>
            </div>
          </Card>
        </div>
      );
  }

  render() {
    const { classes } = this.props;
    let components = {
      agenda: {
        event: AgendaContainer({ onSelectEvent: this.onSelectEvent, classes: classes })
      },
      month: {
        event: MonthlyContainer({ onSelectEvent: this.onSelectEvent, classes: classes })
      }
    }

    var permutations = [];
    if (this.state.selectedProduct) {
      Object.keys(this.state.selectedProduct.permutations).forEach(key => {
        permutations.push(key);
      })
    }

    return (
      <div>
        <Snackbar
          place="br"
          color="danger"
          message={this.state.notification}
          open={this.state.notification != null}
        />
        <div className={classes.center}>
          <div className={classes.innerSearchContainer}>
            <CustomInput
              id="sku"
              inputProps={{
                inputProps: {className: classes.largeText},
                onChange: (e) => this.handleSearch(e),
                placeholder: "Search SKU or product"
              }}
              formControlProps={{
                fullWidth: true
              }} />
            </div>
        </div>
        <h4 className={classes.center}>Date and time indicate when a product expired.</h4>
        <div className={classes.legend}>
          <div className={classes.legendInner}>
            <Badge color="refundRequiredGradient">Pending</Badge>
            <Badge color="expiringTodayGradient">Partially Ready</Badge>
            <Badge color="expiredPendingGradient">On Deck</Badge>
            <Badge color="expiringTomorrowGradient">Scheduled</Badge>
            <Badge color="readyToProcessGradient">Ready</Badge>
            <Badge color="purchasingCompleteGradient">Partially Complete</Badge>
            <Badge color="printTagsCompleteGradient">Complete</Badge>
          </div>
        </div>
        <GridContainer justify="center">
          {this.renderReleaseModal()}
          <GridItem xs={12} sm={12} md={10} className='calendar-grid'>
            <Card>
              <CardBody calendar>
                <BigCalendar
                  selectable
                  localizer={localizer}
                  events={this.state.filteredProducts}
                  components={components}
                  defaultView={this.state.selectedView}
                  views={['month', 'agenda']}
                  scrollToTime={new Date(1970, 1, 1, 6)}
                  date={this.state.selectedDate}
                  defaultDate={new Date()}
                  onSelectEvent={event => this.onSelectEvent(event)}
                  onNavigate={date => this.onNavigate(date)}
                  onView={view => this.onView(view)}
                  eventPropGetter={this.eventColors}
                  length={this.state.length}
                  drilldownView='agenda'
                  formats = {{
                    agendaHeaderFormat: ({start, end}) => {
                      return (moment.utc(start).format('MMMM YYYY'));
                    }
                  }}
                  popup='true'
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.selectedProduct &&
          <div>
            {this.renderSelectedProductCard()}
          </div>
        }
        {permutations.map(key =>
          <ProductCard
            product={this.state.selectedProduct}
            permutation={key}
            onFulfillmentStatusChange={(e, i) => this.handleFulfillmentStatusChoice(e, i)}
            onPrintTagStatusChange={(e, i) => this.handlePrintTagStatusChoice(e, i)}
            onPurchasingStatusChange={(e, i) => this.handlePurchasingStatusChoice(e, i)}
          />
        )}
      </div>
    );
  }
}

FulfillmentOverview.propTypes = {
  classes: PropTypes.object
};

export default withStyles(combineStyles(productCardStyle, reportStyles, modalStyle))(withGracefulUnmount(FulfillmentOverview));
