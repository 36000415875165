import React from "react";
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";

// core components
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import Slide from "@material-ui/core/Slide";
import Table from "components/Table/Table.jsx";
import TagsInput from "react-tagsinput";

import api from "state/api";
import reportStyles from "assets/jss/material-dashboard-pro-react/views/reportStyles.jsx";
import { sortSizes } from 'apparel-sorter';
import { withStyles } from '@material-ui/core/styles';
import withGracefulUnmount from 'react-graceful-unmount'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class PurchasingReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cachePrefix: "",
      containsMixedStatuses: false,
      filterIndex: 0,
      filteredOrders: [],
      filteredSkus: [],
      groupTagData: [],
      newStatusSelected: null,
      orders: [],
      pickupBoxData: [],
      pickupTagData: [],
      purchasingData: [],
      searchStatus: "Ready ",
      searchString: "",
      tagData: [],
      skus: []
    };

    this.defaultSearch = '';
    this.isSearching = false;
    this.selectedItem = {};
    this.textEntered = '';
  }

  componentWillUnmount() {
    localStorage.setItem('PurchasingReport', JSON.stringify(this.state))
    localStorage.setItem('PurchasingReportLocal', JSON.stringify({
      selectedItem: this.selectedItem,
      isSearching: this.isSearching
    }))
  }

  componentWillMount() {
    let rehydrate = JSON.parse(localStorage.getItem('PurchasingReport'));
    if (rehydrate) {
      this.setState(rehydrate)
      this.defaultSearch = rehydrate.searchString;
    }

    let rehydrateLocal = JSON.parse(localStorage.getItem('PurchasingReportLocal'));
    if (rehydrateLocal) {
      this.selectedItem = rehydrateLocal.selectedItem;
      this.isSearching = rehydrateLocal.isSearching;
    }
  }

  componentDidMount() {
    if (this.state.searchString.length > 0) {
      const blacklist = this.state.skus.filter(a => !this.state.filteredSkus.includes(a));
      if (this.state.searchStatus.length > 0 && this.state.searchStatus !== "Ready ") {
        this.searchSku({reportType: "purchasing", sku: this.state.searchString, status: this.state.searchStatus}, blacklist);
      } else {
        this.searchSku({reportType: "purchasing", sku: this.state.searchString}, blacklist);
      }
    }
  }

  handleChange(event) {
    // Send through this request again if we're mid-search and this is still
    // the most recent search term
    if(this.isSearching) {
      const value = event.target.value;
      this.textEntered = value;
      setTimeout(() => {
        if (this.textEntered === value) {
          this.handleChange({target: {value: value}})
        }
      }, 500 );
      return;
    }
    this.setState({searchString: event.target.value});

    // Reset the search status if searching for the first time under 'All'
    var prefix = this.state.cachePrefix;
    if (prefix.length === 0 && this.state.searchStatus === 'Ready ') {
      this.setState({searchStatus: ""});
    }

    // Reset the cache if the first four digits have changed at all
    var searchString = event.target.value;
    if (prefix.length > 0 && !searchString.startsWith(prefix)) {
      prefix = "";
      this.setState({cachePrefix: ""});
    }

    if (searchString.length > 3) {
      if (prefix.length === 0) {
        // Kick off a new search if at least 4 characters have been entered and no
        // data has yet been cached
        this.isSearching = true;
        prefix = searchString.substring(0, 4);
        this.searchSku({reportType: "purchasing", sku: searchString});
        this.setState({cachePrefix: prefix});
      } else {
        // Orders have already been cached for this prefix; do a local filtering
        var filtered = this.state.orders.filter(order => order.line_items.filter(e => e.sku.includes(searchString).length > 0));
        filtered = filtered.map(order => {
          var filteredOrder = JSON.parse(JSON.stringify(order));
          filteredOrder.line_items = filteredOrder.line_items.filter(e => e.sku.includes(searchString));
          return filteredOrder;
        });
        this.setState({filteredOrders: filtered});
        this.organizeResults(filtered)
      }
    }

    if (prefix.length < 4) {
      this.setState({filteredOrders: []});
      this.setState({filteredSkus: []});
      this.setState({skus: []});
    }
  }

  handleEmptyBlacklist() {
    this.setState({filteredSkus: this.state.skus});
  }

  handleTags = skuTags => {
    var blacklist = this.state.skus.filter(a => !skuTags.includes(a));
    this.organizeResults(this.state.filteredOrders, blacklist);
  };

  organizeResults(filtered, blacklist = []) {
    // Consolidate by product name
    var artNums = {};
    var counts = {};
    var totals = {};
    var paidTotals = {};
    var pendingTotals = {};
    var data = {};
    var skus = {};
    var uniqueSkus = new Set();
    var filteredSkus = new Set();
    var filteredSimpleSkus = new Set();
    filtered.forEach(order => {
      // don't render null orders
      if (order.id == null) {
        return;
      }
      order.line_items.forEach(item => {
        if (item.size.length > 0 || item.color.length > 0 || item.style.length > 0) {
          var organization = item.collection_name ? item.collection_name : "[NONE]";
          var uniqueSKU = `${item.sku} - ${item.title} - ${organization}`;
          uniqueSkus.add(uniqueSKU);
          if (!blacklist.includes(uniqueSKU)) {
            filteredSkus.add(uniqueSKU);
            filteredSimpleSkus.add(item.sku);
            var count = counts[item.title] || 0;
            var total = totals[item.title] || 0;
            var paidTotal = paidTotals[item.title] || 0;
            var pendingTotal = pendingTotals[item.title] || 0;
            var sCount = counts[`${item.title}|${item.style}`] || 0;
            var cCount = counts[`${item.title}|${item.style}|${item.color}`] || 0;
            var products = data[item.title] || {};
            var styles = products[item.style] || {};
            var colors = styles[item.color] || {};
            var sizes = colors[item.size] || {};
            var prices = sizes[item.price] || 0
            var quantity = parseInt(item.quantity);
            artNums[item.title] = order.art_number;
            counts[item.title] = count + quantity;
            counts[`${item.title}|${item.style}`] = sCount + quantity;
            counts[`${item.title}|${item.style}|${item.color}`] = cCount + quantity;
            sizes[item.price] = prices + quantity
            colors[item.size] = sizes;
            styles[item.color] = colors;
            products[item.style] = styles;
            if (item.size.length > 0 || item.color.length > 0 || item.style.length > 0) {
              data[item.title] = products;
              skus[`${item.title}|${item.style}|${item.color}|${item.size}`] = item.sku;

              totals[item.title] = total + parseFloat(item.price) * quantity;
            }
            if (order.payment_type === "PENDING" || order.payment_type === "VOIDED") {
              pendingTotals[item.title] = pendingTotal + parseFloat(item.price) * quantity;
            } else {
              paidTotals[item.title] = paidTotal + parseFloat(item.price) * quantity;
            }
          }
        }
      });
    });
    this.setState({skus: [...uniqueSkus].sort()});
    this.setState({filteredSkus: [...filteredSkus].sort()});

    var rows = [];
    var productsSorted = Object.keys(data).sort();
    productsSorted.forEach(product => {
      rows.push({product: product, style: "", color: "", size: "", count: counts[product], artNumber: artNums[product]});

      var styles = data[product] || {};
      var stylesSorted = Object.keys(styles).sort().reverse();
      stylesSorted.forEach(style => {
        rows.push({product: "", style: style, color: "", size: "", count: counts[`${product}|${style}`]});

        var colors = styles[style] || {};
        var colorsSorted = Object.keys(colors).sort();
        colorsSorted.forEach(color => {
          rows.push({product: "", style: "", color: color, size: "", count: counts[`${product}|${style}|${color}`]});

          var sizes = colors[color] || {};
          var sizesSorted = sortSizes(Object.keys(sizes));
          sizesSorted.forEach(size => {
            var prices = sizes[size] || {};
            var pricesSorted = Object.keys(prices).sort();
            pricesSorted.forEach(price => {
              var sku = skus[`${product}|${style}|${color}|${size}`];
              var total = prices[price] || 0;
              rows.push({product: "", style: "", color: "", price: price, sku: sku, size: size, count: total});
            });
          });
        });
      });
      var total = totals[product] ? totals[product] : 0;
      var paidTotal = paidTotals[product] ? paidTotals[product] : 0;
      var pendingTotal = pendingTotals[product] ? pendingTotals[product] : 0;
      rows.push({product: "", style: "", color: "", price: "Total", size: "", count: total.toFixed(2)});
      rows.push({product: "", style: "", color: "", price: "Paid", size: "", count: paidTotal.toFixed(2)});
      rows.push({product: "", style: "", color: "", price: "On Account", size: "", count: pendingTotal.toFixed(2)});
    });

    const fo = this.state.filteredOrders.filter(order => order.line_items.filter(e => filteredSimpleSkus.has(e.sku)));
    this.setState({filteredOrders: fo});
    this.setState({purchasingData: rows});
  }

  searchSku(body, blacklist = []) {
    const params = Object.keys(body).map((key) => { return encodeURIComponent(key) + '=' + encodeURIComponent(body[key]);}).join('&');
    fetch('/api/orders?' + params, {
      method: 'GET',
      headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}
    })
    .then(response => api.authCheck(response))
    .then(data => {
      if (data['status']) {
        this.isSearching = false;

        var orders = data['data'];
        this.setState({filteredOrders: orders});
        this.setState({orders: orders});
        this.setState({containsMixedStatuses: data['mixed'] && orders.length > 0});
        this.organizeResults(orders, blacklist);
      }
    });
  }

  handleCloseStatusModal() {
    this.selectedItem = {};
    this.setState({ newStatusSelected: null });
  }

  handleNavigationChange(index) {
    const statusTitles = ["Pending", "Ready", "Complete"];
    if (index - 1 < statusTitles.length && index > 0) {
      const status = statusTitles[index - 1];
      this.setState({searchStatus: status});
      this.searchSku({reportType: "purchasing", sku: this.state.searchString, status: status});
    } else {
      this.setState({searchStatus: ""});
      this.searchSku({reportType: "purchasing", sku: this.state.searchString});
    }
    this.setState({filterIndex: index});
  }

  handleSaveStatusChange() {
    const product = this.selectedItem.product;
    const oldStatus = this.selectedItem.purchasing_status;
    const newStatus = this.state.newStatusSelected;
    var data = [];
    this.state.filteredOrders.forEach(order => {
      order.line_items.forEach(item => {
        if (item.title === product) {
          data.push({id: item.line_item_id, purchasing_status: newStatus});
        }
      });
    });

    const body = {changes: data};
    fetch('/api/lineItems', {
      method: 'PUT',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(body)
    })
    .then(response => api.authCheck(response))
    .then(data => {
      if (this.state.searchStatus === 'Ready ') {
        this.searchSku({reportType: "purchasing", sku: this.state.searchString});
      } else {
        this.searchSku({reportType: "purchasing", sku: this.state.searchString, status: oldStatus});
      }
    });

    this.handleCloseStatusModal();
  }

  handleStatusChoice(newStatus) {
    if (newStatus !== this.state.searchStatus) {
      this.setState({ newStatusSelected: newStatus });
    }
  }

  renderData() {
    const { classes } = this.props;
    const statusTitles = ["Pending", "Ready", "Complete"];
    var data = this.state.purchasingData;
    var status = this.state.searchStatus;
    return data.map(order => {
      var color = "plain";
      if (order.product.length > 0) {
        color = "product";
      } else  if (order.style.length > 0) {
        color = "style";
      } else if (order.color.length > 0) {
        color = "color";
      }
      return ({color: color, data:[
        <div>
        {order.product.length > 0 && status.length > 0 ? (
          <CustomDropdown
            buttonText={status}
            buttonProps={{
              style: { marginBottom: "0" },
              color: "info"
            }}
            dropdownList={statusTitles}
            onPopulate={() => { this.selectedItem = order }}
            onClick={(e) => this.handleStatusChoice(e)}
          />
        ) : null }
        </div>,
        <div>
        {order.product.length > 0 ? (
          <span>
            <strong>{order.product}</strong>
            { order.artNumber > 0 ? (
              <span className={classes.artNumber}>Art #: {order.artNumber}</span>
            ) : null }
          </span>
        ) : null }
        </div>,
        <div>
        {order.style}
        </div>,
        <div>
        {order.color}
        </div>,
        <div>
        {order.sku}
        </div>,
        <div>
        {order.price}
        </div>,
        <div>
        {order.size}
        </div>,
        <div>
          <strong>{order.count}</strong>
        </div>
      ]});
    })
  }

  renderStatusModal() {
    const { classes } = this.props;
    const order = this.selectedItem;

    var blString = "";
    const blacklist = this.state.skus.filter(a => !this.state.filteredSkus.includes(a));
    if (blacklist.length > 0) {
      blString = ` (except for ${blacklist.join(', ')})`;
    }

    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal
        }}
        open={this.state.newStatusSelected != null}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.handleCloseStatusModal()}
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
      >
        <DialogContent
          id="notice-modal-slide-description"
          className={classes.modalBody}
        >
        <p className={classes.alertText}>The purchasing status for all <b>{order.product}</b> orders{blString} will be changed from <b>{this.state.searchStatus}</b> to <b>{this.state.newStatusSelected}</b>.</p>
        </DialogContent>
        <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter }>
          <Button
            onClick={() => this.handleSaveStatusChange()}
            color="info" >
            Set Purchasing Status
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const { classes } = this.props;
    const prefix = this.state.cachePrefix;
    const blacklistLength = this.state.skus.length - this.state.filteredSkus.length;
    const headers = [
      {label: "Product", key: "product"},
      {label: "Style", key: "style"},
      {label: "Color", key: "color"},
      {label: "SKU", key: "sku"},
      {label: "Price", key: "price"},
      {label: "Size", key: "size"},
      {label: "Qty", key: "count"},
    ];
    return (
      <GridContainer justify="center">
        {this.renderStatusModal()}
        {this.state.containsMixedStatuses &&
          <GridItem xs={12}>
            <h4 className={classes.alertHeader}>This search contains mixed purchasing statuses. Make sure these are reconciled before changing status from this page.</h4>
          </GridItem>
        }
        <GridItem xs={12} className={classes.searchInputContainer}>
          <CustomInput
            id="sku"
            inputProps={{
              inputProps: {className: classes.largeText},
              defaultValue: this.defaultSearch,
              onChange: (e) => this.handleChange(e),
              placeholder: "Search SKU"
            }}
            formControlProps={{
              fullWidth: true
            }} />
        </GridItem>
        { prefix.length > 0 ? (
          <GridItem xs={12} className={classes.center}>
          <NavPills
            alignCenter
            color="warning"
            active={this.state.filterIndex}
            onChange={(e) => this.handleNavigationChange(e)}
            tabs={[
              {
                tabButton: "All"
              },
              {
                tabButton: "Pending"
              },
              {
                tabButton: "Ready"
              },
              {
                tabButton: "Complete"
              }
            ]}
          />
          </GridItem>
        ) : null }
        <GridItem xs={12} className={classes.center}>
          <TagsInput
            renderInput={() => {}}
            value={this.state.filteredSkus}
            onChange={this.handleTags}
            tagProps={{ className: "react-tagsinput-tag info" }}
          />
        </GridItem>
        { blacklistLength > 0 &&
          <GridItem xs={12} className={classes.center}>
            <Button
              onClick={() => this.handleEmptyBlacklist()}
              color="danger"
              simple
              >
              {blacklistLength} item{blacklistLength > 1 ? 's' : ''} removed
            </Button>
          </GridItem>
        }
        { this.state.purchasingData.length > 0 ? (
        <GridItem xs={10}>
          <Card className={classes.productCard}>
            <CardHeader color="primary" icon>
              <CardText color="rose" className={classes.dateCardText}>
                <CSVLink data={this.state.purchasingData} headers={headers} filename={"purchasing.csv"} className={classes.exportButton}>
                  Export CSV
                </CSVLink>
              </CardText>
            </CardHeader>
            <CardBody className={classes.cardBodyVariants}>
              <Table
                tableHead={['', 'Product', 'Style', 'Color', 'SKU', 'Price', 'Size', 'Qty']}
                tableData={this.renderData()}
                customHeadCellClasses={[
                  classes.widthFixed75,
                  classes.widthFixed75,
                  classes.widthFixed75,
                ]}
                customHeadClassesForCells={[5, 6, 7]}
                customCellClasses={[
                  classes.widthFixed75,
                  classes.widthFixed75,
                  classes.widthFixed75
                ]}
                customClassesForCells={[5, 6, 7]}
              />
            </CardBody>
          </Card>
        </GridItem>
        ) : null}
      </GridContainer>
    );
  }
}

PurchasingReport.propTypes = {
  classes: PropTypes.object
};

export default withStyles(reportStyles)(withGracefulUnmount(PurchasingReport));
