import {
  cardTitle,
  grayColor
} from "assets/jss/material-dashboard-pro-react.jsx";

const productTablesStyle = theme => ({
  cardBodyProducts: {
    padding: "0",
    "& tr td": {
      borderWidth: "0",
      paddingLeft: "0",
      paddingRight: "0 !important",
    }
  },
  cardBodyVariants: {
    padding: "0",
    "& tr td": {
      borderWidth: "1px"
    },

    [theme.breakpoints.down("xs")]: {
      borderTop: "none",
      paddingTop: "10px",
      "& tr:first-child td": {
        border: "none"
      }
    },
  },
  cardHeaderVariant: {
    fontSize: "1.0em",
    fontWeight: "500",
    marginRight: "-18px !important",
    marginTop: "10px !important",
    padding: "5px 10px !important",
    textTransform: "uppercase",

    [theme.breakpoints.down("xs")]: {
      float: "left !important",
      marginBottom: "4px",
      marginLeft: "-14px",
      marginTop: "0px !important",
    },
  },
  cardIconTitle: {
    ...cardTitle,
    fontSize: "1em",
    marginTop: "-28px",
    padding: "0"
  },
  designCard: {
    marginTop: "0",
    marginBottom: "16px",
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    textAlign: "center"
  },
  designNameSmall: {
    color: grayColor[1],
    display: "block",
    fontSize: "0.8em",
    fontWeight: "300",
    marginBottom: "8px",
    marginTop: "2px",
  },
  infoSpan: {
    marginTop: "-24px !important",
    verticalAlign: "top",
  },
  largeText: {
    fontSize: "2em",
    fontWeight: "600",
    textAlign: "center",
  },
  tdName: {
    display: "block",
    fontWeight: "400",
    fontSize: "1.5em",
    minWidth: "200px",
  },
  tdNameSmall: {
    color: grayColor[1],
    display: "block",
    fontSize: "0.8em",
    fontWeight: "300",
    marginTop: "8px",
  },
  productCard: {
    marginTop: "0",
    marginBottom: "0",
    padding: "0px 15px",
    display: "flex",
    flexDirection: "column",
  },
  productsCard: {
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none"
  },
  productGridContainer: {
    margin: "auto",
    maxWidth: "1000px",
    paddingRight: "24px",

    [theme.breakpoints.down("md")]: {
      margin: "0 -15px",
      paddingRight: "0"
    }
  },
  productGridItem: {
    padding: "0 !important"
  },
  searchInputContainer: {
    maxWidth: "200px",
    textAlign: "center"
  },
  variantDiv: {
    width: "100%"
  }
});

export default productTablesStyle;
