import React from "react";
import PropTypes from "prop-types";

import { useTheme, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Table from "components/Table/Table.jsx";

import productCellStyle from "assets/jss/material-dashboard-pro-react/components/productCellStyle.jsx";

function renderLineItems(lineItems, classes) {
  return lineItems.map(lineItem => {
    return ([
      <div className={classes.imgContainer}>
      {lineItem['src'] ? (
        <img src={lineItem['src'].replace(".jpg", "_small.jpg")} alt='aProduct' className={classes.img} />
      ) : (
        <img src={lineItem['default_image'].replace(".jpg", "_small.jpg")} alt='zProduct' className={classes.img} />
      )}
      </div>,
      <span>{lineItem['title']}: {lineItem['size']} - {lineItem['color']} - {lineItem['style']}</span>,
      <span>{lineItem['sku']}</span>,
      <span>
      <small className={classes.tdNumberSmall}>${lineItem['price']}</small>
      </span>,
      <span>{lineItem['status']}</span>
    ]);
  })
}

function renderLineItemsForSmallScreen(lineItems, classes) {

  return lineItems.map(lineItem => {
    return ([
      <div className={classes.imgContainer}>
        <img src={lineItem['src'].replace(".jpg", "_small.jpg")} alt='Product' className={classes.img} />
      </div>,
      <div className={classes.variantContainer}>
        {lineItem['size'] &&
          <span><strong>{lineItem['title']}: {lineItem['size']} - {lineItem['color']} - {lineItem['style']}</strong></span>
        }
        <span>{lineItem['sku']}</span>
        <span>${lineItem['price']}</span>
        <span>{lineItem['status']}</span>
      </div>
    ]);
  })
}

function LineItemCellCollection({ ...props }) {
  const { classes, lineItems } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <div className={classes.noOverflow}>
    {matches ? (
      <Table
        tableHead={["", "NAME", "SKU", "PRICE", "STATUS" ]}
        tableData={renderLineItems(lineItems, classes)}
        tableShopping
        customHeadCellClasses={[
          classes.description,
          classes.description,
          classes.right,
          classes.right
        ]}
        customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
        customCellClasses={[]}
        customClassesForCells={[]}
      />
    ) : (
      <Table
        tableHead={[]}
        tableData={renderLineItemsForSmallScreen(lineItems, classes)}
        tableShopping
        customHeadCellClasses={[]}
        customHeadClassesForCells={[0, 1]}
        customCellClasses={[classes.imgCell]}
        customClassesForCells={[0]}
      />
    )}
    </div>
  );
}

LineItemCellCollection.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  lineItems: PropTypes.array.isRequired
};

export default withStyles(productCellStyle)(LineItemCellCollection);
