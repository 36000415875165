import React from "react";
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";

// @material-ui/core components
import Moment from 'react-moment'
import ReactTable from "react-table";

import api from "state/api";
import { states } from "variables/jnj.jsx";
import tableStyle from "assets/jss/material-dashboard-pro-react/tableStyle.jsx";
import { withStyles } from '@material-ui/core/styles';
import withGracefulUnmount from 'react-graceful-unmount'

// core components
import Button from "components/CustomButtons/Button.jsx";
import CardText from "components/Card/CardText.jsx";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import RefreshSpinner from "components/Spinners/RefreshSpinner.jsx";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const columns = [
  {
    Header: "Collection",
    accessor: "title",
  },
  {
    Header: "Updated",
    accessor: "updated_at",
    filterable: false,
    Cell: ({ row }) => (
      <span>
        {row._original.updated_at &&
          <Moment format="MM/DD/YY h:mm a">{row._original.updated_at}</Moment>
        }
      </span>
    ),
    maxWidth: 90
  },
  {
    Header: "Handle",
    accessor: "handle"
  },
  {
    Header: "SKU",
    accessor: "ticker",
    maxWidth: 80
  },
  {
    Header: "Members",
    accessor: "members",
    sortMethod: (a, b, desc) => {
      a = (a === 0) ? (desc ? -Infinity : Infinity) : a;
      b = (b === 0) ? (desc ? -Infinity : Infinity) : b;
      if (parseInt(a) > parseInt(b)) { return 1; }
      if (parseInt(a) < parseInt(b)) { return -1; }
      return 0;
    },
    maxWidth: 100
  },
  {
    Header: "Order Method",
    accessor: "order_method",
  },
  {
    Header: "Payment",
    accessor: "payment_method",
    maxWidth: 95
  },
  {
    Header: "Shipping",
    accessor: "shipping_types",
    Cell: ({ row }) => (
      <span>
        {row._original.shipping_types &&
          <span>{row._original.shipping_types.replace(',', ', ')}</span>
        }
      </span>
    ),
  },
  {
    Header: "Address",
    accessor: "address"
  },
  {
    Header: "City",
    accessor: "city"
  },
  {
    Header: "State",
    accessor: "state",
    maxWidth: 65
  },
  {
    Header: "Zip",
    accessor: "zip",
    maxWidth: 52
  }
];

class CollectionTables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collectionBeingEdited: null,
      downloadData: [],
      filteredData: [],
      lastCursor: "",
      lastUpdated: "2000-01-01T12:00:00Z",
      newCollectionSelected: false,
      newCollectionOrderMethod: "Order Method",
      newCollectionPaymentMethod: "Payment Method",
      newCollectionShippingGroupDelivery: false,
      newCollectionShippingGroupPickup: false,
      newCollectionShippingIndividualPickup: false,
      newCollectionShippingIndividualShipping: false,
      newCollectionState: "State",
      collections: [],
      collectionsUpdated: 0,
      loading: false,
      refreshing: false,
      status: "",
      updating: false,

      sorted: [{
          id: "updated_at",
          desc: true
      }],
      page: 0,
      pageSize: 20,
      expanded: {},
      resized: [],
      filtered: []
    };

    this.address1Ref = React.createRef();
    this.address2Ref = React.createRef();
    this.cityRef = React.createRef();
    this.dataTable = React.createRef();
    this.handleRef = React.createRef();
    this.paymentMethodRef = React.createRef();
    this.shippingMethodRef = React.createRef();
    this.stateRef = React.createRef();
    this.tickerRef = React.createRef();
    this.titleRef = React.createRef();
    this.zipRef = React.createRef();

    this.resetNewCollectionFields = false;
  }

  componentWillUnmount() {
    let state = this.state;
    delete state.collections;
    delete state.filteredData;
    delete state.downloadData;
    state.sorted = this.dataTable.current.state.sorted;
    state.page = this.dataTable.current.state.page;
    state.pageSize = this.dataTable.current.state.pageSize;
    state.expanded = this.dataTable.current.state.expanded;
    state.resized = this.dataTable.current.state.resized;
    state.filtered = this.dataTable.current.state.filtered;
    localStorage.setItem('CollectionTables', JSON.stringify(state))
  }

  componentWillMount() {
    let rehydrate = JSON.parse(localStorage.getItem('CollectionTables'));
    if (rehydrate) {
      rehydrate.collectionsUpdated = 0;
      rehydrate.refreshing = false;
      rehydrate.newCollectionSelected = false;
      this.setState(rehydrate)
    }
  }

  componentDidMount() {
    this.updateSearch();

    fetch('/api/meta/lastCollectionRefresh')
      .then(response => api.authCheck(response))
      .then(data => this.setState({ lastUpdated: data['value'] }));

    fetch('/api/meta/lastCollectionCursor')
      .then(response => api.authCheck(response))
      .then(data => this.setState({ lastCursor: data['value'] }));
  }

  continueRefresh(body) {
    var updateCount = this.state.collectioinsUpdated;
    const params = Object.keys(body).map((key) => { return encodeURIComponent(key) + '=' + encodeURIComponent(body[key]);}).join('&');
    fetch('/api/collections', {
      method: 'PUT',
      headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
      body: params
    })
    .then(response => api.authCheck(response))
    .then(data => {
      if (data['status']) {
        this.setState({ collectionsUpdated: updateCount + data['changeCount'] });
        if (data['hasNextPage']) {
          this.setState({ lastCursor: data['cursor'] });
          if (data['throttleRemaining'] > 1100) {
            this.continueRefresh({cursor: data['cursor']});
          } else {
            // Assuming a restore rate of 100, wait until we're back at 1000 throttle remaining
            var seconds = (1100 - data['throttleRemaining']) / 100 + 0.5
            setTimeout(() => this.continueRefresh({cursor: data['cursor']}), seconds * 1000);
          }
        } else {
          // Update the last updated metadata
          const dateString = new Date().toJSON();
          const body = {value: dateString};
          const params = Object.keys(body).map((key) => { return encodeURIComponent(key) + '=' + encodeURIComponent(body[key]);}).join('&');
          fetch('/api/meta/lastCollectionRefresh', {
            method: 'PUT',
            headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
            body: params
          })
          this.setState({ lastUpdated: dateString });
          this.setState({ refreshing: false });
          this.updateSearch();
        }
      } else {
        setTimeout(() => this.continueRefresh({cursor: data['cursor']}), 5000);
      }
    });
  }

  handleRefresh() {
    this.setState({ refreshing: true });
    if (this.state.lastCursor && this.state.lastCursor !== "") {
      this.continueRefresh({cursor: this.state.lastCursor});
    } else {
      this.continueRefresh({lastUpdate: this.state.lastUpdated});
    }
  }

  handleSaveNewCollection() {
    let address1 = this.address1Ref.current.value;
    let address2 = this.address2Ref.current.value;
    let city = this.cityRef.current.value;
    let handle = this.handleRef.current.value;
    let ticker = this.tickerRef.current.value;
    let title = this.titleRef.current.value;
    let zip = this.zipRef.current.value;

    if (title.length > 0 && handle.length > 0 && ticker.length > 0 && this.state.newCollectionOrderMethod !== "Order Method"
        && this.state.newCollectionPaymentMethod !== "Payment Method" && this.state.newCollectionState !== "State") {
      this.setState({ newCollectionSelected: false })

      let shippingMethods = [];
      if (this.state.newCollectionShippingGroupDelivery) {
        shippingMethods.push("Group Delivery");
      }
      if (this.state.newCollectionShippingGroupPickup) {
        shippingMethods.push("Group Pick-up");
      }
      if (this.state.newCollectionShippingIndividualPickup) {
        shippingMethods.push("Individual Pickup");
      }
      if (this.state.newCollectionShippingIndividualShipping) {
        shippingMethods.push("Individual Shipping");
      }

      let orderMethod = this.state.newCollectionOrderMethod;
      let paymentMethod = this.state.newCollectionPaymentMethod;
      let state = Object.keys(states).find(key => states[key] === this.state.newCollectionState);
      const body = { title: title, handle: handle, order_method: orderMethod, payment_method: paymentMethod, shipping_methods: shippingMethods.join('|'), address1: address1, address2: address2, city: city, state: state, zip: zip, ticker: ticker };
      const params = Object.keys(body).map((key) => { return encodeURIComponent(key) + '=' + encodeURIComponent(body[key]);}).join('&');
      fetch('/api/collections', {
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
        body: params
      }).then(res => this.handleRefresh())
    }
  }

  updateSearch() {
    this.setState({ loading: true });
    fetch('/api/collections')
          .then(response => api.authCheck(response))
          .then(data => {
            this.setState({ collections: data['data'] });
            this.setState({ filteredData: data['data'] });
            this.setState({ loading: false });
          });
  }

  renderAddCollectionModal() {
    const { classes } = this.props;

    if (this.resetNewCollectionFields) {
      this.address1Ref.current.value = "";
      this.address2Ref.current.value = "";
      this.cityRef.current.value = "";
      this.handleRef.current.value = "";
      this.tickerRef.current.value = "";
      this.titleRef.current.value = "";
      this.zipRef.current.value = "";
      this.resetNewCollectionFields = false;
      this.setState({ newCollectionOrderMethod: "Order Method" })
      this.setState({ newCollectionPaymentMethod: "Payment Method" })
      this.setState({ newCollectionState: "State" })
      this.setState({ newCollectionShippingGroupDelivery: false })
      this.setState({ newCollectionShippingGroupPickup: false })
      this.setState({ newCollectionShippingIndividualPickup: false })
    }

    return (
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal
        }}
        open={this.state.newCollectionSelected}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.setState({ newCollectionSelected: false })}
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
      >
        <DialogContent
          id="notice-modal-slide-description"
          className={classes.modalBody}
        >
          <CustomInput
            labelText="Title"
            id="title"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              inputRef: this.titleRef,
              type: "name"
            }}
          />
          <CustomInput
            labelText="Handle"
            id="handle"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              inputRef: this.handleRef
            }}
          />
          <CustomDropdown
            buttonText={this.state.newCollectionOrderMethod}
            buttonProps={{
              style: { marginBottom: "0", width: "200px" },
              color: "warning"
            }}
            dropdownList={["Logins", "Quick Link"]}
            onClick={(e) => this.setState({ newCollectionOrderMethod: e })}
          />
          <CustomDropdown
            buttonText={this.state.newCollectionPaymentMethod}
            buttonProps={{
              style: { marginBottom: "0", width: "200px" },
              color: "warning"
            }}
            dropdownList={["Credit Card", "On Account"]}
            onClick={(e) => this.setState({ newCollectionPaymentMethod: e })}
          />
          <FormControlLabel
          value="groupDelivery"
          control={<Checkbox
            checked={this.state.newCollectionShippingGroupDelivery}
            onClick={() => this.setState({ newCollectionShippingGroupDelivery: !this.state.newCollectionShippingGroupDelivery })}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot
            }} />}
          label="Group Delivery"
          labelPlacement="right"
          />
          <FormControlLabel
          value="individualPickup"
          control={<Checkbox
            checked={this.state.newCollectionShippingIndividualPickup}
            onClick={() => this.setState({ newCollectionShippingIndividualPickup: !this.state.newCollectionShippingIndividualPickup })}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot
            }} />}
          label="Individual Pickup"
          labelPlacement="right"
          />
          <FormControlLabel
          value="groupPickup"
          control={<Checkbox
            checked={this.state.newCollectionShippingGroupPickup}
            onClick={() => this.setState({ newCollectionShippingGroupPickup: !this.state.newCollectionShippingGroupPickup })}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot
            }} />}
          label="Group Pickup"
          labelPlacement="right"
          />
          <FormControlLabel
          value="individualShipping"
          control={<Checkbox
            checked={this.state.newCollectionShippingIndividualShipping}
            onClick={() => this.setState({ newCollectionShippingIndividualShipping: !this.state.newCollectionShippingIndividualShipping })}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot
            }} />}
          label="Individual Shipping"
          labelPlacement="right"
          />
          <CustomInput
            labelText="Address 1"
            id="address1"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              inputRef: this.address1Ref
            }}
          />
          <CustomInput
            labelText="Address 2"
            id="address2"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              inputRef: this.address2Ref
            }}
          />
          <CustomInput
            labelText="City"
            id="city"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              inputRef: this.cityRef
            }}
          />
          <CustomDropdown
            buttonText={this.state.newCollectionState}
            buttonProps={{
              style: { marginBottom: "0", width: "200px" },
              color: "warning"
            }}
            dropdownList={Object.values(states)}
            onClick={(e) => this.setState({ newCollectionState: e })}
          />
          <CustomInput
            labelText="Zip"
            id="zip"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              inputRef: this.zipRef
            }}
          />
          <CustomInput
            labelText="SKU"
            id="ticker"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              inputRef: this.tickerRef
            }}
          />
        </DialogContent>
        <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter }>
          <Button
            onClick={() => this.handleSaveNewCollection()}
            color="info"
            round>
            Create New Collection
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderCard() {
    const { classes } = this.props;
    return (
      <Card>
        <CardBody className={classes.cardBodyProducts}>
          {this.renderCollections()}
        </CardBody>
      </Card>
    );
  }

// TODO: Offload paging and filter logic onto the server
  renderCollections() {
    return (
      <ReactTable
        ref={this.dataTable}
        data={this.state.filteredData}
        filterable
        defaultFilterMethod={(filter, row) => {
          if (filter.value && row[filter.id]) {
            return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
          }
          return false;
        }}
        columns={columns}
        loading={this.state.loading}
        showPaginationTop={false}
        showPaginationBottom={true}
        className="-striped -highlight"

        defaultSorted={this.state.sorted}
        defaultPage={this.state.page}
        defaultPageSize={this.state.pageSize}
        defaultExpanded={this.state.expanded}
        defaultResized={this.state.resized}
        defaultFiltered={this.state.filtered}
      />
    );
  }

  render() {
    const { classes } = this.props;
    const headers = ["Collection", "Handle", "SKU", "Members", "Order Method", "Updated", "Payment", "Shipping", "Address", "City", "State", "Zip"];
    const dataNames = ["title", "updated_at", "handle", "ticker", "members", "order_method", "payment_method", "shipping_types", "address", "city", "state", "zip"];
    return (
      <GridContainer>
        {this.renderAddCollectionModal()}
        <GridItem xs={12} className={classes.right}>
          <Button
            onClick={() => {
              this.resetNewCollectionFields = true;
              this.setState({ newCollectionSelected: true });
            }}
            color="info"
            round>
            Add Collection
          </Button>
        </GridItem>
        <RefreshSpinner
          onClick={() => this.handleRefresh()}
          objectName='collections'
          refreshing={this.state.refreshing}
          updateCount={this.state.collectionsUpdated}
          updatedDate={this.state.lastUpdated}
        />
        <GridItem xs={12}>
          {this.renderCard()}
        </GridItem>
        <GridItem xs={6}>
          <CardText color="rose" className={classes.dateCardText}>
            <CSVLink data={this.state.downloadData}
              asyncOnClick={true}
              filename={"collections.csv"}
              headers={headers}
              onClick={(event, done) => {
                this.setState({
                  updating: true
                }, () => {
                  var currentRecords = this.dataTable.current.getResolvedState().sortedData;
                  var data_to_download = []
                  currentRecords.forEach(function (item, index) {
                    var fi = {};
                    dataNames.forEach(function (name, i) {
                      var header = headers[i];
                      var value = item[name];
                      fi[header] = value ? value.replace('"', '”') : '';
                    });
                    data_to_download.push(fi);
                  });
                  this.setState({
                    downloadData: data_to_download
                  }, () => {
                    done();
                  })
                })
              }}
              className={classes.exportButton}>
              Export CSV
            </CSVLink>
          </CardText>
        </GridItem>
      </GridContainer>
    );
  }
}

CollectionTables.propTypes = {
  classes: PropTypes.object
};

export default withStyles(tableStyle)(withGracefulUnmount(CollectionTables));
