import React from "react";
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";

// core components
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Close from "@material-ui/icons/Close";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import IconButton from "@material-ui/core/IconButton";
import NavPills from "components/NavPills/NavPills.jsx";
import Slide from "@material-ui/core/Slide";
import Table from "components/Table/Table.jsx";
import Tooltip from "@material-ui/core/Tooltip";

import api from "state/api";
import reportStyles from "assets/jss/material-dashboard-pro-react/views/reportStyles.jsx";
import { withStyles } from '@material-ui/core/styles';
import withGracefulUnmount from 'react-graceful-unmount'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class PrintTagTables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      boxData: [],
      filterIndex: 0,
      filteredOrders: [],
      filteredSummary: {},
      newStatusSelected: null,
      orders: [],
      searchStatus: "Ready ",
      searchString: "",
      tagData: [],
      skus: [],
      viewMode: "None"
    };

    this.defaultSearch = '';
    this.lineItemsVisible = [];
    this.blacklist = [];
    this.cachePrefix = "";
    this.isSearching = false;
    this.textEntered = '';
  }

  componentWillUnmount() {
    localStorage.setItem('PrintTagsReport', JSON.stringify(this.state))
    localStorage.setItem('PrintTagsReportLocal', JSON.stringify({
      lineItemsVisible: this.lineItemsVisible,
      blacklist: this.blacklist,
      cachePrefix: this.cachePrefix,
      isSearching: this.isSearching
    }))
  }

  componentWillMount() {
    let rehydrate = JSON.parse(localStorage.getItem('PrintTagsReport'));
    if (rehydrate) {
      this.setState(rehydrate)
      this.defaultSearch = rehydrate.searchString;
    }

    let rehydrateLocal = JSON.parse(localStorage.getItem('PrintTagsReportLocal'));
    if (rehydrateLocal) {
      this.lineItemsVisible = rehydrateLocal.lineItemsVisible;
      this.blacklist = rehydrateLocal.blacklist;
      this.cachePrefix = rehydrateLocal.cachePrefix;
      this.isSearching = rehydrateLocal.isSearching;
    }
  }

  componentDidMount() {
    if (this.state.searchString.length > 0) {
      if (this.state.searchStatus.length > 0 && this.state.searchStatus !== "Ready ") {
        this.searchSku({reportType: "print-tags", sku: this.state.searchString, status: this.state.searchStatus});
      } else {
        this.searchSku({reportType: "print-tags", sku: this.state.searchString});
      }
    } else {
      this.searchSku({reportType: "print-tags"});
    }
  }

  handleChange(event) {
    // Send through this request again if we're mid-search and this is still
    // the most recent search term
    if(this.isSearching) {
      const value = event.target.value;
      this.textEntered = value;
      setTimeout(() => {
        if (this.textEntered === value) {
          this.handleChange({target: {value: value}})
        }
      }, 500 );
      return;
    }
    this.setState({searchString: event.target.value});

    // Reset the search status if searching for the first time under 'All'
    var prefix = this.cachePrefix;
    if (prefix.length === 0 && this.state.searchStatus === 'Ready ') {
      this.setState({searchStatus: ""});
    }

    // Reset the cache if the first three digits have changed at all
    var searchString = event.target.value;
    if (prefix.length > 0 && !searchString.startsWith(prefix)) {
      prefix = "";
      this.cachePrefix = "";
    }

    if (searchString.length > 3) {
      if (prefix.length === 0) {
        // Kick off a new search if at least 4 characters have been entered and no
        // data has yet been cached
        this.isSearching = true;
        prefix = searchString.substring(0, 4);
        this.searchSku({reportType: "print-tags", sku: searchString});
        this.cachePrefix = prefix;
      } else {
        // Orders have already been cached for this prefix; do a local filtering
        var filtered = this.state.orders.filter(order => {
          if (order.line_items.some(e => e.sku.includes(searchString))) {
            var filteredOrder = order;
            filteredOrder.line_items = order.line_items.filter(e => e.sku.includes(searchString));

            const item = filteredOrder.line_items[0];
            var organization = item.collection_name;
            filteredOrder.title = organization + "|||||" + item.title + "|||||" + order.customer;
            return filteredOrder;
          }
          return null;
        });

        this.setState({filteredOrders: filtered});
        this.organizeResults(filtered)
      }
    }

    if (prefix.length < 4) {
      this.blacklist = [];
      this.lineItemsVisible = [];
      this.setState({filteredOrders: []});
      this.setState({filteredSummary: {}});
      this.setState({boxData: []});
      this.setState({tagData: []});
      this.setState({viewMode: "None"});
    }
  }

  handleCloseStatusModal() {
    this.setState({ newStatusSelected: null });
  }

  handleEmptyBlacklist() {
    this.blacklist = [];
    this.organizeResults(this.state.filteredOrders);
  }

  handleRemoveItems(item) {
    let product = item.product.length > 0 ? item.product : item.productTitle;
    let org = item.org.length > 0 ? item.org : item.orgName;
    if (org === "<No org>" || !org) {
      org = "";
    }

    let uniqueSKU = `${item.sku} - ${product} - ${org}`;
    if (org.length === 0 && item.sku.length === 0) {
      uniqueSKU = product;
    } else if (item.sku.length === 0) {
      uniqueSKU = `${product} - ${org}`;
    }

    let blacklist = this.blacklist;
    blacklist.push(uniqueSKU);
    this.blacklist = blacklist;
    this.organizeResults(this.state.filteredOrders);
  }

  handleStatusChoice(newStatus) {
    const status = newStatus.replace('Print Tags ', '');
    if (status !== this.state.searchStatus) {
      this.setState({ newStatusSelected: status });
    }
  }

  handleNavigationChange(index) {
    const statusTitles = ["Pending", "Ready", "Complete"];
    if (index - 1 < statusTitles.length && index > 0) {
      const status = statusTitles[index - 1];
      this.setState({searchStatus: status});
      this.searchSku({reportType: "print-tags", sku: this.state.searchString, status: status});
    } else {
      this.setState({searchStatus: ""});
      this.searchSku({reportType: "print-tags", sku: this.state.searchString});
    }
    this.setState({viewMode: "None"});
    this.setState({filterIndex: index});
  }

  handleSaveStatusChange() {
    const newStatus = this.state.newStatusSelected;
    var data = [];
    this.lineItemsVisible.forEach(item => {
      data.push({id: item.id, print_tags_status: newStatus});
    });

    const body = {changes: data};
    fetch('/api/lineItems', {
      method: 'PUT',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(body)
    })
    .then(response => api.authCheck(response))
    .then(data => {
      if (this.state.searchStatus === 'Ready ' || this.state.searchStatus === '') {
        if (this.state.searchString.length > 0) {
          this.searchSku({reportType: "print-tags", sku: this.state.searchString});
        } else {
          this.searchSku({reportType: "print-tags"});
        }
      } else {
        this.searchSku({reportType: "print-tags", sku: this.state.searchString, status: this.state.searchStatus});
      }
    });

    this.handleCloseStatusModal();
  }

  organizeResults(filtered) {
    // Consolidate by shipping type X collection name X product name
    var blacklist = this.blacklist;
    var deliveryCounts = {};
    var groupPickupCounts = {};
    var indPickupCounts = {};
    var tagData = [];
    var countTitles = [];
    var orderCountTitles = [];
    var uniqueSkus = new Set();
    var filteredSummary = {};
    var lineItemsVisible = []
    filtered.forEach(order => {
      order.line_items.forEach(item => {
        var organization = item.collection_name;
        var uniqueSKU = `${item.sku} - ${item.title} - ${organization}`;
        var uniquePOSKU = `${item.title} - ${organization}`;
        uniqueSkus.add(uniqueSKU);
        if (!blacklist.includes(uniqueSKU) && !blacklist.includes(uniquePOSKU) && !blacklist.includes(item.title)) {
          // Add this item to the summary data by org -> product -> sku
          let productData = filteredSummary[item.title] ? filteredSummary[item.title] : {};
          let orgData = productData[organization] ? productData[organization] : {};
          let skuCount = orgData[item.sku] ? orgData[item.sku] : 0;
          let quantity = parseInt(item.quantity);
          orgData[item.sku] = parseInt(skuCount) + quantity;
          productData[organization] = orgData;
          filteredSummary[item.title] = productData;

          lineItemsVisible.push({id: item.line_item_id, status: item.print_status});

          var title = organization + "|||||" + item.title + "|||||" + item.shipping_type;
          var orderTitle = organization + "|||||" + item.title + "|||||" + order.customer;
          if (item.shipping_type === "Group Delivery") {
            deliveryCounts[title] = quantity  + (deliveryCounts[title] || 0);
          } else if (item.shipping_type === "Group Pickup") {
            groupPickupCounts[title] = quantity  + (groupPickupCounts[title] || 0);
          } else {
            indPickupCounts[title] = quantity  + (indPickupCounts[title] || 0);
          }
          if (countTitles.indexOf(title) < 0) {
            countTitles.push(title);
          }
          if (orderCountTitles.indexOf(orderTitle) < 0) {
            orderCountTitles.push(orderTitle);
          }
        }
      });
    });
    countTitles.sort();

    this.lineItemsVisible = lineItemsVisible;
    this.setState({filteredSummary: filteredSummary});

    // Group delivery boxes
    var boxLabels = []
    countTitles.forEach(function(key) {
      var value = deliveryCounts[key];
      while (value > 0) {
        value -= 50;
        let vals = key.split("|||||");
        boxLabels.push({Organization: vals[0], Product: vals[1], Method: vals[2]});
      }
    });

    // Pickup boxes
    countTitles.forEach(function(key) {
      var value = groupPickupCounts[key];
      while (value > 0) {
        value -= 50;
        let vals = key.split("|||||");
        boxLabels.push({Organization: vals[0], Product: vals[1], Method: vals[2]});
      }

      value = indPickupCounts[key];
      while (value > 0) {
        value -= 50;
        let vals = key.split("|||||");
        boxLabels.push({Organization: vals[0], Product: vals[1], Method: vals[2]});
      }
    });
    boxLabels = boxLabels.sort((a,b) => {
      if (a.Organization === b.Organization) {
        if (a.Product === b.Product) {
          if (a.Method === b.Method) {
            return 0;
          } else {
            return b.Method.localeCompare(a.Method);
          }
        } else {
          return a.Product.localeCompare(b.Product);
        }
      } else {
        return a.Organization.localeCompare(b.Organization);
      }
    });
    this.setState({boxData: boxLabels});

    // Group deliveries and pickups
    orderCountTitles.forEach(function(key) {
      var vals = key.split("|||||");
      var org = vals[0];
      var product = vals[1];
      var customer = vals[2];
      filtered.forEach(order => {
        if (order.customer === customer) {
          order.line_items.forEach(line_item => {
            var organization = line_item.collection_name;
            if (line_item.title === product && organization === org) {
              var item = {};
              item.Organization = org;
              item.Product = product.replace('"', '”');
              item.Style = line_item.style.replace('"', '”');
              item.Color = line_item.color.replace('"', '”');
              item.Name = order.customer;
              item.Size = line_item.size.replace('"', '”');
              item.Method = line_item.shipping_type;
              item.Qty = 1;

              for( let i = parseInt(line_item.quantity); i > 0; i-- ) {
                tagData.push(item);
              }
            }
          });
        }
      });
    });

    const sizes = ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL'];
    tagData = tagData.sort((a,b) => {
      if (a.Organization === b.Organization) {
        if (a.Method === b.Method) {
          if (a.Product === b.Product) {
            if (a.Style === b.Style) {
              if (a.Color === b.Color) {
                if (a.Size === b.Size) {
                  return 0;
                } else {
                  var indexA = sizes.indexOf(a.Size);
                  var indexB = sizes.indexOf(b.Size);
                  return indexA - indexB;
                }
              } else {
                return a.Color.localeCompare(b.Color);
              }
            } else {
              return b.Style.localeCompare(a.Style);
            }
          } else {
            return a.Product.localeCompare(b.Product);
          }
        } else {
          return a.Method.localeCompare(b.Method);
        }
      } else {
        return a.Organization.localeCompare(b.Organization);
      }
    });

    this.setState({tagData: tagData});
  }

  searchSku(body) {
    const params = Object.keys(body).map((key) => { return encodeURIComponent(key) + '=' + encodeURIComponent(body[key]);}).join('&');
    fetch('/api/orders?' + params, {
      method: 'GET',
      headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}
    })
    .then(response => api.authCheck(response))
    .then(data => {
      if (data['status']) {
        this.isSearching = false;

        var orders = data['data'];

        this.setState({filteredOrders: orders});
        this.setState({orders: orders});
        this.organizeResults(orders);
      }
    });
  }

  renderBoxLabels() {
    var data = this.state.boxData;
    return data.map(order => {
      return ([
        <div>
        {order.Organization}
        </div>,
        <div>
        {order.Product}
        </div>,
        <div>
        {order.Method}
        </div>
      ]);
    })
  }

  renderTags() {
    var data = this.state.tagData;
    return data.map(order => {
      return ([
        <div>
        {order.Organization}
        </div>,
        <div>
        {order.Product}
        </div>,
        <div>
        {order.Style}
        </div>,
        <div>
        {order.Color}
        </div>,
        <div>
        {order.Name}
        </div>,
        <div>
        {order.Size}
        </div>,
        <div>
        {order.Method}
        </div>,
        <div>
          <span>
          {order.Qty}
          </span>
        </div>
      ]);
    })
  }

  renderStatusModal(currentStatus) {
    const { classes } = this.props;
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal
        }}
        open={this.state.newStatusSelected != null}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.handleCloseStatusModal()}
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
      >
        <DialogContent
          id="notice-modal-slide-description"
          className={classes.modalBody}
        >
        <p className={classes.alertText}>The print tag status for all displayed orders will be changed from <b>{currentStatus}</b> to <b>{this.state.newStatusSelected}</b>.</p>
        </DialogContent>
        <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter }>
          <Button
            onClick={() => this.handleSaveStatusChange()}
            color="info" >
            Set Print Tags Status
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderSummary() {
    const { classes } = this.props;
    let data = [];
    let summary = this.state.filteredSummary;
    let products = Object.keys(summary);
    products.sort().forEach(product => {
      let orgs = summary[product];
      let organizations = Object.keys(orgs);
      data.push({org: "", product: product, sku: "", count: ""})
      organizations.sort().forEach(org => {
        let skus = orgs[org];
        let sks = Object.keys(skus).sort((a, b) => {
          let anums = a.split('-');
          let bnums = b.split('-');
          let anum = anums[anums.length - 1];
          let bnum = bnums[bnums.length - 1];
          let aInt = parseInt(anum);
          let bInt = parseInt(bnum);
          if (aInt > 0 && bInt > 0) {
            return aInt - bInt;
          }
          return 0;
        });
        let orgName = org.length > 0 ? org : "<No org>";
        data.push({org: orgName, product: "", sku: "", count: "", productTitle: product})
        sks.forEach(sku => {
          let count = skus[sku];
          data.push({org: "", product: "", sku: sku, count: count, orgName: orgName, productTitle: product});
        });
      });
    });

    return data.map(order => {
      let removeText = "Remove " + order.sku;
      let color = "plain";
      if (order.org.length > 0) {
        color = "style";
        removeText = "Remove " + order.org;
      } else  if (order.product.length > 0) {
        color = "product";
        removeText = "Remove " + order.product;
      }
      return ({color: color, data:[
        <div>
        {order.product.length > 0 &&
          <span>
            <strong>{order.product}</strong>
          </span>
        }
        </div>,
        <div>
        {order.org.length > 0 &&
          <span>
            {order.org}
          </span>
        }
        </div>,
        <div>
        {order.sku}
        </div>,
        <div>
        {order.count}
        </div>,
        <Tooltip
          id="tooltip-top-start"
          title={removeText}
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <IconButton
            aria-label="Close"
            className={classes.tableActionButton}
          >
            <Close
              className={
                classes.tableActionButtonIcon + " " + classes.close
              }
              onClick={() => this.handleRemoveItems(order)}
            />
          </IconButton>
        </Tooltip>
      ]});
    })
  }

  render() {
    const { classes } = this.props;
    const count = this.lineItemsVisible.length;
    const prefix = this.cachePrefix;
    var currentStatus = this.state.searchStatus;
    var printTagStatus = "";
    var warning = "";
    let containsMixedStatuses = false;
    if (count > 0) {
      var printStatuses = this.lineItemsVisible.map(item => item.status);
      const statusSet = new Set(printStatuses);
      currentStatus = printStatuses[0];
      printTagStatus = "Print Tags " + currentStatus;

      containsMixedStatuses = statusSet.size > 1;
      if (statusSet.size > 1) {
        warning = 'This selection includes items with different Print Tag statuses. Bulk status change will be disabled.';
      }
    }

    return (
      <GridContainer justify="center">
      {this.renderStatusModal(currentStatus)}
      {this.state.searchString.length === 0 ? (
        <GridItem xs={12}>
          <h4 className={classes.center}>All line items marked as <b>Print Tags Ready</b> are included below. You may also search for any SKU in the system.</h4>
        </GridItem>
      ) : (
        <GridItem xs={12} className={classes.center}>
        {warning.length > 0 ? (
            <h4 className={classes.warning}>{warning}</h4>
        ) : null }
        </GridItem>
      )}
        <GridItem xs={12} className={classes.searchInputContainer}>
          <CustomInput
            id="sku"
            inputProps={{
              defaultValue: this.defaultSearch,
              inputProps: {className: classes.largeText},
              onChange: (e) => this.handleChange(e),
              placeholder: "Search SKU"
            }}
            formControlProps={{
              fullWidth: true
            }} />
        </GridItem>
        { prefix.length > 0 &&
          <GridItem xs={12} className={classes.center}>
          <NavPills
            alignCenter
            color="warning"
            active={this.state.filterIndex}
            onChange={(e) => this.handleNavigationChange(e)}
            tabs={[
              {
                tabButton: "All"
              },
              {
                tabButton: "Pending"
              },
              {
                tabButton: "Ready"
              },
              {
                tabButton: "Complete"
              }
            ]}
          />
          </GridItem>
        }
        { count > 0 &&
          <GridItem xs={12} className={classes.center + " " + classes.summaryGridItem}>
            <CardBody className={classes.summaryCard}>
              <h2>Summary</h2>
              <p>Remove any items by SKU, product, or organization that don't belong in this report.</p>
              {this.blacklist.length > 0 &&
                <Button
                  onClick={() => this.handleEmptyBlacklist()}
                  color="danger"
                  simple
                  >
                  {this.blacklist.length} item{this.blacklist.length > 1 ? 's' : ''} removed
                </Button>
              }
              <Table
                tableData={this.renderSummary()}
                customHeadCellClasses={[
                  classes.widthFixed50,
                  classes.widthFixed50
                ]}
                customHeadClassesForCells={[3, 4]}
                customCellClasses={[
                  classes.widthFixed50,
                  classes.widthFixed50
                ]}
                customClassesForCells={[3, 4]}
              />
            </CardBody>
          </GridItem>
        }
        { count > 0 && !containsMixedStatuses &&
        <GridItem xs={10} className={classes.center}>
          <CustomDropdown
            buttonText={printTagStatus}
            buttonProps={{
              round: true,
              style: { margin: "16px" },
              color: "rose"
            }}
            dropdownList={["Print Tags Pending", "Print Tags Ready", "Print Tags Complete"]}
            onClick={(e) => this.handleStatusChoice(e)}
          />
        </GridItem>
        }
        { this.state.boxData.length > 0 &&
          <GridItem xs={12} className={classes.center + " " + classes.labelGridItem}>
            <CardBody className={classes.exportCard}>
              <h3>Box Labels</h3>
              <CSVLink data={this.state.boxData} filename={this.state.searchString + "-box-labels.csv"} className={classes.button}>
                Export CSV
              </CSVLink>
              <Button
                onClick={() => this.setState({viewMode: "Box"})}
                color="info" >
                View
              </Button>
            </CardBody>
          </GridItem>
        }
        { this.state.tagData.length > 0 &&
        <GridItem xs={12} className={classes.center + " " + classes.labelGridItem}>
          <CardBody className={classes.exportCard}>
            <h3>Tag Labels</h3>
            <CSVLink data={this.state.tagData} filename={this.state.searchString + "-tag-labels.csv"} className={classes.button}>
              Export CSV
            </CSVLink>
            <Button
              onClick={() => this.setState({viewMode: "Tag"})}
              color="info" >
              View
            </Button>
          </CardBody>
        </GridItem>
        }
        { this.state.viewMode === "Box" && this.state.boxData.length > 0 &&
          <Card className={classes.basicCard}>
            <h3>Box Labels</h3>
            <CardBody>
              <Table
                tableHead={['Organization', 'Product', "Method"]}
                tableData={this.renderBoxLabels()}
              />
            </CardBody>
          </Card>
        }
        { this.state.viewMode === "Tag" && this.state.tagData.length > 0 &&
          <Card className={classes.basicCard}>
            <h3>Tag Labels</h3>
            <CardBody>
              <Table
                tableHead={['Organization', 'Product', 'Style', 'Color', 'Name', 'Size', 'Method', 'Qty']}
                tableData={this.renderTags()}
              />
            </CardBody>
          </Card>
        }
      </GridContainer>
    );
  }
}

PrintTagTables.propTypes = {
  classes: PropTypes.object
};

export default withStyles(reportStyles)(withGracefulUnmount(PrintTagTables));
