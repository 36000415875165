import React from "react";
import PropTypes from "prop-types";

import { CSVLink } from "react-csv";
import Moment from "react-moment";
import ReactTable from "react-table";

// core components
import Button from "components/CustomButtons/Button.jsx";
import CardText from "components/Card/CardText.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Slide from "@material-ui/core/Slide";
import Snackbar from "components/Snackbar/Snackbar.jsx";

import api from "state/api";
import combineStyles from "assets/jss/material-dashboard-pro-react/combineStyles.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import tableStyle from "assets/jss/material-dashboard-pro-react/tableStyle.jsx";
import { withStyles } from "@material-ui/core/styles";
import withGracefulUnmount from "react-graceful-unmount";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

class NotificationTables extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmingBulkEdit: false,
            filteredData: [],
            loading: true,
            notification: null,
            orders: [],
            sendingNotification: false,
            updating: false,

            sorted: [
                {
                    id: "s.shipment_id",
                    desc: true,
                },
            ],
            page: 0,
            pageSize: 20,
            expanded: {},
            resized: [],
            filtered: [],
        };

        this.dataTable = React.createRef();
        this.delayTimer = null;
        this.lineItemCount = 0;
        this.fetchData = this.fetchData.bind(this);
        this.selectedItem = {};
        this.userPermissions = [];
    }

    componentWillUnmount() {
        let state = this.state;
        delete state.orders;
        delete state.filteredData;
        state.sorted = this.dataTable.current.state.sorted;
        state.page = this.dataTable.current.state.page;
        state.pageSize = this.dataTable.current.state.pageSize;
        state.expanded = this.dataTable.current.state.expanded;
        state.resized = this.dataTable.current.state.resized;
        state.filtered = this.dataTable.current.state.filtered;
        localStorage.setItem("NotificationTables", JSON.stringify(state));
    }

    componentWillMount() {
        let rehydrate = JSON.parse(localStorage.getItem("NotificationTables"));
        if (rehydrate) {
            rehydrate.updating = false;
            this.setState(rehydrate);
        }
    }

    componentDidMount() {
        this.updateSearch();

        fetch("/api/user/roles")
            .then((response) => response.text())
            .then((data) => (this.userPermissions = data));
    }

    fetchData(state, instance) {
        if (this.dataTable.current) {
            this.setState({ loading: true });

            if (this.delayTimer) {
                clearTimeout(this.delayTimer);
            }
            this.delayTimer = setTimeout(() => {
                this.updateSearch();
            }, 300);
        }
    }

    handleCloseNotificationModal() {
        this.selectedItem = {};
        this.setState({ sendingNotification: false });
        this.setState({ confirmingBulkEdit: false });
    }

    handleClick(item) {
        this.setState(
            {
                updating: true,
            },
            () => {
                this.selectedItem = item;
                this.setState({ sendingNotification: true });
            }
        );
    }

    handleBulkSendNotifications() {
        if (!this.state.confirmingBulkEdit) {
            this.setState({ confirmingBulkEdit: true });
            return;
        }

        this.updateSearch((ids) => {
            let data = ids.join(",");
            const body = { shipment_ids: data };
            fetch("/api/notifications/shipping", {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            })
                .then((response) => api.authCheck(response))
                .then((data) => {
                    this.updateSearch();
                });
        });

        this.handleCloseNotificationModal();
    }

    handleSendNotifications() {
        if (this.state.confirmingBulkEdit) {
            this.setState({ confirmingBulkEdit: false });
            return;
        }

        const body = {
            shipment_ids: this.selectedItem.shipment_id,
        };
        fetch("/api/notifications/shipping", {
            credentials: "include",
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        }).then((data) => {
            this.updateSearch();
        });

        this.showNotification(`Shipment notification has been scheduled.`);

        this.handleCloseNotificationModal();
    }

    renderNotificationModal() {
        const { classes } = this.props;
        const bulkButton = this.state.confirmingBulkEdit
            ? "Confirm Bulk Edit"
            : "Bulk edit";
        const singleButton = this.state.confirmingBulkEdit
            ? "Cancel"
            : "Notify";

        return (
            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal,
                }}
                open={this.state.sendingNotification}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.handleCloseNotificationModal()}
                aria-labelledby="notice-modal-slide-title"
                aria-describedby="notice-modal-slide-description"
            >
                <DialogContent
                    id="notice-modal-slide-description"
                    className={classes.modalBody}
                >
                    <h4 className={classes.dialogHeader}>
                        Send a shipping notification for this item or all{" "}
                        {this.lineItemCount.toLocaleString()} line items in this
                        search?
                    </h4>

                    {!this.state.confirmingBulkEdit ? (
                        <p>
                            A shipping notification will be sent to{" "}
                            {this.selectedItem.email} for this item.
                        </p>
                    ) : (
                        <p className={classes.dialogDangerHeader}>
                            Shipping notifications will be sent for{" "}
                            {this.lineItemCount.toLocaleString()} shipments.
                        </p>
                    )}
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    <div className={classes.fullWidth}>
                        {this.lineItemCount > 1 &&
                            this.userPermissions.includes("admin") && (
                                <Button
                                    onClick={() =>
                                        this.handleBulkSendNotifications()
                                    }
                                    color="danger"
                                    round
                                >
                                    {bulkButton}
                                </Button>
                            )}
                        <Button
                            onClick={() => this.handleSendNotifications()}
                            color="info"
                            round
                        >
                            {singleButton}
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        );
    }

    showNotification(message) {
        this.setState({ notification: message });
        setTimeout(
            function() {
                this.setState({ notification: null });
            }.bind(this),
            3000
        );
    }

    updateSearch(lineItemIdCallback = null) {
        this.setState({ loading: true });

        let filters = {};
        if (this.dataTable.current) {
            let state = this.dataTable.current.state;
            filters = {
                page_size: state.pageSize,
                page: state.page,
                sorted: JSON.stringify(state.sorted),
                filtered: JSON.stringify(state.filtered),
                total_page_count: true,
            };
        }

        filters["reportType"] = "shipping-notification";

        if (lineItemIdCallback) {
            filters["only_line_items"] = true;
        }

        var body = filters ? filters : {};
        const params = Object.keys(body)
            .map((key) => {
                return (
                    encodeURIComponent(key) +
                    "=" +
                    encodeURIComponent(body[key])
                );
            })
            .join("&");
        const url = params.length > 0 ? "/api/orders?" : "/api/orders";

        fetch(url + params, {
            method: "GET",
            headers: {
                "Content-Type":
                    "application/x-www-form-urlencoded;charset=UTF-8",
            },
        })
            .then((response) => api.authCheck(response))
            .then((data) => {
                if (!lineItemIdCallback) {
                    this.setState({ loading: false });
                }

                if (data["status"]) {
                    if (lineItemIdCallback) {
                        let items = data["data"];
                        lineItemIdCallback(items);
                    } else {
                        let orders = data["data"];
                        this.setState({ pages: parseInt(data["pages"]) });
                        this.setState({ orders: orders });
                        this.lineItemCount = parseInt(data["count"]);
                    }
                }
            });
    }

    renderCard() {
        const { classes } = this.props;
        return (
            <Card>
                <CardBody className={classes.cardBodyProducts}>
                    {this.renderOrders()}
                </CardBody>
            </Card>
        );
    }

    renderOrders() {
        const { classes } = this.props;
        return (
            <ReactTable
                ref={this.dataTable}
                data={this.state.orders}
                pages={this.state.pages}
                filterable
                manual
                columns={[
                    {
                        Header: "Action",
                        sortable: false,
                        filterable: false,
                        Cell: ({ row }) => (
                            <Button
                                simple
                                color="white"
                                className={classes.actionButton}
                                onClick={() => this.handleClick(row._original)}
                            >
                                Notify
                            </Button>
                        ),
                        width: 140,
                        className: classes.center,
                    },
                    {
                        Header: "Order",
                        accessor: "order_number",
                        maxWidth: 120,
                    },
                    {
                        Header: "Tracking",
                        accessor: "tracking_number",
                        sortable: false,
                        filterable: false,
                        width: 190,
                    },
                    {
                        Header: "SKUs",
                        accessor: "skus",
                        minWidth: 140,
                    },
                    {
                        Header: "Shipped",
                        accessor: "ship_date",
                        filterable: false,
                        Cell: ({ row }) => (
                            <span>
                                {row._original.ship_date && (
                                    <Moment format="MM/DD/YY">
                                        {row._original.ship_date}
                                    </Moment>
                                )}
                            </span>
                        ),
                        width: 90,
                    },
                    {
                        Header: "Email",
                        accessor: "email",
                    },
                ]}
                loading={this.state.loading}
                showPaginationTop={false}
                showPaginationBottom={true}
                className="-striped -highlight"
                onFetchData={this.fetchData} // Request new data when things change
                defaultSorted={this.state.sorted}
                defaultPage={this.state.page}
                defaultPageSize={this.state.pageSize}
                defaultExpanded={this.state.expanded}
                defaultResized={this.state.resized}
                defaultFiltered={this.state.filtered}
            />
        );
    }

    render() {
        const { classes } = this.props;
        const headers = ["Order", "Tracking", "SKUs", "Shipped", "Email"];
        const dataNames = [
            "order_number",
            "tracking",
            "skus",
            "ship_date",
            "email",
        ];
        return (
            <div className={classes.center}>
                <Snackbar
                    place="br"
                    color="danger"
                    message={this.state.notification}
                    open={this.state.notification != null}
                />
                <GridItem xs={12} className={classes.marginTop}>
                <h4 className={classes.center}>Shipments listed below are currently in transit and have not been notified.</h4>
                </GridItem>
                <GridContainer justify="center">
                    <GridItem xs={0} md={8} />
                    {this.renderNotificationModal()}
                    <GridItem xs={12}>{this.renderCard()}</GridItem>
                    <GridItem xs={12}>
                        <CardText color="rose" className={classes.dateCardText}>
                            <CSVLink
                                data={this.state.filteredData}
                                asyncOnClick={true}
                                filename={"shipments_not_notified.csv"}
                                headers={headers}
                                onClick={(event, done) => {
                                    this.setState(
                                        {
                                            updating: true,
                                        },
                                        () => {
                                            var currentRecords = this.dataTable.current.getResolvedState()
                                                .sortedData;
                                            var data_to_download = [];
                                            currentRecords.forEach(function(
                                                item,
                                                index
                                            ) {
                                                var fi = {};
                                                dataNames.forEach(function(
                                                    name,
                                                    i
                                                ) {
                                                    var header = headers[i];
                                                    var value = item[name];
                                                    fi[header] = value
                                                        ? value.replace(
                                                              '"',
                                                              "”"
                                                          )
                                                        : "";
                                                });
                                                data_to_download.push(fi);
                                            });
                                            this.setState(
                                                {
                                                    filteredData: data_to_download,
                                                },
                                                () => {
                                                    done();
                                                }
                                            );
                                        }
                                    );
                                }}
                                className={classes.exportButton}
                            >
                                Export CSV
                            </CSVLink>
                        </CardText>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

NotificationTables.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(combineStyles(tableStyle, modalStyle))(
    withGracefulUnmount(NotificationTables)
);
