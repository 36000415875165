import React from "react";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import TimeAgo from 'react-timeago'

const style = {
  inlineRefreshContainer: {
    margin: "10px 15px -20px",
    zIndex: "999"
  },
  refreshContainer: {
    margin: "10px 15px -20px",
    width: "100%",
    zIndex: "999"
  },
  rightFloat: {
    float: "right",
    marginLeft: "8px",
  },
  rotating: {
    animation: "rotation 2s infinite linear"
  },
};

function RefreshSpinner({ ...props }) {
  const { classes, children, className, updatedDate, onClick, refreshing, updateCount, objectName, ...rest } = props;
  var classNames = classes.rightFloat;
  if (refreshing) {
    classNames += " " + classes.rotating;
  }

  var date = updatedDate ? updatedDate : '2000-01-01T12:00:00Z';
  var count = updateCount;
  if (!updateCount) {
    count = 0;
  }

  var allClasses = classes.refreshContainer + " " + className;
  if (!objectName) {
    allClasses = classes.inlineRefreshContainer + " " + className;
  }
  return (
    <div {...rest} onClick={onClick} className={allClasses}>
      <FontAwesomeIcon icon={faRedoAlt} size="lg" className={classNames} />
      <span className={classes.rightFloat}>
        {refreshing !== true && date !== '2000-01-01T12:00:00Z' ? (
          <span>Last updated&nbsp;
            <TimeAgo date={date} />
          </span>
        ) : null}

        {refreshing === true && count > 0 &&
          <span>
            <span>
              {count} {objectName} updated
            </span>
          </span>
        }
      </span>
    </div>
  );
}

RefreshSpinner.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  objectName: PropTypes.string,
  onClick: PropTypes.func,
  refreshing: PropTypes.bool,
  updateCount: PropTypes.number,
  updatedDate: PropTypes.string
};

export default withStyles(style)(RefreshSpinner);
