const aggregationMethods = ["None", "Product", "Product & Org", "SKU"];
const shippingTitlesFulfillment = ["Pending", "Shipping"];
const statusTitlesFulfillment = ["Pending", "On Deck", "Scheduled", "Ready"];
const statusTitlesShort = ["Pending", "On Deck", "Ready", "Pick List", "Picking", "Checkout", "Complete", "Refund Req", "Refunded"];
const statusTitlesShortPlus = ["Pending", "On Deck", "Ready", "Pick List", "Picking", "Checkout", "Complete", "Refund Req", "Refunded", "Missing", "All"];
const statusTitlesLong  = ["Pending", "On Deck", "Ready", "Warehouse Pick List", "Warehouse Picking", "Front Desk Checkout", "Complete", "Refund Required", "Refunded"];
const statusToShortMap  = {Pending: "Pending", "On Deck": "On Deck", Ready: "Ready", "Warehouse Pick List": "Pick List", "Warehouse Picking": "Picking",
                           "Front Desk Checkout": "Checkout", Complete: "Complete", "Refund Required": "Refund Req", "Refunded": "Refunded"};
const statusToLongMap   = {Pending: "Pending", "On Deck": "On Deck", Ready: "Ready", "Pick List": "Warehouse Pick List", Picking: "Warehouse Picking",
                            Checkout: "Front Desk Checkout", Complete: "Complete", "Refund Req": "Refund Required", "Refunded": "Refunded"};
const states = {
    "AL": "Alabama",
    "AK": "Alaska",
    "AS": "American Samoa",
    "AZ": "Arizona",
    "AR": "Arkansas",
    "CA": "California",
    "CO": "Colorado",
    "CT": "Connecticut",
    "DE": "Delaware",
    "DC": "District Of Columbia",
    "FM": "Federated States Of Micronesia",
    "FL": "Florida",
    "GA": "Georgia",
    "GU": "Guam",
    "HI": "Hawaii",
    "ID": "Idaho",
    "IL": "Illinois",
    "IN": "Indiana",
    "IA": "Iowa",
    "KS": "Kansas",
    "KY": "Kentucky",
    "LA": "Louisiana",
    "ME": "Maine",
    "MH": "Marshall Islands",
    "MD": "Maryland",
    "MA": "Massachusetts",
    "MI": "Michigan",
    "MN": "Minnesota",
    "MS": "Mississippi",
    "MO": "Missouri",
    "MT": "Montana",
    "NE": "Nebraska",
    "NV": "Nevada",
    "NH": "New Hampshire",
    "NJ": "New Jersey",
    "NM": "New Mexico",
    "NY": "New York",
    "NC": "North Carolina",
    "ND": "North Dakota",
    "MP": "Northern Mariana Islands",
    "OH": "Ohio",
    "OK": "Oklahoma",
    "OR": "Oregon",
    "PW": "Palau",
    "PA": "Pennsylvania",
    "PR": "Puerto Rico",
    "RI": "Rhode Island",
    "SC": "South Carolina",
    "SD": "South Dakota",
    "TN": "Tennessee",
    "TX": "Texas",
    "UT": "Utah",
    "VT": "Vermont",
    "VI": "Virgin Islands",
    "VA": "Virginia",
    "WA": "Washington",
    "WV": "West Virginia",
    "WI": "Wisconsin",
    "WY": "Wyoming"
}

export {
  aggregationMethods,
  shippingTitlesFulfillment,
  statusTitlesFulfillment,
  statusTitlesShort,
  statusTitlesShortPlus,
  statusTitlesLong,
  statusToShortMap,
  statusToLongMap,
  states
}
