import Cookies from 'js-cookie';

export const initialState = {
  user: {
    session_id: null,
    client_id: null,
    roles: null,
    name: null,
    isAuthenticated: false
  }
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'login': {
      const loginData = action.data;

      return {
        ...state,
        user: {
          ...state.user,
          session_id: Cookies.get('PHPSESSID'),
          client_id: loginData.client_id,
          roles: loginData.roles,
          isAuthenticated: true,
        }
      };
    }

    case 'register': {
      const registerData = action.data

      return {
        ...state,
        user: {
          ...state.user,
          session_id: Cookies.get('PHPSESSID'),
          client_id: registerData.client_id,
          roles: registerData.roles,
          isAuthenticated: true,
        }
      }
    }

    case 'logout': {
      return {
        ...state,
        user: initialState.user,
      }
    }

    default:
      return state;
  }
};
