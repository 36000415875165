import CatalogProductTables from "views/Tables/CatalogProductTables.jsx";
import CollectionTables from "views/Tables/CollectionTables.jsx";
import CustomerTables from "views/Tables/CustomerTables.jsx";
import Dashboard from "views/Dashboard/Dashboard.jsx";
import DesignGalleryTables from "views/Tables/DesignGalleryTables.jsx";
import EndOfDayReport from "views/Reports/EndOfDayReport.jsx";
import FulfillmentOverview from "views/Reports/FulfillmentOverview.jsx";
import FulfillmentTables from "views/Tables/FulfillmentTables.jsx";
import NotificationTables from "views/Tables/NotificationTables";
import OrderTables from "views/Tables/OrderTables.jsx";
import ProductTables from "views/Tables/ProductTables.jsx";
import PrintTagsReport from "views/Reports/PrintTagsReport.jsx";
import PurchasingReport from "views/Reports/PurchasingReport.jsx";
import ShippingReport from "views/Reports/ShippingReport.jsx";
import ShippingTables from "views/Tables/ShippingTables.jsx";
import UpInHouseReport from "views/Reports/UpInHouseReport.jsx";
import UserTables from "views/Tables/UserTables.jsx";
import Login from "views/Pages/LoginPage.jsx";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";

var dashRoutes = [
    {
        primary: "dashboard",
        path: "/dashboard",
        name: "Dashboard",
        icon: DashboardIcon,
        component: Dashboard,
        layout: "/admin",
    },
    {
        primary: "reports",
        collapse: true,
        name: "Reports",
        icon: "description",
        state: "reportsCollapse",
        views: [
            {
                primary: "reports",
                path: "/up-in-house",
                name: "Up In House",
                mini: "UIH",
                component: UpInHouseReport,
                layout: "/reports",
            },
            {
                primary: "reports",
                path: "/end-of-day",
                name: "End of Day",
                mini: "EOD",
                component: EndOfDayReport,
                layout: "/reports",
            },
            {
                primary: "reports",
                path: "/print-tags",
                name: "Print Tags",
                mini: "PT",
                component: PrintTagsReport,
                layout: "/reports",
            },
            {
                primary: "reports",
                path: "/purchasing",
                name: "Purchasing",
                mini: "P",
                component: PurchasingReport,
                layout: "/reports",
            },
            {
                primary: "reports",
                path: "/shipping",
                name: "Shipping",
                mini: "S",
                component: ShippingReport,
                layout: "/reports",
            },
        ],
    },
    {
        primary: "orders",
        path: "/orders",
        name: "Orders",
        icon: "shopping_basket",
        component: OrderTables,
        layout: "/admin",
    },
    {
        primary: "products",
        collapse: true,
        name: "Products",
        icon: "local_offer",
        state: "productsCollapse",
        views: [
            {
                primary: "products",
                path: "/sale",
                name: "Sale Products",
                mini: "SP",
                component: ProductTables,
                layout: "/products",
            },
            {
                primary: "products",
                path: "/catalog",
                name: "Product Catalog",
                mini: "PC",
                component: CatalogProductTables,
                layout: "/products",
            },
            {
                primary: "products",
                path: "/design-gallery",
                name: "Design Gallery",
                mini: "DG",
                component: DesignGalleryTables,
                layout: "/products",
            },
        ],
    },
    {
        primary: "fulfillment",
        collapse: true,
        name: "Fulfillment",
        icon: "forward",
        state: "fulfillmentCollapse",
        views: [
            {
                primary: "fulfillment",
                path: "/overview",
                name: "Overview",
                mini: "O",
                component: FulfillmentOverview,
                layout: "/fulfillment",
            },
            {
                primary: "fulfillment",
                path: "/scheduling",
                name: "Scheduling",
                mini: "S",
                component: FulfillmentTables,
                layout: "/fulfillment",
            },
            {
                primary: "fulfillment",
                path: "/shipments",
                name: "Shipments",
                mini: "Sh",
                component: ShippingTables,
                layout: "/fulfillment",
            },
            {
                primary: "fulfillment",
                path: "/notifications",
                name: "Notifications",
                mini: "N",
                component: NotificationTables,
                layout: "/fulfillment",
            },
        ],
    },
    {
        primary: "collections",
        path: "/collections",
        name: "Collections",
        icon: "group",
        component: CollectionTables,
        layout: "/admin",
    },
    {
        primary: "customers",
        path: "/customers",
        name: "Customers",
        icon: "perm_contact_calendar",
        component: CustomerTables,
        layout: "/admin",
    },
    {
        primary: "admin",
        path: "/users",
        name: "Users",
        icon: "verified_user",
        component: UserTables,
        layout: "/admin",
    },
    {
        redirect: true, // Prevent from appearing in Admin's Sidenav
        path: "/login-page",
        name: "Login",
        component: Login,
        layout: "/auth",
    },
];
export default dashRoutes;
