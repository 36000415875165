import React from "react";
import PropTypes from "prop-types";

import { useTheme, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Table from "components/Table/Table.jsx";

import upInHouseCellStyle from "assets/jss/material-dashboard-pro-react/components/upInHouseCellStyle.jsx";

function renderVariants(variants, props) {
  const { classes } = props;
  return variants.map(variant => {
    return ([
      <div className={classes.imgContainer}>
        {variant['src'] ? (
          <img src={variant['src'].replace(".jpg", "_small.jpg")} alt='aProduct' className={classes.img} />
        ) : (
          <img src={variant['default_image'].replace(".jpg", "_small.jpg")} alt='zProduct' className={classes.img} />
        )}
      </div>,
      <span>{variant['size']}</span>,
      <span>{variant['color']}</span>,
      <span>{variant['style']}</span>,
      <span>{variant['sku']}</span>,
      <span>${variant['price']}</span>,
      <span>
      {variant['count'] > 0 &&
        <span>{variant['count']}</span>
      }
      </span>
    ]);
  })
}

function renderVariantsForSmallScreen(variants, props) {
  const { classes } = props;
  return variants.map(variant => {
    return ([
      <div className={classes.imgContainer}>
        <img src={variant['src'].replace(".jpg", "_small.jpg")} alt='Product' className={classes.img} />
      </div>,
      <div className={classes.variantContainer}>
        {variant['size'] &&
          <span><strong>{variant['size']} - {variant['color']} - {variant['style']}</strong></span>
        }
        <span>{variant['sku']}</span>
        <span>${variant['price']}
        {variant['count'] > 0 &&
          <span className={classes.countOrdered}>{variant['count']}</span>
        }
        </span>
      </div>
    ]);
  })
}

function UpInHouseCellCollection({ ...props }) {
  const { classes, product } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <div className={classes.noOverflow}>
    {matches ? (
      <Table
        tableHead={["", "SIZE", "COLOR", "STYLE", "SKU", "PRICE", "QTY"]}
        tableData={renderVariants(product.variants, props)}
        tableShopping
        customHeadCellClasses={[
          classes.widthFixed50,
          classes.widthFixed50,
          classes.left,
          classes.left,
          classes.left,
          classes.widthFixed75,
          classes.widthFixed75,
        ]}
        customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
        customCellClasses={[
          classes.imgCell,
          classes.widthFixed50,
          classes.left,
          classes.left,
          classes.left,
          classes.widthFixed75,
          classes.widthFixed75Bold
        ]}
        customClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
      />
    ) : (
      <Table
        tableHead={[]}
        tableData={renderVariantsForSmallScreen(product.variants, props)}
        tableShopping
        customCellClasses={[classes.imgCell]}
        customClassesForCells={[0]}
      />
    )}
    </div>
  );
}

UpInHouseCellCollection.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  product: PropTypes.object.isRequired
};

export default withStyles(upInHouseCellStyle)(UpInHouseCellCollection);
