import React from "react";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";

import {
  grayColor
} from "assets/jss/material-dashboard-pro-react.jsx";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardText from "components/Card/CardText.jsx";
import LineItemCellCollection from "components/Cells/LineItemCellCollection.jsx";

const style = theme => ({
  cardOrderCount: {
    color: "white",
    fontWeight: "500",
    lineHeight: "1.1em",
    margin: "0"
  },
  cardOrderTitle: {
    color: "white",
    fontWeight: "400",
    lineHeight: "1.1em",
    margin: "0"
  },
  cardHeader: {
    display: "inline-flex !important",
    justifyContent: "space-between",
    marginTop: "-40px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "-20px",
      marginRight: "-20px",
      padding: "0"
    },
  },
  contentDiv: {
    display: "flex",
    flexDirection: "column"
  },
  dateCardButton: {
    borderTop: "1px solid #ffffff55",
    paddingLeft: "0",
    paddingRight: "0",
    width: "100%"
  },
  dateCardText: {
    marginLeft: "8px",
    marginTop: "0",
    paddingBottom: "0",
    paddingTop: "0",
    width: "150px",
    [theme.breakpoints.down("xs")]: {
      width: "100px"
    },
  },
  dateHeaderContainer: {
    alignItems: "flex-start",
    display: "inline-flex",
  },
  dateSubtitle: {
    color: "white",
    fontSize: "0.9em",
    marginTop: "0",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8em",
    },
  },
  dateTitle: {
    color: "white",
    fontWeight: "400",
    marginBottom: "0",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.1em",
    },
  },
  hideOnMobile: {
    [theme.breakpoints.down("xs")]: {
      display: "none !important"
    },
  },
  infoSpan: {
    paddingTop: "14px !important",
    verticalAlign: "top",
    [theme.breakpoints.up("lg")]: {
      width: "30%"
    },
  },
  lineItemDiv: {
    flexDirection: "column",
    marginLeft: "-4px"
  },
  notificationButton: {
    borderTop: "1px solid #ffffff55",
    marginBottom: "0"
  },
  notificationCardText: {
    marginTop: "0",
    paddingBottom: "0",
    paddingTop: "0",
    [theme.breakpoints.down("xs")]: {
      marginTop: "8px"
    },
  },
  notificationFooter: {
    marginBottom: "-48px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column"
    },
  },
  notificationSubtitle: {
    color: "white",
    fontSize: "0.9em",
    marginTop: "0"
  },
  notificationTitle: {
    color: "white",
    fontWeight: "400",
    marginBottom: "0"
  },
  numberText: {
    alignItems: "center",
    display: "flex",
    marginTop: "0",
    paddingBottom: "0",
    paddingTop: "0"
  },
  orderCard: {
    marginTop: "40px",
    marginBottom: "0",
    padding: "0px 15px",
    display: "flex",
    flexDirection: "column",
  },
  orderCardBody: {
    marginTop: "-15px",
    padding: "0",
    "& tr td": {
      padding: "6px 8px",
      borderWidth: "1px"
    },

    [theme.breakpoints.down("xs")]: {
      marginTop: "40px",
      borderTop: "none",
      paddingTop: "10px",
      "& tr:first-child td": {
        border: "none"
      }
    },
  },
  orderCardHeader: {
    fontSize: "1.0em",
    fontWeight: "500",
    marginBottom: "-40px",
    marginLeft: "-12px",
    marginTop: "20px !important",
    padding: "5px 10px !important",
    textTransform: "uppercase"
  },
  square: {
    alignItems: "center",
    display: "inline-flex",
    flexDirection: "column",
    height: "100px",
    justifyContent: "center",
    marginTop: "0",
    width: "100px",
    verticalAlign: "middle"
  },
  textHeavy: {
    display: "block",
    fontWeight: "400",
    fontSize: "1.5em",
    marginBottom: "3px",
    minWidth: "200px",
  },
  textMedium: {
    color: grayColor[1],
    display: "block",
    fontWeight: "400",
    fontSize: "1.2em",
    minWidth: "200px",
  },
  textSmall: {
    color: grayColor[1],
    display: "block",
    fontSize: "0.8em",
    fontWeight: "300",
    paddingBottom: "12px"
  }
});

function OrderCard({ ...props }) {
  const { classes, order } = props;

  // Formatted dates
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const updatedString = new Date(order.updated_at).toLocaleString('en-us', options);

  return (
    <Card className={classes.orderCard}>
      <CardHeader className={classes.cardHeader} text>
        <CardText color="danger" className={classes.numberText}>
          <h4 className={classes.cardOrderTitle}>{order.name}</h4>
        </CardText>
        <div className={classes.dateHeaderContainer}>
          <div>
            <CardText color="success" className={classes.dateCardText}>
              <h4 className={classes.dateTitle}>Updated</h4>
              <h5 className={classes.dateSubtitle}>
                {updatedString}
              </h5>
            </CardText>
          </div>
        </div>
      </CardHeader>
      <div className={classes.contentDiv}>
        <span className={classes.infoSpan}>
          <span className={classes.textHeavy}>
            {order.first_name} {order.last_name}
          </span>
          {order.shipping_type ? (
            <small className={classes.textSmall}>
              {order.shipping_type}
            </small>
          ) : (
            <small className={classes.textSmall}>
              No shipping type specified
            </small>
          )}

        </span>
        <div className={classes.lineItemDiv}>
          <CardHeader color="primary" icon>
            <CardIcon className={classes.orderCardHeader} color="primary">
              {order['line_items'].length} Line Item{order['line_items'].length > 1 && 's'}
            </CardIcon>
          </CardHeader>
          <CardBody className={classes.orderCardBody}>
            <LineItemCellCollection lineItems={order['line_items']} />
          </CardBody>
        </div>
      </div>
    </Card>
  );
}

OrderCard.propTypes = {
  classes: PropTypes.object,
  order: PropTypes.object
};

export default withStyles(style)(OrderCard);
