import {
  expiredPendingColor,
  expiringTodayColor,
  expiringTomorrowColor,
  expiringLaterColor,
  readyToProcessColor,
  purchasingCompleteColor,
  printTagsCompleteColor,
  refundRequiredColor,
  refundCompleteColor,
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  whiteColor
} from "assets/jss/material-dashboard-pro-react.jsx";

const badgeStyle = {
  // Badge types
  badge: {
    borderRadius: "12px",
    padding: "5px 12px",
    textTransform: "uppercase",
    fontSize: "10px",
    fontWeight: "700",
    lineHeight: "1",
    color: whiteColor,
    textAlign: "center",
    verticalAlign: "baseline",
    display: "inline-block"
  },
  cornerBadge: {
    padding: "0",
    display: "inline-block",
    boxShadow: "0 0 3px 2px rgba(0,0,0,0.3) !important",
    height: "40px",
    left: "-27px",
    position: "relative",
    top: "-27px",
    width: "40px",
    WebkitTransform: "rotate(-45deg)"
  },
  cornerNoPaddingBadge: {
    padding: "0",
    display: "inline-block",
    boxShadow: "0 0 3px 2px rgba(0,0,0,0.3) !important",
    height: "40px",
    left: "-20px",
    position: "relative",
    top: "-20px",
    width: "40px",
    WebkitTransform: "rotate(-45deg)"
  },

  primary: {
    backgroundColor: primaryColor[0]
  },
  warning: {
    backgroundColor: warningColor[0]
  },
  danger: {
    backgroundColor: dangerColor[0]
  },
  success: {
    backgroundColor: successColor[0]
  },
  info: {
    backgroundColor: infoColor[0]
  },
  rose: {
    backgroundColor: roseColor[0]
  },
  gray: {
    backgroundColor: grayColor[0]
  },
  expiredPendingGradient: {
    backgroundColor: expiredPendingColor,
    boxShadow: "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(153, 153, 153, 0.4)"
  },
  expiringTodayGradient: {
    backgroundColor: expiringTodayColor,
    boxShadow: "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(153, 153, 153, 0.4)"
  },
  expiringTomorrowGradient: {
    backgroundColor: expiringTomorrowColor,
    boxShadow: "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(153, 153, 153, 0.4)"
  },
  expiringLaterGradient: {
    backgroundColor: expiringLaterColor,
    boxShadow: "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(153, 153, 153, 0.4)"
  },
  readyToProcessGradient: {
    backgroundColor: readyToProcessColor,
    boxShadow: "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(153, 153, 153, 0.4)"
  },
  purchasingCompleteGradient: {
    backgroundColor: purchasingCompleteColor,
    boxShadow: "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(153, 153, 153, 0.4)"
  },
  printTagsCompleteGradient: {
    backgroundColor: printTagsCompleteColor,
    boxShadow: "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(153, 153, 153, 0.4)"
  },
  refundRequiredGradient: {
    backgroundColor: refundRequiredColor,
    boxShadow: "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(153, 153, 153, 0.4)"
  },
  refundCompleteGradient: {
    backgroundColor: refundCompleteColor,
    boxShadow: "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(153, 153, 153, 0.4)"
  },
  clear: {
    backgroundColor: "transparent",
    boxShadow: "none !important"
  }
};

export default badgeStyle;
