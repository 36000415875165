import React from "react";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";

import {
  grayColor
} from "assets/jss/material-dashboard-pro-react.jsx";

import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardText from "components/Card/CardText.jsx";
import LineItemCellCollection from "components/Cells/LineItemCellCollection.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

const style = theme => ({
  cardOrderTitle: {
    color: "white",
    fontWeight: "400",
    lineHeight: "1.1em",
    margin: "0"
  },
  cardHeader: {
    display: "inline-flex !important",
    justifyContent: "space-between",
    padding: "0"
  },
  dateTitle: {
    color: "black",
    flex: "2",
    fontWeight: "400",
    margin: "auto 0",
    paddingLeft: "20px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.1em",
    },
  },
  numberText: {
    alignItems: "center",
    boxShadow: "none",
    display: "flex",
    margin: "0",
    padding: "10px 15px",
  },
  etaCard: {
    marginTop: "40px",
    marginBottom: "0",
    padding: "0px 15px 0 0",
    display: "flex",
    flexDirection: "column",
  },
  etaButton: {
      margin: "8px 0"
  },
});

function EtaCard({ ...props }) {
  const { classes, product, onSetEta } = props;

  // Formatted dates
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  var updatedString = "Not set";
  if (product.eta) {
    updatedString = new Date(product.eta).toLocaleString('en-us', options);
  }

  return (
    <Card className={classes.etaCard}>
      <CardHeader className={classes.cardHeader} text>
        <CardText color="danger" className={classes.numberText}>
          <h4 className={classes.cardOrderTitle}>ETA</h4>
        </CardText>
        <h4 className={classes.dateTitle}>{updatedString}</h4>
        <Button
            color="danger"
            onClick={() => onSetEta()}
            className={classes.etaButton}
        >
            Set ETA
        </Button>
      </CardHeader>
    </Card>
  );
}

EtaCard.propTypes = {
  classes: PropTypes.object,
  product: PropTypes.object
};

export default withStyles(style)(EtaCard);
