import {
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  expiredPendingColor,
  expiringTodayColor,
  expiringTomorrowColor,
  expiringLaterColor,
  readyToProcessColor,
  purchasingCompleteColor,
  printTagsCompleteColor,
  refundRequiredColor,
  refundCompleteColor
} from "assets/jss/material-dashboard-pro-react.jsx";

const cardTextStyle = {
  expiredPendingCardHeader: {
    backgroundColor: expiredPendingColor
  },
  expiringTodayCardHeader: {
    backgroundColor: expiringTodayColor
  },
  expiringTomorrowCardHeader: {
    backgroundColor: expiringTomorrowColor
  },
  expiringLaterCardHeader: {
    backgroundColor: expiringLaterColor
  },
  readyToProcessCardHeader: {
    backgroundColor: readyToProcessColor
  },
  purchasingCompleteCardHeader: {
    backgroundColor: purchasingCompleteColor
  },
  printTagsCompleteCardHeader: {
    backgroundColor: printTagsCompleteColor
  },
  refundRequiredCardHeader: {
    backgroundColor: refundRequiredColor
  },
  refundCompleteCardHeader: {
    backgroundColor: refundCompleteColor
  },
  cardText: {
    float: "none",
    display: "inline-block",
    marginRight: "0",
    borderRadius: "3px",
    padding: "15px",
    marginTop: "-20px"
  },
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader
};

export default cardTextStyle;
