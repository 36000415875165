import React from "react";
import PropTypes from "prop-types";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import RefreshSpinner from "components/Spinners/RefreshSpinner.jsx";

import api from "state/api";
import productTablesStyle from "assets/jss/material-dashboard-pro-react/views/productTablesStyle.jsx";
import { withStyles } from '@material-ui/core/styles';
import withGracefulUnmount from 'react-graceful-unmount'

class DesignGalleryTables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cachePrefix: "",
      filteredProducts: [],
      isSearching: false,
      lastCursor: "",
      lastUpdated: "2000-01-01T12:00:00Z",
      products: [],
      productCount: 2,
      productsUpdated: 0,
      refreshing: false,
      searchString: "",
    };
    this.defaultSearch = '';
  }

  componentWillUnmount() {
    let state = this.state;
    delete state.products;
    delete state.filteredProducts;
    localStorage.setItem('DesignGalleryTables', JSON.stringify(state))
  }

  componentWillMount() {
    let rehydrate = JSON.parse(localStorage.getItem('DesignGalleryTables'));
    if (rehydrate) {
      rehydrate.productCount = 0;
      rehydrate.productsUpdated = 0;
      rehydrate.refreshing = 0;
      this.setState(rehydrate)
      this.defaultSearch = rehydrate.searchString;
    }
  }

  componentDidMount() {
    if (this.state.searchString.length > 0) {
      this.searchSku({productType: "design-gallery", sku: this.state.searchString});
    } else {
      this.searchSku({productType: "design-gallery"});
    }

    fetch('/api/meta/jnj_LastProductRefresh')
      .then(response => api.authCheck(response))
      .then(data => this.setState({ lastUpdated: data['value'] }));

    fetch('/api/meta/jnj_LastProductCursor')
      .then(response => api.authCheck(response))
      .then(data => this.setState({ lastCursor: data['value'] }));

    fetch('/api/products?count=true&productType=design-gallery')
      .then(response => api.authCheck(response))
      .then(data => this.setState({ productCount: data['count'] || 0 }));
  }

  continueRefresh(body) {
    var updateCount = this.state.productsUpdated;
    const params = Object.keys(body).map((key) => { return encodeURIComponent(key) + '=' + encodeURIComponent(body[key]);}).join('&');
    fetch('/api/products/jnj', {
      method: 'PUT',
      headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
      body: params
    })
    .then(response => api.authCheck(response))
    .then(data => {
      if (data['status']) {
        this.setState({ productsUpdated: updateCount + data['changeCount'] });
        if (data['hasNextPage']) {
          this.setState({ lastCursor: data['cursor'] });
          if (data['throttleRemaining'] > 1100) {
            this.continueRefresh({cursor: data['cursor']});
          } else {
            // Assuming a restore rate of 100, wait until we're back at 1100 throttle remaining
            var seconds = (1100 - data['throttleRemaining']) / 100 + 0.5
            setTimeout(() => this.continueRefresh({cursor: data['cursor']}), seconds * 1000);
          }
        } else {
          // Update the last updated metadata
          const dateString = new Date().toJSON();
          const body = {value: dateString};
          const params = Object.keys(body).map((key) => { return encodeURIComponent(key) + '=' + encodeURIComponent(body[key]);}).join('&');
          fetch('/api/meta/lastProductRefresh', {
            method: 'PUT',
            headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
            body: params
          })
          this.setState({ lastUpdated: dateString });
          this.setState({ refreshing: false });
        }
      } else {
        setTimeout(() => this.continueRefresh({cursor: data['cursor']}), 5000);
      }
    });
  }

  handleChange(event) {
    this.setState({searchString: event.target.value});

    // Reset the cache if the first four digits have changed at all
    var searchString = event.target.value;
    var prefix = this.state.cachePrefix;
    if (prefix.length > 0 && !searchString.startsWith(prefix)) {
      prefix = "";
      this.setState({cachePrefix: ""});
    }

    if (!this.state.isSearching && searchString.length > 3) {
      if (prefix.length === 0) {
        // Kick off a new search if at least 4 characters have been entered and no
        // data has yet been cached
        this.setState({isSearching: true});
        this.searchSku({productType: "design-gallery", sku: searchString});
        this.setState({cachePrefix: searchString.substring(0, 4)});
      } else {
        // Products have already been cached for this prefix; do a local filtering
        var filtered = this.state.products.filter(product => {
          if (product.variants.some(v => v.sku.includes(searchString))) {
            return product;
          }
          return null;
        });
        this.setState({filteredProducts: filtered});
      }
    }

    if (prefix.length < 4) {
      this.setState({filteredProducts: []});
    }

    if (searchString.length === 0) {
      this.searchSku({productType: "design-gallery"});
    }
  }

  handleRefresh() {
    this.setState({ refreshing: true });
    if (this.state.lastCursor && this.state.lastCursor !== "") {
      this.continueRefresh({cursor: this.state.lastCursor});
    } else {
      this.continueRefresh({lastUpdate: this.state.lastUpdated});
    }
  }

  searchSku(body) {
    const params = Object.keys(body).map((key) => { return encodeURIComponent(key) + '=' + encodeURIComponent(body[key]);}).join('&');
    fetch('/api/products?' + params, {
      method: 'GET',
      headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}
    })
    .then(response => api.authCheck(response))
    .then(data => {
      if (data['status']) {
        var products = data['data'];
        this.setState({products: products});
        this.setState({filteredProducts: products});
        this.setState({isSearching: false});
      }
    });
  }

  renderProducts() {
    const { classes } = this.props;
    return this.state.filteredProducts.map(product => {
      const collections = product['collections'];
      const variant = product.variants[0];
      return ([
        <GridItem xs={4}>
          <Card className={classes.designCard}>
            <span>
              <span className={classes.tdName}>
                {product['title']}
              </span>
              {collections && collections.length > 0 ? (
                <small className={classes.designNameSmall}>
                  Available to {collections}
                </small>
              ) : (
                <small className={classes.designNameSmall}>
                  Not available to any collections
                </small>
              )}
            </span>
            <div className={classes.variantDiv}>
              {variant.src ? (
                <img src={variant.src.replace(".jpg", "_medium.jpg")} alt='aProduct' className={classes.img} />
              ) : (
                <img src={variant.default_image.replace(".jpg", "_medium.jpg")} alt='zProduct' className={classes.img} />
              )}
            </div>
          </Card>
        </GridItem>
      ]);
    })
  }

  render() {
    const { classes } = this.props;
    const productCount = this.state.productCount;
    const searchString = this.state.searchString;
    return (
      <GridContainer className={classes.productGridContainer} justify="center">
        <RefreshSpinner
          onClick={() => this.handleRefresh()}
          objectName='products'
          refreshing={this.state.refreshing}
          updateCount={this.state.productsUpdated}
          updatedDate={this.state.lastUpdated}
        />
        <GridItem xs={12} className={classes.searchInputContainer}>
          <CustomInput
            id="sku"
            inputProps={{
              defaultValue: this.defaultSearch,
              inputProps: {className: classes.largeText},
              onChange: (e) => this.handleChange(e),
              placeholder: "Search SKU"
            }}
            formControlProps={{
              fullWidth: true
            }} />
        </GridItem>
        <GridItem xs={12} className={classes.productGridItem}>
          <Card className={classes.productsCard}>
            <CardHeader className={classes.cardIconTitle}>
              {productCount > 0 && searchString.length === 0 ? (
                <span>Displaying 20 of {productCount}</span>
              ) : null}

            </CardHeader>
            <GridContainer justify="center">
              {this.renderProducts()}
            </GridContainer>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

DesignGalleryTables.propTypes = {
  classes: PropTypes.object
};

export default withStyles(productTablesStyle)(withGracefulUnmount(DesignGalleryTables));
