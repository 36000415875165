import {
  blackColor,
  hexToRgb,
  primaryColor
} from "assets/jss/material-dashboard-pro-react.jsx";

const tableStyle = theme => ({
  actionButton: {
    backgroundColor: '#00acc1',
    "&:hover": {
      backgroundColor: '#26c6da',
    },
    "&:focus": {
      backgroundColor: '#26c6da',
    }
  },
  cardBodyProducts: {
    paddingLeft: "16px",
    "& tr td": {
      borderWidth: "0"
    }
  },
  center: {
    textAlign: "center"
  },
  checked: {
    color: primaryColor[0] + "!important"
  },
  checkRoot: {
    padding: "14px",
    "&:hover": {
      backgroundColor: "unset"
    }
  },
  checkedIcon: {
    width: "20px",
    height: "20px",
    border: "1px solid rgba(" + hexToRgb(blackColor) + ", .54)",
    borderRadius: "3px"
  },
  dateTime: {
    "& .rdtPicker": {
      marginLeft: "320px"
    }
  },
  dateTimeInput: {
    color: "#f44336",
    fontSize: "1.3em",
    textAlign: "center",
  },
  dialogDangerHeader: {
    color: "#f44336",
    fontWeight: "700",
    marginBottom: "15px",
    marginTop: "0"
  },
  dialogHeader: {
    marginBottom: "15px",
    marginTop: "0"
  },
  editableText: {
    color: primaryColor[0],
    cursor: "pointer"
  },
  exportButton: {
    color: "#fff",
    fontSize: "1.2em",
    fontWeight: "600",
    "&:hover": {
      color: "#fff",
    }
  },
  filterHeader: {
    color: "#777",
    fontSize: "1.1em",
    fontWeight: "600",
    textTransform: "uppercase"
  },
  fullWidth: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },
  legend: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  legendInner: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    "& span": {
      margin: "2px"
    }
  },
  marginTop: {
    marginTop: "16px"
  },
  right: {
    textAlign: "right"
  },
  uncheckedIcon: {
    width: "0px",
    height: "0px",
    padding: "9px",
    border: "1px solid rgba(" + hexToRgb(blackColor) + ", .54)",
    borderRadius: "3px"
  },
});

export default tableStyle;
