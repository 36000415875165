import Cookies from 'js-cookie';
import { history } from "index.js";

const api = {
  login(params) {
		return post('/api/login', params)
			.then(res => res.text())
			.then(textData => {
				if (textData[0] !== "{") {
          throw new Error("Invalid password"); // Check if password is valid
        }

				return JSON.parse(textData);
			})
	},

  register(params) {
		Cookies.remove('PHPSESSID');

		return post('/api/register', params)
			.then(res => res.json())
	},

  authCheck(response) {
    if( response.status === 403) {
      this.logout()
        .then(() => {
          history.push('/auth/login');
        })
    } else {
      return response.json();
    }
  },

	// TODO: hook up to backend's logout
  logout() {
		// Remove session cookie
		Cookies.remove('PHPSESSID');
		return Promise.resolve()
  }
}

function post(url, params = {}) {
	return fetch(url, {
		method: 'POST',
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({ ...params })
	})
		.catch(error => console.error(error))
}

export default api;
