import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

// state + auth
import { AuthenticationProvider } from 'state/AuthenticationProvider.js'
import { initialState, reducer } from 'state'

import AuthLayout from "layouts/Auth.jsx";
import RtlLayout from "layouts/RTL.jsx";
import AdminLayout from "layouts/Admin.jsx";

import "assets/scss/material-dashboard-pro-react.scss?v=1.7.0";

export const history = createBrowserHistory();

ReactDOM.render(
  <AuthenticationProvider initialState={initialState} reducer={reducer}>
    <Router history={history}>
      <Switch>
        <Route path="/rtl" component={RtlLayout} />
        <Route path="/auth" component={AuthLayout} />
        <Route path="/admin" component={AdminLayout} />
        <Route path="/reports" component={AdminLayout} />
        <Route path="/products" component={AdminLayout} />
        <Route path="/fulfillment" component={AdminLayout} />
        <Redirect from="/api" to="/services/" />
        <Redirect from="/" to="/admin/dashboard" />
      </Switch>
    </Router>
  </AuthenticationProvider>,
  document.getElementById("root")
);