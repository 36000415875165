const productCellStyle = theme => ({
  countOrdered: {
    fontWeight: "600",
    float: "right"
  },
  imgCell: {
    paddingLeft: "0",
    width: "60px",

    [theme.breakpoints.down("xs")]: {
      paddingTop: "3px"
    },
  },
  imgContainer: {
    maxHeight: "50px",
    marginLeft: "0px",
    marginRight: "16px",
    minWidth: "50px",
    overflow: "hidden",
    display: "inline-block",

    [theme.breakpoints.down("xs")]: {
      marginLeft: "0px",
      marginRight: "0px",
      maxHeight: "fit-content",
    },
  },
  left: {
    textAlign: "left",
    paddingLeft: "0"
  },
  noOverflow: {
    "& div": {
      overflowX: "unset"
    }
  },
  img: {
    width: "100%",
    height: "auto",
    objectFit: "contain",
    verticalAlign: "middle",
    border: "0",
    maxWidth: "80px",
    maxHeight: "60px",

    [theme.breakpoints.down("xs")]: {
      maxHeight: "fit-content",
    },
  },
  right: {
    textAlign: "right"
  },
  variantContainer: {
    "& span": {
      display: "block"
    },


    [theme.breakpoints.down("xs")]: {
      paddingLeft: "16px"
    }
  },
  widthFixed100: {
    paddingLeft: "0",
    width: "100px"
  },
  widthFixed50: {
    paddingLeft: "0",
    width: "50px"
  },
  widthFixed75: {
    paddingRight: "0px !important",
    textAlign: "right",
    width: "75px"
  },
  widthFixed75Bold: {
    fontWeight: "600",
    paddingRight: "0 !important",
    textAlign: "right",
    width: "75px"
  }
});

export default productCellStyle;
