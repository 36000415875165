import React from "react";
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";

// @material-ui/core components
import Moment from 'react-moment'
import ReactTable from "react-table";

import api from "state/api";
import tableStyle from "assets/jss/material-dashboard-pro-react/tableStyle.jsx";
import { withStyles } from '@material-ui/core/styles';
import withGracefulUnmount from 'react-graceful-unmount';

// core components
import CardText from "components/Card/CardText.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import RefreshSpinner from "components/Spinners/RefreshSpinner.jsx";

const columns = [
  {
    Header: "Customer",
    accessor: "name",
  },
  {
    Header: "Created",
    accessor: "created_at",
    filterable: false,
    Cell: ({ row }) => (
      <span>
        {row._original.created_at &&
          <Moment format="MM/DD/YY h:mm a">{row._original.created_at}</Moment>
        }
      </span>
    )
  },
  {
    Header: "Email",
    accessor: "email"
  },
  {
    Header: "Phone",
    accessor: "phone"
  },
  {
    Header: "Orgs",
    accessor: "collections",
  },
  {
    Header: "Tags",
    accessor: "tags",
    Cell: ({ row }) => (
      <span>
        {row._original.tags.replace(/,/g, ', ')}
      </span>
    )
  },
  {
    Header: "Orders",
    accessor: "orders_count",
    sortMethod: (a, b, desc) => {
      a = (a === 0) ? (desc ? -Infinity : Infinity) : a;
      b = (b === 0) ? (desc ? -Infinity : Infinity) : b;
      if (parseInt(a) > parseInt(b)) { return 1; }
      if (parseInt(a) < parseInt(b)) { return -1; }
      return 0;
    },
    maxWidth: 100
  },
  {
    Header: "Spent",
    accessor: "total_spent",
    filterable: false,
    Cell: ({ row }) => (
      `$${row.total_spent}`
    ),
    sortMethod: (a, b, desc) => {
      a = (a === 0) ? (desc ? -Infinity : Infinity) : a;
      b = (b === 0) ? (desc ? -Infinity : Infinity) : b;
      if (parseFloat(a) > parseFloat(b)) { return 1; }
      if (parseFloat(a) < parseFloat(b)) { return -1; }
      return 0;
    },
    maxWidth: 80
  },
];

class CustomerTables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadData: [],
      filteredData: [],
      filterIndex: 0,
      lastCursor: "",
      lastUpdated: "2000-01-01T12:00:00Z",
      customers: [],
      customersUpdated: 0,
      loading: false,
      refreshing: false,
      status: "",
      updating: false,

      sorted: [{
          id: "created_at",
          desc: true
      }],
      page: 0,
      pageSize: 20,
      expanded: {},
      resized: [],
      filtered: []
    };

    this.dataTable = React.createRef();
  }

  componentWillUnmount() {
    let state = this.state;
    delete state.customers;
    delete state.downloadData;
    delete state.filteredData;
    state.sorted = this.dataTable.current.state.sorted;
    state.page = this.dataTable.current.state.page;
    state.pageSize = this.dataTable.current.state.pageSize;
    state.expanded = this.dataTable.current.state.expanded;
    state.resized = this.dataTable.current.state.resized;
    state.filtered = this.dataTable.current.state.filtered;
    localStorage.setItem('CustomerTables', JSON.stringify(state))
  }

  componentWillMount() {
    let rehydrate = JSON.parse(localStorage.getItem('CustomerTables'));
    if (rehydrate) {
      rehydrate.refreshing = false;
      rehydrate.customersUpdated = 0;
      this.setState(rehydrate)
    }
  }

  componentDidMount() {
    this.setState({ loading: true });

    fetch('/api/customers')
      .then(response => api.authCheck(response))
      .then(data => {
        this.setState({ customers: data['data'] });
        this.setState({ filteredData: data['data'] });
        this.setState({ loading: false });
      });

    fetch('/api/meta/lastCustomerRefresh')
      .then(response => api.authCheck(response))
      .then(data => this.setState({ lastUpdated: data['value'] }));

    fetch('/api/meta/lastCustomerCursor')
      .then(response => api.authCheck(response))
      .then(data => this.setState({ lastCursor: data['value'] }));
  }

  continueRefresh(body) {
    var updateCount = this.state.customersUpdated;
    const params = Object.keys(body).map((key) => { return encodeURIComponent(key) + '=' + encodeURIComponent(body[key]);}).join('&');
    fetch('/api/customers', {
      method: 'PUT',
      headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
      body: params
    })
    .then(response => api.authCheck(response))
    .then(data => {
      if (data['status']) {
        this.setState({ customersUpdated: updateCount + data['changeCount'] });
        if (data['hasNextPage']) {
          this.setState({ lastCursor: data['cursor'] });
          if (data['throttleRemaining'] > 1100) {
            this.continueRefresh({cursor: data['cursor']});
          } else {
            // Assuming a restore rate of 100, wait until we're back at 1000 throttle remaining
            var seconds = (1100 - data['throttleRemaining']) / 100 + 0.5
            setTimeout(() => this.continueRefresh({cursor: data['cursor']}), seconds * 1000);
          }
        } else {
          // Update the last updated metadata
          const dateString = new Date().toJSON();
          const body = {value: dateString};
          const params = Object.keys(body).map((key) => { return encodeURIComponent(key) + '=' + encodeURIComponent(body[key]);}).join('&');
          fetch('/api/meta/lastCustomerRefresh', {
            method: 'PUT',
            headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
            body: params
          })
          this.setState({ lastUpdated: dateString });
          this.setState({ refreshing: false });
        }
      } else {
        setTimeout(() => this.continueRefresh({cursor: data['cursor']}), 5000);
      }
    });
  }

  handleNavigationChange(index) {
    const statuses = ["All", "Admins"];
    if (index < statuses.length && index > 0) {
      const status = statuses[index];
      this.setState({ status: status}, function () {
        this.updateSearch();
      });
    } else {
      this.setState({ status: ""}, function () {
        this.updateSearch();
      });
    }

    this.setState({filterIndex: index});
  }

  handleRefresh() {
    this.setState({ refreshing: true });
    if (this.state.lastCursor && this.state.lastCursor !== "") {
      this.continueRefresh({cursor: this.state.lastCursor});
    } else {
      this.continueRefresh({lastUpdate: this.state.lastUpdated});
    }
  }

  updateSearch() {
    const status = this.state.status;
    if (status === 'Admins') {
      var admins = this.state.customers.filter(c => c.tags.includes('admin:'));
      this.setState({filteredData: admins});
    } else {
      this.setState({filteredData: this.state.customers});
    }
  }

  renderCard() {
    const { classes } = this.props;
    return (
      <Card>
        <CardBody className={classes.cardBodyProducts}>
          {this.renderCustomers()}
        </CardBody>
      </Card>
    );
  }

// TODO: Offload paging and filter logic onto the server
  renderCustomers() {
    return (
      <ReactTable
        ref={this.dataTable}
        data={this.state.filteredData}
        filterable
        defaultFilterMethod={(filter, row) => (row[filter.id] ? row[filter.id] : '').toLowerCase().includes(filter.value.toLowerCase())}
        columns={columns}
        loading={this.state.loading}
        showPaginationTop={false}
        showPaginationBottom={true}
        className="-striped -highlight"

        defaultSorted={this.state.sorted}
        defaultPage={this.state.page}
        defaultPageSize={this.state.pageSize}
        defaultExpanded={this.state.expanded}
        defaultResized={this.state.resized}
        defaultFiltered={this.state.filtered}
      />
    );
  }

  render() {
    const { classes } = this.props;
    const headers = ["Customer", "Created", "Email", "Phone", "Orgs", "Tags", "Orders", "Total Spent"];
    const dataNames = ["name", "created_at", "email", "phone", "collections", "tags", "orders_count", "total_spent"];
    return (
      <GridContainer>
        <RefreshSpinner
          onClick={() => this.handleRefresh()}
          objectName='customers'
          refreshing={this.state.refreshing}
          updateCount={this.state.customersUpdated}
          updatedDate={this.state.lastUpdated}
        />
        <GridItem xs={12}>
          <NavPills
            alignCenter
            color="warning"
            active={this.state.filterIndex}
            onChange={(e) => this.handleNavigationChange(e)}
            tabs={[
              {
                tabButton: "All",
              },
              {
                tabButton: "Admins",
              }
            ]}
          />
        </GridItem>
        <GridItem xs={12}>
          {this.renderCard()}
        </GridItem>
        <GridItem xs={6}>
          <CardText color="rose" className={classes.dateCardText}>
            <CSVLink
              data={this.state.downloadData}
              asyncOnClick={true}
              filename={"customers.csv"}
              headers={headers}
              onClick={(event, done) => {
                this.setState({
                  updating: true
                }, () => {
                  var currentRecords = this.dataTable.current.getResolvedState().sortedData;
                  var data_to_download = []
                  currentRecords.forEach(function (item, index) {
                    var fi = {};
                    dataNames.forEach(function (name, i) {
                      var header = headers[i];
                      var value = item[name];
                      fi[header] = value ? value.replace('"', '”') : '';
                    });
                    data_to_download.push(fi);
                  });
                  this.setState({
                    downloadData: data_to_download
                  }, () => {
                    done();
                  })
                })
              }}
              className={classes.exportButton}>
              Export CSV
            </CSVLink>
          </CardText>
        </GridItem>
      </GridContainer>
    );
  }
}

CustomerTables.propTypes = {
  classes: PropTypes.object
};

export default withStyles(tableStyle)(withGracefulUnmount(CustomerTables));
