import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Block from "@material-ui/icons/Block";

// @material-ui/icons
import Email from "@material-ui/icons/Email";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";

// state
import { AuthenticationContext } from 'state/AuthenticationProvider.js';
import api from "state/api";
import { history } from "index.js";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

class LoginPage extends React.Component {
  static contextType = AuthenticationContext;

  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      username: '',
      email: '',
      password: '',
      failedLogin: false
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  updateValue = (value, field) => {
    this.setState({
      [field]: value,
    })
  }
  handleLogin = () => {
    const { username, password } = this.state;
    if (!username || !password) return

    const dispatch = this.context[1];

    api.login({ username, password })
      .then(data => {
        // Update state and go to dashboard
        dispatch({ type: 'login', data });
        history.push('/admin/dashboard')
      })
      .catch((e) => {
        console.log("Login failed:", e);
        
        this.setState({ failedLogin: true });
        setTimeout(
          function() {
            this.setState({ failedLogin: false });
          }.bind(this),
          3000
        );
      })
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Snackbar
          place="tc"
          color="rose"
          icon={Block}
          message="The username or password you entered is incorrect."
          open={this.state.failedLogin}
        />
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form>
              <Card login className={classes[this.state.cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="rose"
                >
                  <h4 className={classes.cardTitle}>Log in</h4>
                </CardHeader>
                <CardBody>
                  {/* Username */}
                  <CustomInput
                    emitValue={val => this.updateValue(val, 'username')}
                    labelText="Email"
                    id="firstname"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      )
                    }}
                  />
                  {/* Password */}
                  <CustomInput
                    emitValue={val => this.updateValue(val, 'password')}
                    labelText="Password"
                    id="password"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ),
                      type: "password",
                      autoComplete: "off"
                    }}
                  />
                </CardBody>
                {/* Log in */}
                <CardFooter className={classes.justifyContentCenter}>
                  <Button color="rose" simple size="lg" block onClick={this.handleLogin}>
                    Let{"'"}s Go
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(loginPageStyle)(LoginPage);
