import {
  grayColor
} from "assets/jss/material-dashboard-pro-react.jsx";

const productCardStyle = theme => ({
  cardBodyVariants: {
    padding: "0",
    "& tr td": {
      borderWidth: "1px"
    },

    [theme.breakpoints.down("xs")]: {
      borderTop: "none",
      paddingTop: "10px",
      "& tr:first-child td": {
        border: "none"
      }
    },
  },
  cardHeaderVariant: {
    fontSize: "1.0em",
    fontWeight: "500",
    marginRight: "-18px !important",
    marginTop: "-50px !important",
    padding: "5px 10px !important",
    textTransform: "uppercase",

    [theme.breakpoints.down("xs")]: {
      float: "left !important",
      marginBottom: "4px",
      marginLeft: "-14px",
      marginTop: "0px !important",
    },
  },
  cardOrderCount: {
    color: "white",
    fontWeight: "500",
    lineHeight: "1.1em",
    margin: "0"
  },
  cardOrderSubtitle: {
    color: "white",
    fontSize: "0.7em",
    fontWeight: "300",
  },
  cardOrderTitle: {
    color: "white",
    lineHeight: "1.1em",
    margin: "0"
  },
  cardHeader: {
    alignItems: "center",
    display: "inline-flex !important",
    justifyContent: "space-between",
    marginTop: "-40px",
    paddingLeft: "0px",
    paddingRight: "0px",

    [theme.breakpoints.down("xs")]: {
      marginLeft: "-20px",
      marginRight: "-20px",
      padding: "0"
    },
  },
  contentDiv: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  dateCardButton: {
    borderTop: "1px solid #ffffff55",
    paddingLeft: "0",
    paddingRight: "0",
    width: "100%"
  },
  dateCardText: {
    marginLeft: "8px",
    marginTop: "0",
    paddingBottom: "0",
    paddingTop: "0",
    width: "150px",
    [theme.breakpoints.down("xs")]: {
      width: "100px"
    },
  },
  dateHeaderContainer: {
    marginLeft: "16px",
    marginTop: "30px",
    alignItems: "flex-start",
    display: "inline-flex",
    flexWrap: "wrap"
  },
  dateSubtitle: {
    color: "white",
    fontSize: "0.9em",
    marginTop: "0",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8em",
    },
  },
  dateTitle: {
    color: "white",
    fontWeight: "400",
    marginBottom: "0",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.1em",
    },
  },
  flexBox: {
    display: "flex"
  },
  hideOnMobile: {
    [theme.breakpoints.down("xs")]: {
      display: "none !important"
    },
  },
  infoSpan: {
    paddingTop: "14px !important",
    verticalAlign: "top",
  },
  notificationButton: {
    borderTop: "1px solid #ffffff55",
    marginBottom: "0",
    paddingBottom: "10px",
    paddingTop: "10px",
  },
  notificationCardText: {
    marginTop: "0",
    paddingBottom: "0",
    paddingTop: "0",
    [theme.breakpoints.down("xs")]: {
      marginTop: "8px"
    },
  },
  notificationFooter: {
    marginLeft: "0px",
    marginRight: "0px",

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column"
    },
  },
  notificationSubtitle: {
    color: "white",
    fontSize: "0.9em",
    marginTop: "0"
  },
  notificationTitle: {
    color: "white",
    fontWeight: "400",
    marginBottom: "0"
  },
  productCard: {
    margin: "40px auto 0",
    maxWidth: "1000px",
    padding: "0px 15px",
    display: "flex",
    flexDirection: "column",
  },
  productContainer: {
    background: "#00000099",
    height: "100%",
    top: "0",
    left: "0",
    overflowY: "scroll",
    paddingBottom: "24px",
    position: "fixed",
    width: "100%",
    zIndex: "1200",
  },
  productInnerContainer: {
    margin: "0 auto",
    maxWidth: "1000px"
  },
  square: {
    alignItems: "center",
    display: "inline-flex",
    flexDirection: "column",
    height: "100px",
    justifyContent: "bottom",
    marginTop: "0",
    width: "100px",
    verticalAlign: "middle"
  },
  textHeavy: {
    display: "block",
    fontWeight: "400",
    fontSize: "1.5em",
    minWidth: "200px",
    paddingBottom: "4px",
  },
  textSmall: {
    color: grayColor[1],
    display: "block",
    fontSize: "0.8em",
    fontWeight: "300",
    paddingBottom: "12px"
  },
  timeSubtitle: {
    color: "white",
    fontSize: "0.9em",
    marginTop: "-7px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8em",
    },
  },
  warning: {
    color: "#f44336",
    textAlign: "center"
  },
  variantDiv: {
    width: "100%",
  }
});

export default productCardStyle;
