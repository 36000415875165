import React from "react";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import UpInHouseCellCollection from "components/Cells/UpInHouseCellCollection.jsx";

import productCardStyle from "assets/jss/material-dashboard-pro-react/views/productCardStyle.jsx";
import { statusTitlesLong } from "variables/jnj.jsx";

function ProductCard({ ...props }) {
  const { classes, product, permutation, onFulfillmentStatusChange, onPrintTagStatusChange, onPurchasingStatusChange } = props;
  const perm = product.permutations[permutation];
  const orderCount = perm.count;
  const lineItemIds = perm.line_items.map(i => i.id);
  const statuses = permutation.split("|");
  const fulfillmentStatus = statuses[0];
  const printTagStatus = 'Print Tags ' + statuses[1];
  const purchaseStatus = 'Purchasing ' + statuses[2];

  // Total up the variant orders
  product.variants.forEach(variant => {
    const quantities = perm.line_items.map(i => i.sku === variant.sku ? parseInt(i.quantity) : 0);
    variant.count = quantities.reduce((total, amount) => total + amount);
  });

  return (
    <div>
      <Card className={classes.productCard}>
        <CardHeader className={classes.cardHeader} text>
          <CardText color={perm.color} className={classes.square}>
            <h2 className={classes.cardOrderCount}>{orderCount}</h2>
            <h4 className={classes.cardOrderTitle}>units</h4>
            <small className={classes.cardOrderSubtitle}>
              {perm.line_items.length} line items
            </small>
          </CardText>
          <div className={classes.dateHeaderContainer}>
            {orderCount > 0 &&
              <div>
                  <div className={classes.flexBox}>
                    <CustomDropdown
                      buttonText={purchaseStatus}
                      buttonProps={{
                        style: { margin: "3px" },
                        color: "info"
                      }}
                      dropdownList={["Purchasing Pending", "Purchasing Ready", "Purchasing Complete"]}
                      onClick={(e) => onPurchasingStatusChange(e.replace('Purchasing ', ''), lineItemIds)}
                    />
                    <CustomDropdown
                      buttonText={printTagStatus}
                      buttonProps={{
                        style: { margin: "3px" },
                        color: "info"
                      }}
                      dropdownList={["Print Tags Pending", "Print Tags Ready", "Print Tags Complete"]}
                      onClick={(e) => onPrintTagStatusChange(e.replace('Print Tags ', ''), lineItemIds)}
                    />
                  </div>
                </div>
              }
              <CustomDropdown
                buttonText={fulfillmentStatus}
                buttonProps={{
                  style: { margin: "3px" },
                  color: "info"
                }}
                dropdownList={statusTitlesLong}
                onClick={(e) => onFulfillmentStatusChange(e, lineItemIds)}
              />
            </div>
        </CardHeader>
        <div className={classes.contentDiv}>
          <div className={classes.variantDiv}>
            <CardBody className={classes.cardBodyVariants}>
              <UpInHouseCellCollection product={product} />
            </CardBody>
          </div>
        </div>
      </Card>
    </div>
  );
}

ProductCard.propTypes = {
  classes: PropTypes.object,
  onFulfillmentStatusChange: PropTypes.func,
  onPrintTagStatusChange: PropTypes.func,
  onPurchasingStatusChange: PropTypes.func,
  permutation: PropTypes.object,
  product: PropTypes.object
};

export default withStyles(productCardStyle)(ProductCard);
