import React from "react";
import PropTypes from "prop-types";

import { CSVLink } from "react-csv";
import ReactTable from "react-table";

// core components
import CardText from "components/Card/CardText.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import api from "state/api";
import combineStyles from 'assets/jss/material-dashboard-pro-react/combineStyles.jsx'
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import tableStyle from "assets/jss/material-dashboard-pro-react/tableStyle.jsx";
import { withStyles } from '@material-ui/core/styles';
import withGracefulUnmount from 'react-graceful-unmount'

class ShippingReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bulkActive: 0,
      filteredData: [],
      loading: true,
      orders: [],
      refreshing: false,
      updating: false,

      sorted: [{
          id: "created_at",
          desc: true
      }],
      page: 0,
      pageSize: 20,
      expanded: {},
      resized: [],
      filtered: [],
    };

    this.dataTable = React.createRef();
    this.editMode = "";
    this.lineItemCount = 0;
    this.status = "";
  }

  componentWillUnmount() {
    let state = this.state;
    delete state.orders;
    delete state.filteredData;
    state.sorted = this.dataTable.current.state.sorted;
    state.page = this.dataTable.current.state.page;
    state.pageSize = this.dataTable.current.state.pageSize;
    state.expanded = this.dataTable.current.state.expanded;
    state.resized = this.dataTable.current.state.resized;
    state.filtered = this.dataTable.current.state.filtered;
    localStorage.setItem('ShippingReport', JSON.stringify(state))
  }

  componentWillMount() {
    let rehydrate = JSON.parse(localStorage.getItem('ShippingReport'));
    if (rehydrate) {
      rehydrate.refreshing = false;
      rehydrate.updating = false;
      this.status = rehydrate.filterStatus;
      if (this.status === "All") {
        this.status = "";
      }
      this.setState(rehydrate)
    }
  }

  componentDidMount() {
    this.updateSearch();
  }

  updateSearch() {
    this.setState({ loading: true });

    let filters = {reportType: 'shipping'};
    if (this.dataTable.current) {
      let state = this.dataTable.current.state;
      filters = {page_size: state.pageSize, page: state.page, sorted: JSON.stringify(state.sorted), filtered: JSON.stringify(state.filtered), total_page_count: true, group_by: this.state.filterGroup, reportType: 'shipping'};
    }

    var body = filters ? filters : {};
    const params = Object.keys(body).map((key) => { return encodeURIComponent(key) + '=' + encodeURIComponent(body[key]);}).join('&');
    const url = params.length > 0 ? '/api/products?' : '/api/products'

    fetch(url + params, {
      method: 'GET',
      headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}
    })
    .then(response => api.authCheck(response))
    .then(data => {
      this.setState({ loading: false });

      if (data['status']) {
        let orders = data['data'];
        this.setState({pages: parseInt(data['pages'])});
        this.setState({orders: orders});
        this.lineItemCount = parseInt(data['count']);
      }
    });
  }

  renderCard() {
    const { classes } = this.props;
    return (
      <Card>
        <CardBody className={classes.cardBodyProducts}>
          {this.renderOrders()}
        </CardBody>
      </Card>
    );
  }

// TODO: Offload paging and filter logic onto the server
  renderOrders() {
    const { classes } = this.props;
    return (
      <ReactTable
        ref={this.dataTable}
        data={this.state.orders}
        pages={this.state.pages}
        defaultFilterMethod={(filter, row) => (row[filter.id] ? row[filter.id] : '').toLowerCase().includes(filter.value.toLowerCase())}
        filterable
        columns={[
          {
            Header: "SKU",
            accessor: "sku",
            Cell: ({ row }) => (
              `${row.sku.substr(0, row.sku.lastIndexOf("-"))}`
            ),
            width: 140
          },
          {
            Header: "Product",
            accessor: "title",
          },
          {
            Header: "Qty",
            headerStyle: {
              paddingRight: "10px",
              textAlign: "right",
            },
            accessor: "quantity",
            maxWidth: 60,
            filterable: false,
            className: classes.right,
            sortMethod: (a, b, desc) => {
              a = (a === 0) ? (desc ? -Infinity : Infinity) : a;
              b = (b === 0) ? (desc ? -Infinity : Infinity) : b;
              if (parseInt(a) > parseInt(b)) { return 1; }
              if (parseInt(a) < parseInt(b)) { return -1; }
              return 0;
            },
          },
          {
            Header: "Shipping Paid",
            headerStyle: {
              paddingRight: "10px",
              textAlign: "right",
            },
            accessor: "shipping_paid",
            filterable: false,
            className: classes.right,
            Cell: ({ row }) => (
              `$${row.shipping_paid}`
            ),
            sortMethod: (a, b, desc) => {
              a = (a === 0) ? (desc ? -Infinity : Infinity) : a;
              b = (b === 0) ? (desc ? -Infinity : Infinity) : b;
              if (parseFloat(a) > parseFloat(b)) { return 1; }
              if (parseFloat(a) < parseFloat(b)) { return -1; }
              return 0;
            },
          },
          {
            Header: "Shipping Costs",
            headerStyle: {
              paddingRight: "10px",
              textAlign: "right",
            },
            accessor: "shipping_cost",
            filterable: false,
            className: classes.right,
            Cell: ({ row }) => (
              `$${row.shipping_cost}`
            ),
            sortMethod: (a, b, desc) => {
              a = (a === 0) ? (desc ? -Infinity : Infinity) : a;
              b = (b === 0) ? (desc ? -Infinity : Infinity) : b;
              if (parseFloat(a) > parseFloat(b)) { return 1; }
              if (parseFloat(a) < parseFloat(b)) { return -1; }
              return 0;
            },
          },
        ]}
        loading={this.state.loading}
        showPaginationTop={false}
        showPaginationBottom={true}
        className="-striped -highlight"
        defaultSorted={this.state.sorted}
        defaultPage={this.state.page}
        defaultPageSize={this.state.pageSize}
        defaultExpanded={this.state.expanded}
        defaultResized={this.state.resized}
        defaultFiltered={this.state.filtered}
      />
    );
  }

  render() {
    const { classes } = this.props;
    const headers = ["Product", "SKU", "Shipping Paid", "Shipping Costs", "Qty"];
    const dataNames = ["name", "sku", "shipping_paid", "shipping_costs", "quantity"];
    return (
      <GridContainer justify="center">
        <GridItem xs={0} md={8}/>
        <GridItem xs={12}>
          {this.renderCard()}
        </GridItem>
        <GridItem xs={12}>
          <CardText color="rose" className={classes.dateCardText}>
            <CSVLink data={this.state.filteredData}
              asyncOnClick={true}
              filename={"shipping.csv"}
              headers={headers}
              onClick={(event, done) => {
                this.setState({
                  updating: true
                }, () => {
                  var currentRecords = this.dataTable.current.getResolvedState().sortedData;
                  var data_to_download = []
                  currentRecords.forEach(function (item, index) {
                    var fi = {};
                    dataNames.forEach(function (name, i) {
                      var header = headers[i];
                      var value = item[name];
                      fi[header] = value ? value.replace('"', '”') : '';
                    });
                    data_to_download.push(fi);
                  });
                  this.setState({
                    filteredData: data_to_download
                  }, () => {
                    done();
                  })
                })
              }}
              className={classes.exportButton}>
              Export CSV
            </CSVLink>
          </CardText>
        </GridItem>
      </GridContainer>
    );
  }
}

ShippingReport.propTypes = {
  classes: PropTypes.object
};

export default withStyles(combineStyles(tableStyle, modalStyle))(withGracefulUnmount(ShippingReport));
