import React from "react";
import PropTypes from "prop-types";

import { useTheme, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Table from "components/Table/Table.jsx";

import productCellStyle from "assets/jss/material-dashboard-pro-react/components/productCellStyle.jsx";

function renderVariants(variants, classes) {
  return variants.map(variant => {
    return ([
      <div className={classes.imgContainer}>
      {variant['src'] ? (
        <img src={variant['src'].replace(".jpg", "_small.jpg")} alt='aProduct' className={classes.img} />
      ) : (
        <img src={variant['default_image'].replace(".jpg", "_small.jpg")} alt='zProduct' className={classes.img} />
      )}
      </div>,
      <span>{variant['color']}</span>,
      <span>{variant['sku']}</span>,
    ]);
  })
}

function renderVariantsForSmallScreen(variants, classes) {

  return variants.map(variant => {
    return ([
      <div className={classes.imgContainer}>
        <img src={variant['src'].replace(".jpg", "_small.jpg")} alt='Product' className={classes.img} />
      </div>,
      <div className={classes.variantContainer}>
        <span>{variant['color']}</span>
        <span>{variant['sku']}</span>
      </div>
    ]);
  })
}

function CatalogProductCellCollection({ ...props }) {
  const { classes, variants } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <div className={classes.noOverflow}>
    {matches ? (
      <Table
        tableHead={["", "COLOR", "SKU"]}
        tableData={renderVariants(variants, classes)}
        tableShopping
        customHeadCellClasses={[
          classes.widthFixed50,
          classes.left,
          classes.left,
        ]}
        customHeadClassesForCells={[0, 1, 2]}
        customCellClasses={[
          classes.imgCell,
          classes.widthFixed100,
        ]}
        customClassesForCells={[0, 1]}
      />
    ) : (
      <Table
        tableHead={[]}
        tableData={renderVariantsForSmallScreen(variants, classes)}
        tableShopping
        customCellClasses={[classes.imgCell]}
        customClassesForCells={[0]}
      />
    )}
    </div>
  );
}

CatalogProductCellCollection.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  variants: PropTypes.array.isRequired
};

export default withStyles(productCellStyle)(CatalogProductCellCollection);
