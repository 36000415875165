import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import ReactTable from "react-table";

import api from "state/api";
import tableStyle from "assets/jss/material-dashboard-pro-react/tableStyle.jsx";
import { withStyles } from '@material-ui/core/styles';
import withGracefulUnmount from 'react-graceful-unmount'

// core components
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import Checkbox from "@material-ui/core/Checkbox";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import LockOpen from "@material-ui/icons/LockOpen";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class UserTables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userNeedingPassword: "",
      userToDelete: "",
      newUserSelected: false,
      loading: false,
      users: [],

      sorted: [{
        id: "name",
        desc: false
      }],
      page: 0,
      pageSize: 20,
      expanded: {},
      resized: [],
      filtered: []
    };
    this.dataTable = React.createRef();
    this.emailRef = React.createRef();
    this.nameRef = React.createRef();
  }

  componentWillUnmount() {
    let state = this.state;
    delete state.users;
    state.sorted = this.dataTable.current.state.sorted;
    state.page = this.dataTable.current.state.page;
    state.pageSize = this.dataTable.current.state.pageSize;
    state.expanded = this.dataTable.current.state.expanded;
    state.resized = this.dataTable.current.state.resized;
    state.filtered = this.dataTable.current.state.filtered;
    localStorage.setItem('UserTables', JSON.stringify(state))
  }

  componentWillMount() {
    let rehydrate = JSON.parse(localStorage.getItem('UserTables'));
    if (rehydrate) {
      this.setState(rehydrate)
    }
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.refreshUsers();
  }

  handleTogglePermission(username, permission) {
    let user = this.state.users.filter(u => u.username === username)[0];
    if (user) {
      let users = this.state.users.filter(u => u.username !== username);
      let permissions = user.permissions ? user.permissions.split(',') : [];
      if (permissions.includes(permission)) {
        // Remove the permission
        user.permissions = permissions.filter(p => p !== permission).join(',');
      } else {
        // Add the permission
        permissions.push(permission);
        user.permissions = permissions.join(',');
      }
      users.push(user);
      this.setState({ users: users });

      const body = { username: username, permissions: user.permissions };
      const params = Object.keys(body).map((key) => { return encodeURIComponent(key) + '=' + encodeURIComponent(body[key]);}).join('&');
      fetch('/api/user', {
        method: 'PUT',
        headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
        body: params
      })
    }
  }

  handlePasswordChange(email, password) {
    if (email.length > 0 && email.includes("@") && password.length > 0) {
      this.setState({ userNeedingPassword: false })

      const body = { username: email, password: password };
      const params = Object.keys(body).map((key) => { return encodeURIComponent(key) + '=' + encodeURIComponent(body[key]);}).join('&');
      fetch('/api/user', {
        method: 'PUT',
        headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
        body: params
      }).then(res => this.refreshUsers())
    }
  }

  handleDeleteUser(email) {
    this.setState({ userToDelete: "" });

    const body = { username: email };
    const params = Object.keys(body).map((key) => { return encodeURIComponent(key) + '=' + encodeURIComponent(body[key]);}).join('&');
    fetch('/api/user', {
      method: 'DELETE',
      headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
      body: params
    }).then(res => this.refreshUsers())
  }

  handleSaveNewUser(name, email, password) {
    if (name.length > 0 && email.length > 0 && email.includes("@") && password.length > 0) {
      this.setState({ newUserSelected: false })

      const body = { name: name, username: email, password: password };
      const params = Object.keys(body).map((key) => { return encodeURIComponent(key) + '=' + encodeURIComponent(body[key]);}).join('&');
      fetch('/api/register', {
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
        body: params
      }).then(res => this.refreshUsers())
    }
  }

  refreshUsers() {
    fetch('/api/users')
      .then(response => api.authCheck(response))
      .then(data => {
        this.setState({ users: data['data'] });
        this.setState({ loading: false });
      });
  }

  renderAddUserModal() {
    const { classes } = this.props;
    let name = "";
    let email = "";
    let password = Math.random().toString(36).substr(2, 8);
    if (this.emailRef.current) {
      this.emailRef.current.value = "";
      this.nameRef.current.value = "";
    }

    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal
        }}
        open={this.state.newUserSelected}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.setState({ newUserSelected: false })}
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
      >
        <DialogContent
          id="notice-modal-slide-description"
          className={classes.modalBody}
        >
          <div className={classes.marginTop}>This password will not be displayed again. If a password is lost, an admin must generate a new one.</div>
          <CustomInput
            labelText="Name"
            emitValue={val => name = val}
            id="name"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              inputRef: this.nameRef,
              type: "name"
            }}
          />
          <CustomInput
            labelText="Email address"
            emitValue={val => email = val}
            id="email_adress"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              inputRef: this.emailRef,
              type: "email"
            }}
          />
          <div>Password: {password}</div>
        </DialogContent>
        <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter }>
          <Button
            onClick={() => this.handleSaveNewUser(name, email, password)}
            color="info"
            round>
            Create New User
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderChangeUserPasswordModal() {
    const { classes } = this.props;
    let password = Math.random().toString(36).substr(2, 8);

    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal
        }}
        open={this.state.userNeedingPassword}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.setState({ userNeedingPassword: "" })}
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
      >
        <DialogContent
          id="notice-modal-slide-description"
          className={classes.modalBody}
        >
          <div className={classes.marginTop}><b>Create a new password for {this.state.userNeedingPassword}?</b></div>
          <div>New password: {password}</div>
          <div className={classes.marginTop}>This password will not be displayed again. If a password is lost, an admin must generate a new one.</div>
        </DialogContent>
        <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter }>
          <Button
            onClick={() => this.handlePasswordChange(this.state.userNeedingPassword, password)}
            color="info"
            round>
            Set New Password
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderDeleteUserModal() {
    const { classes } = this.props;

    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal
        }}
        open={this.state.userToDelete}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.setState({ userToDelete: "" })}
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
      >
        <DialogContent
          id="notice-modal-slide-description"
          className={classes.modalBody}
        >
          <div className={classes.marginTop}>Are you sure you want to delete {this.state.userToDelete}? This cannot be undone.</div>
        </DialogContent>
        <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter }>
          <Button
            onClick={() => this.handleDeleteUser(this.state.userToDelete)}
            color="info"
            round>
            Delete User
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderCard() {
    const { classes } = this.props;
    return (
      <Card>
        <CardBody className={classes.cardBodyProducts}>
          {this.renderUsers()}
        </CardBody>
      </Card>
    );
  }

  renderUsers() {
    const { classes } = this.props;
    const columns = [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "username",
        minWidth: 130
      },
      {
        id: "admin",
        Header: "Admin",
        accessor: (row) => (
          row.permissions != null && row.permissions.includes('admin')
        ),
        filterable: false,
        Cell: ({ row }) => (
          <Checkbox
            checked={row._original.permissions != null && row._original.permissions.includes('admin')}
            onClick={() => this.handleTogglePermission(row._original.username, 'admin')}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot
            }}
          />
        ),
      },
      {
        id: "collections",
        Header: "Collections",
        accessor: (row) => (
          row.permissions != null && row.permissions.includes('collections')
        ),
        filterable: false,
        Cell: ({ row }) => (
          <Checkbox
            checked={row._original.permissions != null && row._original.permissions.includes('collections')}
            onClick={() => this.handleTogglePermission(row._original.username, 'collections')}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot
            }}
          />
        ),
      },
      {
        id: "customers",
        Header: "Customers",
        accessor: (row) => (
          row.permissions != null && row.permissions.includes('customers')
        ),
        filterable: false,
        Cell: ({ row }) => (
          <Checkbox
            checked={row._original.permissions != null && row._original.permissions.includes('customers')}
            onClick={() => this.handleTogglePermission(row._original.username, 'customers')}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot
            }}
          />
        ),
      },
      {
        id: "fulfillment",
        Header: "Fulfillment",
        accessor: (row) => (
          row.permissions != null && row.permissions.includes('fulfillment')
        ),
        filterable: false,
        Cell: ({ row }) => (
          <Checkbox
            checked={row._original.permissions != null && row._original.permissions.includes('fulfillment')}
            onClick={() => this.handleTogglePermission(row._original.username, 'fulfillment')}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot
            }}
          />
        ),
      },
      {
        id: "orders",
        Header: "Orders",
        accessor: (row) => (
          row.permissions != null && row.permissions.includes('orders')
        ),
        filterable: false,
        Cell: ({ row }) => (
          <Checkbox
            checked={row._original.permissions != null && row._original.permissions.includes('orders')}
            onClick={() => this.handleTogglePermission(row._original.username, 'orders')}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot
            }}
          />
        ),
      },
      {
        id: "products",
        Header: "Products",
        accessor: (row) => (
          row.permissions != null && row.permissions.includes('products')
        ),
        filterable: false,
        Cell: ({ row }) => (
          <Checkbox
            checked={row._original.permissions != null && row._original.permissions.includes('products')}
            onClick={() => this.handleTogglePermission(row._original.username, 'products')}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot
            }}
          />
        ),
      },
      {
        id: "up-in-house",
        Header: "Up In House",
        accessor: (row) => (
          row.permissions != null && row.permissions.includes('up-in-house')
        ),
        filterable: false,
        Cell: ({ row }) => (
          <Checkbox
            checked={row._original.permissions != null && row._original.permissions.includes('up-in-house')}
            onClick={() => this.handleTogglePermission(row._original.username, 'up-in-house')}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot
            }}
          />
        ),
      },
      {
        id: "end-of-day",
        Header: "End of Day",
        accessor: (row) => (
          row.permissions != null && row.permissions.includes('end-of-day')
        ),
        filterable: false,
        Cell: ({ row }) => (
          <Checkbox
            checked={row._original.permissions != null && row._original.permissions.includes('end-of-day')}
            onClick={() => this.handleTogglePermission(row._original.username, 'end-of-day')}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot
            }}
          />
        ),
      },
      {
        id: "purchasing",
        Header: "Purchasing",
        accessor: (row) => (
          row.permissions != null && row.permissions.includes('purchasing')
        ),
        filterable: false,
        Cell: ({ row }) => (
          <Checkbox
            checked={row._original.permissions != null && row._original.permissions.includes('purchasing')}
            onClick={() => this.handleTogglePermission(row._original.username, 'purchasing')}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot
            }}
          />
        ),
      },
      {
        id: "print-tags",
        Header: "Print Tags",
        accessor: (row) => (
          row.permissions != null && row.permissions.includes('print-tags')
        ),
        filterable: false,
        Cell: ({ row }) => (
          <Checkbox
            checked={row._original.permissions != null && row._original.permissions.includes('print-tags')}
            onClick={() => this.handleTogglePermission(row._original.username, 'print-tags')}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot
            }}
          />
        ),
      },
      {
        id: "actions",
        Header: "Actions",
        filterable: false,
        sortable: false,
        Cell: ({ row }) => (
          <div>
            <Button
              color="info"
              simple
              onClick={() => this.setState({userNeedingPassword: row._original.username})}
            >
              <LockOpen />
            </Button>
            <Button
              color="danger"
              simple
              onClick={() => this.setState({userToDelete: row._original.username})}
            >
              <Close />
            </Button>
          </div>
        ),
      },
    ];

    return (
      <ReactTable
        ref={this.dataTable}
        data={this.state.users}
        filterable
        defaultFilterMethod={(filter, row) => (row[filter.id] ? row[filter.id] : '').toLowerCase().includes(filter.value.toLowerCase())}
        columns={columns}
        loading={this.state.loading}
        showPaginationTop={false}
        showPaginationBottom={true}
        className="-striped -highlight"
        getTheadThProps={(state, rowInfo, column) => {
          return {
            style: {
              'font-size': '1.02em'
            }
          }
        }}

        defaultSorted={this.state.sorted}
        defaultPage={this.state.page}
        defaultPageSize={this.state.pageSize}
        defaultExpanded={this.state.expanded}
        defaultResized={this.state.resized}
        defaultFiltered={this.state.filtered}
      />
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <GridContainer>
        {this.renderAddUserModal()}
        {this.renderDeleteUserModal()}
        {this.renderChangeUserPasswordModal()}
        <GridItem xs={12} className={classes.right}>
          <Button
            onClick={() => this.setState({ newUserSelected: true })}
            color="info"
            round>
            Add User
          </Button>
        </GridItem>
        <GridItem xs={12}>
          {this.renderCard()}
        </GridItem>
      </GridContainer>
    );
  }
}

UserTables.propTypes = {
  classes: PropTypes.object
};

export default withStyles(tableStyle)(withGracefulUnmount(UserTables));
