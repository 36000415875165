/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import withGracefulUnmount from 'react-graceful-unmount'

// react component used to create a calendar with events on it
import BigCalendar from "react-big-calendar";
import Agenda from "react-big-calendar";
import moment from "moment";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Heading from "components/Heading/Heading.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import OrderCard from "components/Orders/OrderCard.jsx";

import api from "state/api";
import reportStyles from "assets/jss/material-dashboard-pro-react/views/reportStyles.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

const localizer = BigCalendar.momentLocalizer(moment);

const AgendaContainer = ({ classes }) => props => {
    return <ProductAgenda event={props} classes={classes} />;
}

const ProductAgenda = React.memo((props) => {
  const { classes } = props;
  return (
      <div class={classes.endOfDayAgendaItem}>
          <span><strong>{props.event.title}</strong></span><span>{props.event.event.sku}</span><span>{props.event.event.collection}</span><span>{props.event.event.shipping}</span>
      </div>
  );
})

class EndOfDayReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: "",
      filterIndex: 0,
      filteredOrders: [],
      length: 31,
      orders: [],
      selectedDate: new Date(),
      selectedOrder: null,
      selectedView: 'month',
    };
    this.textEntered = "";
  }

  componentWillUnmount() {
    let state = this.state;
    delete state.filteredOrders;
    delete state.orders;
    localStorage.setItem('EndOfDayReportState', JSON.stringify(state))
  }

  componentWillMount() {
    let rehydrate = JSON.parse(localStorage.getItem('EndOfDayReportState'));
    if (rehydrate) {
      rehydrate.selectedDate = new Date(rehydrate.selectedDate);
      this.setState(rehydrate)
    }
  }

  componentDidMount() {
    this.loadEventsForMonthOfDate(this.state.selectedDate);
  }

  onNavigate = (newDate) => {
    // Add a few days in case we jumped to the end of a month due to the length
    const date = moment(newDate).add(5, 'days');
    if (this.state.selectedView == "agenda") {
      const firstDay = moment(date).startOf('month').toDate();
      const length = moment(date).daysInMonth();
      this.setState({ selectedDate: firstDay });
      this.setState({ length: length });
    } else {
      this.setState({ selectedDate: newDate });
    }

    this.loadEventsForMonthOfDate(date);
  }

  // Make sure the list view is displaying by month
  onView = (view) => {
    if (view == "agenda") {
      const date = this.state.selectedDate;
      const firstDay = moment(date).startOf('month').toDate();
      const length = moment(date).daysInMonth();
      this.setState({ selectedDate: firstDay });
      this.setState({ length: length });
    }
    this.setState({ selectedView: view });
  }

  eventColors(event) {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor
    };
  }

  filterOrders(orders) {
    let forders = orders;

    if (this.state.filter !== '') {
      forders = orders.filter(o => o.shipping == this.state.filter)
    }

    if (this.textEntered.length == 0) {
      this.setState({filteredOrders: forders})
    } else {
      let search = this.textEntered.toLowerCase()
      let filtered = forders.filter(order => order.sku.toLowerCase().includes(search) || order.title.toLowerCase().includes(search))
      this.setState({filteredOrders: filtered})
    }
  }

  loadEventsForMonthOfDate(date) {
    const dateString = moment(date).format('YYYY-MM-DD');
    fetch(`/api/orders?reportType=end-of-day&date=${dateString}`)
      .then(response => api.authCheck(response))
      .then(data => {
        var orders = this.state.orders;
        data['data'].forEach(ord => {
          var color = 'expiring-tomorrow';
          if (ord.complete_line_items > ord.total_line_items) {
            color = 'expiring-today;'
          }

          var order = {title: ord.title, allDay: false, start: new Date(ord.latest_updated_at), sku: ord.sku, shipping: ord.shipping_type,
                       end: new Date(ord.latest_updated_at), color: color, id: ord.id, collection: ord.collection_name};
          orders = orders.filter(cachedOrder => cachedOrder.id != ord.id);
          orders.push(order);
        });
        this.setState({ orders: orders });
        this.filterOrders(orders);
       });
  }

  handleNavigationChange(index) {
    const statusTitles = ["All", "Group Delivery", "Group Pickup"];
    if (index < statusTitles.length && index > 0) {
      const status = statusTitles[index];
      this.setState({filter: status}, () => this.filterOrders(this.state.orders));
    } else {
      this.setState({filter: ""}, () => this.filterOrders(this.state.orders));
    }
    this.setState({filterIndex: index});
  }

  handleSearch(event) {
    const value = event.target.value;
    this.textEntered = value;
    setTimeout(() => {
      if (this.textEntered === value) {
        this.filterOrders(this.state.orders);
      }
    }, 500 );
    return;
  }

  render() {
    const { classes } = this.props;
    let components = {
      agenda: {
        event: AgendaContainer({ classes: classes })
      }
    }

    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12}>
            <div className={classes.center}>
              <div className={classes.innerSearchContainer}>
                <CustomInput
                  id="sku"
                  inputProps={{
                    inputProps: {className: classes.largeText},
                    onChange: (e) => this.handleSearch(e),
                    placeholder: "Search SKU or product"
                  }}
                  formControlProps={{
                    fullWidth: true
                  }} />
                </div>
            </div>
          </GridItem>
          <GridItem xs={12}>
            <h4 className={classes.center}>Orders listed on this report were marked <b>Completed</b> on the date and time depicted. <br/>Individual pickups and shipping orders are not shown.</h4>
          </GridItem>
          <GridItem xs={12} className={classes.center}>
            <NavPills
              alignCenter
              color="warning"
              active={this.state.filterIndex}
              onChange={(e) => this.handleNavigationChange(e)}
              tabs={[
                {
                  tabButton: "All"
                },
                {
                  tabButton: "Group Delivery"
                },
                {
                  tabButton: "Group Pickup"
                }
              ]}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={10} className='calendar-grid'>
            <Card>
              <CardBody calendar>
                <BigCalendar
                  selectable
                  localizer={localizer}
                  events={this.state.filteredOrders}
                  components={components}
                  defaultView={this.state.selectedView}
                  views={['month', 'agenda']}
                  scrollToTime={new Date(1970, 1, 1, 6)}
                  date={this.state.selectedDate}
                  defaultDate={new Date()}
                  onNavigate={date => this.onNavigate(date)}
                  onView={view => this.onView(view)}
                  eventPropGetter={this.eventColors}
                  length={this.state.length}
                  drilldownView='agenda'
                  formats = {{
                    agendaHeaderFormat: ({start, end}) => {
                      return (moment.utc(start).format('MMMM YYYY'));
                    }
                  }}
                  popup='true'
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.selectedOrder ? (
          <OrderCard order={this.state.selectedOrder} />
        ) : null }
      </div>
    );
  }
}

EndOfDayReport.propTypes = {
  classes: PropTypes.object
};

export default withStyles(reportStyles)(withGracefulUnmount(EndOfDayReport));
