/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";

// react component used to create a calendar with events on it
import Agenda from "views/Components/Agenda.jsx";
import BigCalendar from "react-big-calendar";
import Datetime from "react-datetime";
import moment from "moment";

import Badge from "components/Badge/Badge.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardText from "components/Card/CardText.jsx";
import Close from "@material-ui/icons/Close";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import DailyAgenda from "views/Components/DailyAgenda.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Email from "@material-ui/icons/Email";
import EtaCard from "components/ETA/EtaCard.jsx";
import Fade from "@material-ui/core/Fade";
import Heading from "components/Heading/Heading.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import ProductCard from "components/Products/ProductCard.jsx";
import RefreshSpinner from "components/Spinners/RefreshSpinner.jsx";
import Slide from "@material-ui/core/Slide";
import Smartphone from "@material-ui/icons/Smartphone";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Tooltip from "@material-ui/core/Tooltip";

import api from "state/api";
import combineStyles from "assets/jss/material-dashboard-pro-react/combineStyles.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import productCardStyle from "assets/jss/material-dashboard-pro-react/views/productCardStyle.jsx";
import reportStyles from "assets/jss/material-dashboard-pro-react/views/reportStyles.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import withGracefulUnmount from "react-graceful-unmount";

const localizer = BigCalendar.momentLocalizer(moment);

const AgendaContainer = ({ onSelectEvent, classes }) => (props) => {
    return (
        <ProductAgenda
            event={props}
            onSelectEvent={onSelectEvent}
            classes={classes}
        />
    );
};

const MonthlyContainer = ({ onSelectEvent, classes }) => (props) => {
    return (
        <ProductMonthly
            event={props}
            onSelectEvent={onSelectEvent}
            classes={classes}
        />
    );
};

const ProductAgenda = React.memo((props) => {
    const { classes, event, onSelectEvent } = props;
    return (
        <div
            onClick={(e) => onSelectEvent(event.event)}
            className={classes.agendaEmbed}
        >
            {event.event.published == 1 ? (
                <div
                    className={
                        classes.publishedIndicatorRight +
                        " " +
                        classes.publishedIndicatorOn
                    }
                ></div>
            ) : (
                <div
                    className={
                        classes.publishedIndicatorRight +
                        " " +
                        classes.publishedIndicatorOff
                    }
                ></div>
            )}
            <span>
                <strong>{event.title}</strong> [{event.event.sku}]
            </span>
            <div className={classes.agendaCount}>{event.event.count}</div>
        </div>
    );
});

const ProductMonthly = React.memo((props) => {
    const { classes, event, onSelectEvent } = props;
    const end = moment(event.event.end).format("h:mm A");
    return (
        <div
            onClick={(e) => onSelectEvent(event.event)}
            className={classes.calendarEmbed}
        >
            {event.event.published == 1 ? (
                <div
                    className={
                        classes.publishedIndicatorLeft +
                        " " +
                        classes.publishedIndicatorOn
                    }
                ></div>
            ) : (
                <div
                    className={
                        classes.publishedIndicatorLeft +
                        " " +
                        classes.publishedIndicatorOff
                    }
                ></div>
            )}
            <div className={classes.monthlyCount}>{event.event.count}</div>
            <span>
                {event.title} [{event.event.sku}]
            </span>
            <div className={classes.monthlyExpiration}>{end}</div>
        </div>
    );
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

class UpInHouseReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            adminDialogInfo: [],
            customerDialogInfo: [],
            deadlineDialogInfo: [],
            newProductDialogInfo: [],
            ordersInDialogInfo: [],
            filteredProducts: [],
            length: 31,
            noteSaved: false,
            notification: null,
            products: [],
            refreshing: false,
            selectedDate: new Date(),
            selectedDeadlineDate: null,
            selectedDeadlineDateClicked: false,
            selectedEtaDate: null,
            selectedEtaDateClicked: false,
            selectedExpirationDate: null,
            selectedExpirationDateClicked: false,
            selectedProduct: null,
            selectedProductPurchasingCollections: null,
            selectedView: "month",
            settingEta: false,
            settingExpiration: false,
            settingHidden: false,
        };
        this.noteInput = React.createRef();
        this.onSelectEvent = this.onSelectEvent.bind(this);
        this.pendingNoteEdits = [];
        this.purchasingCollections = "";
        this.textEntered = "";
    }

    componentWillUnmount() {
        localStorage.setItem("UpInHouseReport", JSON.stringify(this.state));
        localStorage.setItem(
            "UpInHouseReportLocal",
            JSON.stringify({
                pendingNoteEdits: this.pendingNoteEdits,
            })
        );
    }

    componentWillMount() {
        let rehydrate = JSON.parse(localStorage.getItem("UpInHouseReport"));
        if (rehydrate) {
            rehydrate.filteredProducts = [];
            rehydrate.products = [];
            rehydrate.refreshing = false;
            rehydrate.selectedProductPurchasingCollections = [];
            rehydrate.settingEta = false;
            rehydrate.settingExpiration = false;
            rehydrate.settingHidden = false;
            rehydrate.notification = null;
            this.setState(rehydrate);
            rehydrate.selectedDate = new Date(rehydrate.selectedDate);
            rehydrate.selectedDeadlineDate = rehydrate.selectedDeadlineDate
                ? new Date(rehydrate.selectedDeadlineDate)
                : null;
            rehydrate.selectedDeadlineDateClicked = rehydrate.selectedDeadlineDateClicked
                ? new Date(rehydrate.selectedDeadlineDateClicked)
                : null;
            rehydrate.selectedEtaDate = rehydrate.selectedEtaDate
                ? new Date(rehydrate.selectedEtaDate)
                : null;
            rehydrate.selectedExpirationDate = rehydrate.selectedExpirationDate
                ? new Date(rehydrate.selectedExpirationDate)
                : null;
            rehydrate.selectedExpirationDateClicked = rehydrate.selectedExpirationDateClicked
                ? new Date(rehydrate.selectedExpirationDateClicked)
                : null;
        }

        let rehydrateLocal = JSON.parse(
            localStorage.getItem("UpInHouseReportLocal")
        );
        if (rehydrateLocal) {
            this.pendingNoteEdits = rehydrateLocal.pendingNoteEdits;
        }
    }

    componentDidMount() {
        this.loadEventsForMonthOfDate(this.state.selectedDate);
    }

    onSelectEvent(event) {
        this.pendingNoteEdits = [];
        this.setState({ noteSaved: false });
        if (this.noteInput.current) {
            this.noteInput.current.value = "";
        }

        fetch(`/api/products/${event.id}`, { credentials: "include" })
            .then((response) => api.authCheck(response))
            .then((data) => {
                var product = data["data"];

                // Break apart the permutations
                const uih = this;
                Object.keys(product.permutations).forEach((key) => {
                    var statuses = key.split("|");
                    var fulfillmentStatus = statuses[0];
                    var printTagStatus = statuses[1];
                    var purchaseStatus = statuses[2];
                    var p = product.permutations[key];
                    var prod = {};

                    prod.status = fulfillmentStatus;
                    prod.print_status = printTagStatus;
                    prod.purchase_status = purchaseStatus;
                    p.color = uih.calculateColor(prod);
                    p.color = p.color.replace(/-([a-z])/g, (x, up) =>
                        up.toUpperCase()
                    );
                });

                this.setState({ selectedProduct: product });

                if (this.noteInput.current) {
                    this.noteInput.current.value = product.note;
                }

                if (product.expires_at != null) {
                    this.setState({
                        selectedExpirationDate: moment(
                            product.expires_at,
                            "YYYY-MM-DD HH:mm:ss Z"
                        ),
                    });
                }

                if (product.eta != null) {
                    this.setState({
                        selectedEtaDate: moment(
                            product.eta,
                            "YYYY-MM-DD HH:mm:ss Z"
                        ),
                    });
                } else {
                    this.setState({
                        selectedEtaDate: moment(),
                    });
                }
            });

        fetch(`/api/products/${event.id}/purchasingCollections`, {
            credentials: "include",
        })
            .then((response) => api.authCheck(response))
            .then((data) => {
                this.setState({
                    selectedProductPurchasingCollections: data["data"],
                });
            });
    }

    onNavigate = (newDate) => {
        // Add a few days in case we jumped to the end of a month due to the length
        const date = moment(newDate).add(5, "days");
        if (this.state.selectedView == "agenda") {
            const firstDay = moment(date)
                .startOf("month")
                .toDate();
            const length = moment(date).daysInMonth();
            this.setState({ selectedDate: firstDay });
            this.setState({ length: length });
        } else {
            this.setState({ selectedDate: newDate });
        }

        this.loadEventsForMonthOfDate(date);
    };

    // Make sure the list view is displaying by month
    onView = (view) => {
        if (view == "agenda") {
            const date = this.state.selectedDate;
            const firstDay = moment(date)
                .startOf("month")
                .toDate();
            const length = moment(date).daysInMonth();
            this.setState({ selectedDate: firstDay });
            this.setState({ length: length });
        }
        this.setState({ selectedView: view });
    };

    calculateColor(prod) {
        var today = new Date();
        var tomorrow = moment(today).add(1, "days");
        var expiration = new Date(prod.end);
        var isExpiringToday =
            moment(expiration).isSame(today, "day") && expiration > today;
        var isExpiringTomorrow = moment(expiration).isSame(tomorrow, "day");
        var isExpired = expiration < today;
        var refundedItems = prod.status
            .split("|")
            .filter((status) => status === "Refunded").length;
        var purchasePendingItems = prod.purchase_status
            .split("|")
            .filter((status) => status === "Pending").length;

        var color = "expiring-later";
        if (prod.purchase_status.includes("Ready")) {
            color = "ready-to-process";
        } else if (prod.print_status.includes("Complete")) {
            color = "print-tags-complete";
        } else if (
            prod.purchase_status.includes("Complete") &&
            purchasePendingItems <= refundedItems
        ) {
            color = "purchasing-complete";
        } else if (isExpiringToday) {
            color = "expiring-today";
        } else if (isExpiringTomorrow) {
            color = "expiring-tomorrow";
        } else if (prod.status.includes("Refund Required")) {
            color = "refund-required";
        } else if (
            prod.status.includes("Refunded") &&
            !prod.status.includes("Pending")
        ) {
            color = "refund-complete";
        } else if (isExpired) {
            color = "expired-pending";
        }

        return color;
    }

    eventColors(event) {
        var backgroundColor = "event-";
        event.color
            ? (backgroundColor = backgroundColor + event.color)
            : (backgroundColor = backgroundColor + "default");
        return {
            className: backgroundColor,
        };
    }

    filterProducts(products) {
        if (this.textEntered.length == 0) {
            this.setState({ filteredProducts: products });
        } else {
            let search = this.textEntered.toLowerCase();
            let filtered = this.state.products.filter(
                (product) =>
                    product.sku.toLowerCase().includes(search) ||
                    product.title.toLowerCase().includes(search)
            );
            this.setState({ filteredProducts: filtered });
        }
    }

    loadEventsForMonthOfDate(date) {
        const dateString = moment(date).format("YYYY-MM-DD");
        fetch(`/api/products?reportType=up-in-house&date=${dateString}`, {
            credentials: "include",
        })
            .then((response) => api.authCheck(response))
            .then((data) => {
                var products = this.state.filteredProducts;
                data["data"].forEach((prod) => {
                    var color = this.calculateColor(prod);
                    var sku = prod.skus.split("|").reduce((a, b) => {
                        return a.length > b.length ? a : b;
                    }); // Get longest SKU
                    
                    if ((sku.match(/-/g) || []).length > 1) {
                        sku = sku ? sku.substring(0, sku.lastIndexOf("-")) : "";
                    }

                    // Figure out permutation count
                    var permutations = [];
                    var i;
                    const statuses = prod.status.split("|");
                    const printStatuses = prod.print_status.split("|");
                    const purchaseStatuses = prod.purchase_status.split("|");
                    for (
                        i = 0;
                        i < statuses.length &&
                        i < printStatuses.length &&
                        i < purchaseStatuses.length;
                        i++
                    ) {
                        const status = statuses[i];
                        const printStatus = printStatuses[i];
                        const purchaseStatus = purchaseStatuses[i];
                        const perm =
                            status + "|" + printStatus + "|" + purchaseStatus;
                        if (!permutations.includes(perm)) {
                            permutations.push(perm);
                        }
                    }

                    var eta = "";
                    if (prod.eta) {
                        eta = moment.utc(prod.eta).format("MM/DD/YYYY");
                    }
                    var rep = "";
                    if (prod.sales_rep) {
                        rep = prod.sales_rep;
                    }

                    const title =
                        permutations.length > 1
                            ? `[${permutations.length}] ${prod.title}`
                            : prod.title;

                    const downProduct = {
                        title: title,
                        allDay: false,
                        start: new Date(prod.end),
                        eta: eta,
                        sales_rep: rep,
                        end: new Date(prod.end),
                        color: color,
                        id: prod.id,
                        count: prod.quantity,
                        sku: sku,
                        published: prod.published,
                    };
                    products = products.filter(
                        (product) => product.id != prod.id
                    );
                    products.push(downProduct);
                });
                this.setState({ products: products });
                this.filterProducts(products);
            });
    }

    refreshCalendar() {
        this.loadEventsForMonthOfDate(this.state.selectedDate);
        this.onSelectEvent(this.state.selectedProduct);
    }

    confirmDeadlineNotification() {
        const body = { product_id: this.state.selectedProduct.id };
        const params = Object.keys(body)
            .map((key) => {
                return (
                    encodeURIComponent(key) +
                    "=" +
                    encodeURIComponent(body[key])
                );
            })
            .join("&");
        fetch("/api/notifications/customerEmails?" + params, {
            credentials: "include",
            method: "GET",
            headers: {
                "Content-Type":
                    "application/x-www-form-urlencoded;charset=UTF-8",
            },
        })
            .then((response) => api.authCheck(response))
            .then((data) => {
                if (data["status"]) {
                    var info = [];
                    info["members"] = data["data"];
                    info[
                        "collections"
                    ] = this.state.selectedProduct.collection_ids.split(",");
                    this.setState({ deadlineDialogInfo: info });
                }
            });
    }

    confirmExpirationNotification() {
        const body = { product_id: this.state.selectedProduct.id };
        const params = Object.keys(body)
            .map((key) => {
                return (
                    encodeURIComponent(key) +
                    "=" +
                    encodeURIComponent(body[key])
                );
            })
            .join("&");
        fetch("/api/notifications/adminEmails?" + params, {
            credentials: "include",
            method: "GET",
            headers: {
                "Content-Type":
                    "application/x-www-form-urlencoded;charset=UTF-8",
            },
        })
            .then((response) => api.authCheck(response))
            .then((data) => {
                if (data["status"]) {
                    if (data.data.length == 0) {
                        this.setState({ adminDialogInfo: ["NULL"] });
                    } else {
                        this.setState({ adminDialogInfo: data["data"] });
                    }
                }
            });
    }

    confirmExtensionNotification() {
        const body = { product_id: this.state.selectedProduct.id };
        const params = Object.keys(body)
            .map((key) => {
                return (
                    encodeURIComponent(key) +
                    "=" +
                    encodeURIComponent(body[key])
                );
            })
            .join("&");
        fetch("/api/notifications/customerEmails?" + params, {
            credentials: "include",
            method: "GET",
            headers: {
                "Content-Type":
                    "application/x-www-form-urlencoded;charset=UTF-8",
            },
        })
            .then((response) => api.authCheck(response))
            .then((data) => {
                if (data["status"]) {
                    this.setState({ customerDialogInfo: data["data"] });
                }
            });
    }

    confirmNewProductNotification() {
        const body = { product_id: this.state.selectedProduct.id };
        const params = Object.keys(body)
            .map((key) => {
                return (
                    encodeURIComponent(key) +
                    "=" +
                    encodeURIComponent(body[key])
                );
            })
            .join("&");
        fetch("/api/notifications/customerEmails?" + params, {
            credentials: "include",
            method: "GET",
            headers: {
                "Content-Type":
                    "application/x-www-form-urlencoded;charset=UTF-8",
            },
        })
            .then((response) => api.authCheck(response))
            .then((data) => {
                if (data["status"]) {
                    var info = [];
                    info["members"] = data["data"];
                    info[
                        "collections"
                    ] = this.state.selectedProduct.collection_ids.split(",");
                    this.setState({ newProductDialogInfo: info });
                }
            });
    }

    confirmOrdersInNotification() {
        const cids = this.state.selectedProductPurchasingCollections.join("-");
        const body = {
            collection_ids: cids,
        };
        const params = Object.keys(body)
            .map((key) => {
                return (
                    encodeURIComponent(key) +
                    "=" +
                    encodeURIComponent(body[key])
                );
            })
            .join("&");
        fetch("/api/notifications/adminEmailsForCollections?" + params, {
            credentials: "include",
            method: "GET",
            headers: {
                "Content-Type":
                    "application/x-www-form-urlencoded;charset=UTF-8",
            },
        })
            .then((response) => api.authCheck(response))
            .then((adminData) => {
                const body = { product_id: this.state.selectedProduct.id };
                const params = Object.keys(body)
                    .map((key) => {
                        return (
                            encodeURIComponent(key) +
                            "=" +
                            encodeURIComponent(body[key])
                        );
                    })
                    .join("&");
                fetch("/api/notifications/purchaserEmails?" + params, {
                    credentials: "include",
                    method: "GET",
                    headers: {
                        "Content-Type":
                            "application/x-www-form-urlencoded;charset=UTF-8",
                    },
                })
                    .then((response) => api.authCheck(response))
                    .then((data) => {
                        if (data["status"]) {
                            var info = [];
                            info["members"] = data["data"];
                            if (
                                adminData["status"] &&
                                adminData.data.length > 0
                            ) {
                                const admins = adminData["data"].filter(
                                    (el) => !data["data"].includes(el)
                                );

                                info["admins"] = admins ? admins : [];
                            } else {
                                info["admins"] = [];
                            }
                            this.setState({ ordersInDialogInfo: info });
                        }
                    });
            });
    }

    handleCloseEtaModal() {
        this.setState({ selectedEtaDateClicked: false });
        this.setState({ settingEta: false });
    }

    handleCloseExpirationModal() {
        this.setState({ selectedExpirationDateClicked: false });
        this.setState({ settingExpiration: false });
    }

    handleCloseHiddenModal() {
        this.setState({ settingHidden: false });
    }

    handleCloseNotificationModal() {
        this.setState({ adminDialogInfo: [] });
        this.setState({ customerDialogInfo: [] });
        this.setState({ deadlineDialogInfo: [] });
        this.setState({ newProductDialogInfo: [] });
        this.setState({ ordersInDialogInfo: [] });
        this.setState({ selectedDeadlineDateClicked: false });
    }

    handleDateTimeChange(date) {
        this.setState({ selectedExpirationDate: date });
        this.setState({ selectedExpirationDateClicked: true });
    }

    handleDeadlineDateTimeChange(date) {
        this.setState({ selectedDeadlineDate: date });
        this.setState({ selectedDeadlineDateClicked: true });
    }

    handleEtaDateTimeChange(date) {
        this.setState({ selectedEtaDate: date });
        this.setState({ selectedEtaDateClicked: true });
    }

    handleFulfillmentStatusChoice(newStatus, lineItemIds) {
        const data = lineItemIds.map((id) => ({
            id: id,
            app_fulfillment_status: newStatus,
        }));
        const body = { changes: data };
        fetch("/api/lineItems", {
            credentials: "include",
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        })
            .then((response) => api.authCheck(response))
            .then((data) => {
                this.showNotification(
                    `Fulfillment status has been changed to ${newStatus}.`
                );
                this.refreshCalendar();
            });
    }

    handleNoteEdit(note) {
        if (this.state.noteSaved) {
            this.setState({ noteSaved: false });
        }

        this.pendingNoteEdits.push(note);

        setTimeout(() => {
            if (this.pendingNoteEdits.length == 0) {
                return;
            }

            let edit = this.pendingNoteEdits.shift();
            if (this.pendingNoteEdits.length == 0) {
                const body = { text: edit };
                fetch(`/api/products/${this.state.selectedProduct.id}/note`, {
                    credentials: "include",
                    method: "PUT",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(body),
                }).then((data) => {
                    this.setState({ noteSaved: true });
                });
            }
        }, 2000);
    }

    handleOpenExpirationModal() {
        this.setState({ settingExpiration: true });
    }

    handlePrintTagStatusChoice(newStatus, lineItemIds) {
        const data = lineItemIds.map((id) => ({
            id: id,
            print_tags_status: newStatus,
        }));
        const body = { changes: data };
        fetch("/api/lineItems", {
            credentials: "include",
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        })
            .then((response) => api.authCheck(response))
            .then((data) => {
                this.showNotification(
                    `Print tag status has been changed to ${newStatus}.`
                );
                this.refreshCalendar();
            });
    }

    handlePublicationChoice(pubChoice) {
        const product = this.state.selectedProduct;
        const onlineStatus = product.published == 1 ? "Online" : "Offline";
        if (pubChoice != onlineStatus) {
            const pub = pubChoice == "Online" ? "publish" : "unpublish";
            fetch(`/api/products/${this.state.selectedProduct.id}/${pub}`, {
                credentials: "include",
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((response) => api.authCheck(response))
                .then((data) => {
                    this.showNotification(
                        `Product is now ${pubChoice.toLowerCase()}.`
                    );
                    this.refreshCalendar();
                });
        }
    }

    handlePurchasingStatusChoice(newStatus, lineItemIds) {
        const data = lineItemIds.map((id) => ({
            id: id,
            purchasing_status: newStatus,
        }));
        const body = { changes: data };
        fetch("/api/lineItems", {
            credentials: "include",
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        })
            .then((response) => api.authCheck(response))
            .then((data) => {
                this.showNotification(
                    `Purchasing status has been changed to ${newStatus}.`
                );
                this.refreshCalendar();
            });
    }

    handleRefresh() {
        this.setState({ refreshing: true });
        if (this.state.selectedProduct) {
            fetch(`/api/products/${this.state.selectedProduct.id}`, {
                method: "PUT",
                headers: {
                    "Content-Type":
                        "application/x-www-form-urlencoded;charset=UTF-8",
                },
            })
                .then((response) => api.authCheck(response))
                .then((data) => {
                    this.setState({ refreshing: false });
                    this.onSelectEvent(this.state.selectedProduct);
                });
        }
    }

    handleRemoveSelectedProduct() {
        if (this.state.selectedProduct) {
            fetch(`/api/products/${this.state.selectedProduct.id}/hide`, {
                method: "PUT",
                headers: {
                    "Content-Type":
                        "application/x-www-form-urlencoded;charset=UTF-8",
                },
            })
                .then((response) => api.authCheck(response))
                .then((data) => {
                    let products = this.state.products.filter(
                        (product) => product.id != this.state.selectedProduct.id
                    );
                    this.setState({ products: products });
                    this.setState({ selectedProduct: null });
                    this.setState({ settingHidden: false });
                    this.loadEventsForMonthOfDate(this.state.selectedDate);
                    this.filterProducts(products);
                });
        }
    }

    handleSaveEtaModal() {
        this.handleCloseEtaModal();

        const timestamp = this.state.selectedEtaDate.unix();
        const body = {
            value: timestamp,
            product_id: this.state.selectedProduct.id,
        };
        fetch("/api/products/metafields/eta", {
            credentials: "include",
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        })
            .then((response) => api.authCheck(response))
            .then((data) => {
                this.showNotification(
                    "ETA has been updated."
                );
                this.refreshCalendar();
            });
    }

    handleSaveExpirationModal() {
        this.handleCloseExpirationModal();

        const timestamp = this.state.selectedExpirationDate.unix();
        const body = {
            value: timestamp,
            product_id: this.state.selectedProduct.id,
        };
        fetch("/api/products/metafields/expires", {
            credentials: "include",
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        })
            .then((response) => api.authCheck(response))
            .then((data) => {
                this.showNotification(
                    "Expiration date has been updated. Be sure to toggle the product ONLINE if needed."
                );
                this.refreshCalendar();
            });
    }

    handleSearch(event) {
        const value = event.target.value;
        this.textEntered = value;
        setTimeout(() => {
            if (this.textEntered === value) {
                this.filterProducts(this.state.products);
            }
        }, 500);
        return;
    }

    handleSendDeadlineNotification() {
        const timestamp = this.state.selectedDeadlineDate.unix();
        const image = this.state.selectedProduct.product_image.replace(
            ".jpg",
            "_600x.jpg"
        );
        const body = {
            product: this.state.selectedProduct.title,
            recipients: this.state.deadlineDialogInfo["collections"],
            product_image: image,
            product_id: this.state.selectedProduct.id,
            product_title: this.state.selectedProduct.title,
            deadline: timestamp,
            expiration_date: this.state.selectedProduct.expires_at,
        };
        fetch("/api/notifications/deadline", {
            credentials: "include",
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });

        this.state.selectedProduct.last_deadline_notification = this.state.selectedDeadlineDate;
        this.showNotification(
            `Deadline push notifications have been scheduled.`
        );
        this.setState({ deadlineDialogInfo: [] });
    }

    handleSendExpirationNotification() {
        const info = this.state.adminDialogInfo;
        const orgs = new Set(info.map((i) => i.org));
        let count = 0;
        orgs.forEach((o) => {
            const orgInfo = info.filter((i) => i.org == o);
            const subcount = orgInfo[0].count ? orgInfo[0].count : 0;
            count += parseInt(subcount);
        });

        const image = this.state.selectedProduct.product_image.replace(
            ".jpg",
            "_600x.jpg"
        );
        const body = {
            product: this.state.selectedProduct.title,
            recipients: this.state.adminDialogInfo,
            product_image: image,
            product_id: this.state.selectedProduct.id,
            expiration_date: this.state.selectedProduct.expires_at,
            count: count,
        };
        fetch("/api/notifications/expiration", {
            credentials: "include",
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });

        this.state.selectedProduct.last_expiration_notification = moment().format(
            "YYYY-MM-DD HH:mm:ss"
        );
        this.showNotification(`Expiration notifications have been sent.`);
        this.setState({ adminDialogInfo: [] });
    }

    handleSendExtensionNotification() {
        const image = this.state.selectedProduct.product_image.replace(
            ".jpg",
            "_600x.jpg"
        );
        const body = {
            product: this.state.selectedProduct.title,
            recipients: this.state.customerDialogInfo,
            product_image: image,
            product_id: this.state.selectedProduct.id,
            expiration_date: this.state.selectedProduct.expires_at,
        };
        fetch("/api/notifications/extension", {
            credentials: "include",
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });

        this.state.selectedProduct.last_extension_notification = moment().format(
            "YYYY-MM-DD HH:mm:ss"
        );
        this.showNotification(`Extension notifications have been sent.`);
        this.setState({ customerDialogInfo: [] });
    }

    handleSendNewProductNotification() {
        const image = this.state.selectedProduct.product_image.replace(
            ".jpg",
            "_600x.jpg"
        );
        const body = {
            product: this.state.selectedProduct.title,
            recipients: this.state.newProductDialogInfo["collections"],
            product_image: image,
            product_id: this.state.selectedProduct.id,
            expiration_date: this.state.selectedProduct.expires_at,
        };
        fetch("/api/notifications/newProduct", {
            credentials: "include",
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });

        this.state.selectedProduct.last_new_notification = moment().format(
            "YYYY-MM-DD HH:mm:ss"
        );
        this.showNotification(`New product push notifications have been sent.`);
        this.setState({ newProductDialogInfo: [] });
    }

    handleSendOrdersInNotification() {
        const eta = this.state.selectedProduct.eta ? moment(this.state.selectedProduct.eta).format("MM/DD/YYYY") : "";
        const image = this.state.selectedProduct.product_image.replace(
            ".jpg",
            "_600x.jpg"
        );
        const body = {
            eta: eta,
            product: this.state.selectedProduct.title,
            admins: this.state.ordersInDialogInfo["admins"],
            members: this.state.ordersInDialogInfo["members"],
            product_image: image,
            product_id: this.state.selectedProduct.id,
        };
        fetch("/api/notifications/ordersIn", {
            credentials: "include",
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        this.state.selectedProduct.last_orders_in_notification = moment().format(
            "YYYY-MM-DD HH:mm:ss"
        );
        this.showNotification(`"Orders Are In" notifications have been sent.`);
        this.setState({ ordersInDialogInfo: [] });
    }

    showNotification(message) {
        this.setState({ notification: message });
        setTimeout(
            function() {
                this.setState({ notification: null });
            }.bind(this),
            3000
        );
    }

    renderEtaModal() {
        const { classes } = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal,
                }}
                open={this.state.settingEta}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.handleCloseEtaModal()}
                aria-labelledby="notice-modal-slide-title"
                aria-describedby="notice-modal-slide-description"
            >
                <DialogContent
                    id="notice-modal-slide-description"
                    className={classes.modalBody}
                >
                    <p>
                        Select the ETA for this product.
                    </p>
                    <Datetime
                        className={classes.dateTime}
                        defaultValue={moment()
                            .startOf("day")
                            .hour(9)
                            .format("MM/DD/YYYY")}
                        value={this.state.selectedEtaDate}
                        inputProps={{
                            placeholder: "Select ETA",
                            className: classes.dateTimeInput,
                            readOnly: true,
                        }}
                        timeFormat={false}
                        onChange={(e) => this.handleEtaDateTimeChange(e)}
                        onChangeRaw={(e) =>
                            this.handleEtaDateTimeChangeRaw(e.target.value)
                        }
                    />
                </DialogContent>
                <DialogActions
                    className={
                        classes.modalFooter + " " + classes.modalFooterCenter
                    }
                >
                    {this.state.selectedEtaDateClicked && (
                        <Button
                            onClick={() => this.handleSaveEtaModal()}
                            color="info"
                            round
                        >
                            Set ETA
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        );
    }

    renderExpirationModal() {
        const { classes } = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal,
                }}
                open={this.state.settingExpiration}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.handleCloseExpirationModal()}
                aria-labelledby="notice-modal-slide-title"
                aria-describedby="notice-modal-slide-description"
            >
                <DialogContent
                    id="notice-modal-slide-description"
                    className={classes.modalBody}
                >
                    <p>
                        Click below to select a new expiration date for this
                        product. If it has already expired, you will need to
                        toggle it <b>Online</b> as well.
                    </p>

                    <Datetime
                        className={classes.dateTime}
                        defaultValue={moment()
                            .startOf("day")
                            .hour(9)}
                        value={this.state.selectedExpirationDate}
                        inputProps={{
                            placeholder: "Choose a time",
                            className: classes.dateTimeInput,
                            readOnly: true,
                        }}
                        onChange={(e) => this.handleDateTimeChange(e)}
                        onChangeRaw={(e) =>
                            this.handleDateTimeChangeRaw(e.target.value)
                        }
                    />
                </DialogContent>
                <DialogActions
                    className={
                        classes.modalFooter + " " + classes.modalFooterCenter
                    }
                >
                    {this.state.selectedExpirationDateClicked && (
                        <Button
                            onClick={() => this.handleSaveExpirationModal()}
                            color="info"
                            round
                        >
                            Set Expiration Date
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        );
    }

    renderHiddenModal() {
        if (this.state.selectedProduct == null) {
            return;
        }

        const { classes } = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal,
                }}
                open={this.state.settingHidden}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.handleCloseHiddenModal()}
                aria-labelledby="notice-modal-slide-title"
                aria-describedby="notice-modal-slide-description"
            >
                <DialogContent
                    id="notice-modal-slide-description"
                    className={classes.modalBody}
                >
                    <p>
                        Are you sure you want to permanently hide{" "}
                        {this.state.selectedProduct.title} from Up In House?
                    </p>
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    <Button
                        onClick={() => this.handleRemoveSelectedProduct()}
                        color="danger"
                        round
                    >
                        Hide Product
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderNotificationModal() {
        const { classes } = this.props;
        var message = "";
        var showButton = true;
        if (this.state.adminDialogInfo.length > 0) {
            if (
                this.state.adminDialogInfo.length == 1 &&
                this.state.adminDialogInfo[0] === "NULL"
            ) {
                message =
                    "There are no admins attached to any of these organizations.";
                showButton = false;
            } else {
                const info = this.state.adminDialogInfo;
                const orgs = new Set(info.map((i) => i.org));
                orgs.forEach((o) => {
                    const orgInfo = info.filter((i) => i.org == o);
                    const admins = new Set(
                        orgInfo.map((i) => `${i.first_name} ${i.last_name}`)
                    );
                    const count = orgInfo[0].count ? orgInfo[0].count : 0;
                    message += `${o} [${count} units]: ${[...admins].join(
                        ", "
                    )}\n`;
                });
            }
        } else if (
            this.state.customerDialogInfo.length > 0 ||
            Object.keys(this.state.deadlineDialogInfo).length > 0 ||
            Object.keys(this.state.newProductDialogInfo).length > 0
        ) {
            var info = this.state.customerDialogInfo;
            if (Object.keys(this.state.deadlineDialogInfo).length > 0) {
                info = this.state.deadlineDialogInfo["members"];
            } else if (
                Object.keys(this.state.newProductDialogInfo).length > 0
            ) {
                info = this.state.newProductDialogInfo["members"];
            }
            const orgs = new Set(info.map((i) => i.org));
            orgs.forEach((o) => {
                const members = info.filter((i) => i.org == o);
                message += `${o} [${members.length} members]\n`;
            });
        }

        return (
            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal,
                }}
                open={
                    this.state.adminDialogInfo.length > 0 ||
                    this.state.customerDialogInfo.length > 0 ||
                    Object.keys(this.state.deadlineDialogInfo).length > 0 ||
                    Object.keys(this.state.newProductDialogInfo).length > 0 ||
                    Object.keys(this.state.ordersInDialogInfo).length > 0
                }
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.handleCloseNotificationModal()}
                aria-labelledby="notice-modal-slide-title"
                aria-describedby="notice-modal-slide-description"
            >
                <DialogContent
                    id="notice-modal-slide-description"
                    className={classes.modalBodyLeft}
                >
                    {this.state.adminDialogInfo.length > 0 && (
                        <p className={classes.modalBoldTitle}>
                            Send expiration notification to the admins of:
                        </p>
                    )}
                    {this.state.customerDialogInfo.length > 0 && (
                        <p className={classes.modalBoldTitle}>
                            Send extension notification to the members of:
                        </p>
                    )}
                    {Object.keys(this.state.deadlineDialogInfo).length > 0 && (
                        <div>
                            <h3 className={classes.modalTitleMarginBottom}>
                                {this.state.selectedProduct.title}
                            </h3>
                            <p className={classes.modalBoldTitleNoBorder}>
                                Schedule deadline notifications to all members
                                of the connected collections?
                            </p>
                        </div>
                    )}
                    {Object.keys(this.state.newProductDialogInfo).length >
                        0 && (
                        <div>
                            <h3 className={classes.modalTitleMarginBottom}>
                                {this.state.selectedProduct.title}
                            </h3>
                            <p className={classes.modalBoldTitleNoBorder}>
                                Send new product notification to all members of
                                the connected collections?
                            </p>
                        </div>
                    )}
                    {Object.keys(this.state.ordersInDialogInfo).length > 0 && (
                        <div>
                            <p className={classes.modalBoldTitle}>
                                Send "Orders Are In" notification to the{" "}
                                {this.state.ordersInDialogInfo["admins"].length}{" "}
                                admins of the collections listed below as well
                                as the{" "}
                                {
                                    this.state.ordersInDialogInfo["members"]
                                        .length
                                }{" "}
                                members who purchased a product. Admins will not
                                be notified twice if they also made a purchase.
                            </p>
                            <p className={classes.leftAlign}>
                                {this.purchasingCollections}
                            </p>
                        </div>
                    )}
                    {message.split("\n").map((m) => (
                        <p className={classes.leftAlign}>{m}</p>
                    ))}
                    {this.state.customerDialogInfo.length > 0 && (
                        <p className={classes.modalAlert}>
                            Make sure the expiration date is updated before
                            sending these notifications.
                        </p>
                    )}
                    {Object.keys(this.state.deadlineDialogInfo).length > 0 && (
                        <div className={classes.center}>
                            <Datetime
                                className={classes.dateTime}
                                defaultValue={moment()
                                    .startOf("day")
                                    .hour(9)}
                                value={this.state.selectedDeadlineDate}
                                inputProps={{
                                    placeholder: "Choose a time",
                                    className: classes.dateTimeInput,
                                    readOnly: true,
                                }}
                                onChange={(e) =>
                                    this.handleDeadlineDateTimeChange(e)
                                }
                                onChangeRaw={(e) =>
                                    this.handleDeadlineDateTimeChangeRaw(
                                        e.target.value
                                    )
                                }
                            />
                        </div>
                    )}
                </DialogContent>
                {showButton && (
                    <DialogActions
                        className={
                            Object.keys(this.state.deadlineDialogInfo).length >
                            0
                                ? classes.modalFooterCenter
                                : classes.modalFooter
                        }
                    >
                        {Object.keys(this.state.deadlineDialogInfo).length >
                            0 && (
                            <Button
                                onClick={() =>
                                    this.handleSendDeadlineNotification()
                                }
                                color="info"
                                round
                            >
                                Schedule Deadline Notifications
                            </Button>
                        )}
                        {this.state.adminDialogInfo.length > 0 && (
                            <Button
                                onClick={() =>
                                    this.handleSendExpirationNotification()
                                }
                                color="info"
                                round
                            >
                                Send Expiration Notifications
                            </Button>
                        )}
                        {this.state.customerDialogInfo.length > 0 && (
                            <Button
                                onClick={() =>
                                    this.handleSendExtensionNotification()
                                }
                                color="info"
                                round
                            >
                                Send Extension Notifications
                            </Button>
                        )}
                        {Object.keys(this.state.newProductDialogInfo).length >
                            0 && (
                            <Button
                                onClick={() =>
                                    this.handleSendNewProductNotification()
                                }
                                color="info"
                                round
                            >
                                Send New Product Notifications
                            </Button>
                        )}
                        {Object.keys(this.state.ordersInDialogInfo).length >
                            0 && (
                            <Button
                                onClick={() =>
                                    this.handleSendOrdersInNotification()
                                }
                                color="info"
                                round
                            >
                                Send "Orders Are In" Notifications
                            </Button>
                        )}
                    </DialogActions>
                )}
            </Dialog>
        );
    }

    renderSelectedProductCard() {
        const { classes } = this.props;
        const product = this.state.selectedProduct;
        const hasExpiration = product.expires_at != null;
        const hasDeadNotification = product.last_deadline_notification != null;
        const hasExpNotification = product.last_expiration_notification != null;
        const hasExtNotification = product.last_extension_notification != null;
        const hasNewNotification = product.last_new_notification != null;
        const hasOrdersInNotification =
            product.last_orders_in_notification != null;
        const expired = hasExpiration
            ? new Date().getTime() > new Date(product.expires_at).getTime()
            : false;
        const expiredButtonClass =
            hasExpiration && expired
                ? classes.alertButton
                : classes.actionButton;
        const onlineStatus = product.published == 1 ? "Online" : "Offline";
        const onlineColor = product.published == 1 ? "info" : "danger";

        // Highlight collections with purchases
        const allCollections = product.collections.split(", ");
        const cids = product.collection_ids.split(",");
        var purchasingCollections = [];
        var collections = [];
        var i;
        for (i = 0; i < cids.length; i++) {
            const col = allCollections[i];
            const cid = cids[i];
            const selectedCollections = this.state.selectedProductPurchasingCollections;
            if (selectedCollections && selectedCollections.includes(cid)) {
                collections.push("<b>" + col + "</b>");
                purchasingCollections.push(col);
            } else {
                collections.push(col);
            }
        }
        if (collections.length > 0) {
            collections = "Available to " + collections.join(", ");
        } else {
            collections = "";
        }
        this.purchasingCollections = purchasingCollections.join(", ");

        // Formatted dates
        const options = { year: "numeric", month: "long", day: "numeric" };
        const timeOptions = { timeStyle: "short" };
        const expiredWording = hasExpiration
            ? expired
                ? "Expired: "
                : "Expiring: "
            : "No expiration set";
        const expiredString = hasExpiration
            ? new Date(product.expires_at).toLocaleString("en-us", options) +
              " @ "
            : "";
        const expiredTimeString = hasExpiration
            ? new Date(product.expires_at).toLocaleTimeString(
                  "en-us",
                  timeOptions
              )
            : "";
        var deadNotificationString = hasDeadNotification
            ? `Last sent ${new Date(
                  product.last_deadline_notification
              ).toLocaleString("en-us", options)}`
            : "None sent";
        var expNotificationString = hasExpNotification
            ? `Last sent ${new Date(
                  product.last_expiration_notification
              ).toLocaleString("en-us", options)}`
            : "None sent";
        var extNotificationString = hasExtNotification
            ? `Last sent ${new Date(
                  product.last_extension_notification
              ).toLocaleString("en-us", options)}`
            : "None sent";
        var newNotificationString = hasNewNotification
            ? `Last sent ${new Date(
                  product.last_new_notification
              ).toLocaleString("en-us", options)}`
            : "None sent";
        var ordersInNotificationString = hasOrdersInNotification
            ? `Last sent ${new Date(
                  product.last_orders_in_notification
              ).toLocaleString("en-us", options)}`
            : "None sent";

        if (hasDeadNotification) {
            const deadlinePast =
                new Date().getTime() >
                new Date(product.last_deadline_notification).getTime();
            if (!deadlinePast) {
                const dString = hasExpiration
                    ? new Date(
                          product.last_deadline_notification
                      ).toLocaleString("en-us", options) + " @ "
                    : "";
                const dTimeString = hasExpiration
                    ? new Date(
                          product.last_deadline_notification
                      ).toLocaleTimeString("en-us", timeOptions)
                    : "";
                deadNotificationString = `Scheduled for ${dString} ${dTimeString}`;
            }
        }

        if (!product.last_deadline_notification) {
            deadNotificationString = "Never sent";
        }
        if (!product.last_expiration_notification) {
            expNotificationString = "Never sent";
        }
        if (!product.last_extension_notification) {
            extNotificationString = "Never sent";
        }
        if (!product.last_new_notification) {
            newNotificationString = "Never sent";
        }
        if (!product.last_orders_in_notification) {
            ordersInNotificationString = "Never sent";
        }

        return (
            <div>
                <Card className={classes.productCard}>
                    <div className={classes.noteHeader}>Notes</div>
                    <Input
                        classes={{}}
                        inputRef={this.noteInput}
                        disableUnderline={true}
                        multiline={true}
                        onChange={(event) =>
                            this.handleNoteEdit(event.target.value)
                        }
                        defaultValue={product.note}
                        placeholder="Enter a note"
                    />
                    <Fade in={this.state.noteSaved}>
                        <div className={classes.saveLabel}>Saved</div>
                    </Fade>
                </Card>
                <EtaCard product={this.state.selectedProduct}
                onSetEta={() =>
                    this.setState({
                        settingEta: true,
                    })
                }
                />
                <Card className={classes.productCard}>
                    <CardHeader className={classes.headerButtonContainer} text>
                        <div className={classes.headerButtons}>
                            <Tooltip
                                id="tooltip-top-start"
                                title="Remove from Up In House"
                                placement="top"
                                classes={{ tooltip: classes.tooltip }}
                            >
                                <IconButton
                                    aria-label="Close"
                                    className={classes.tableActionButtonLeft}
                                >
                                    <Close
                                        className={
                                            classes.tableActionButtonIconLeft +
                                            " " +
                                            classes.close
                                        }
                                        onClick={() =>
                                            this.setState({
                                                settingHidden: true,
                                            })
                                        }
                                    />
                                </IconButton>
                            </Tooltip>
                            <RefreshSpinner
                                onClick={() => this.handleRefresh()}
                                refreshing={this.state.refreshing}
                            />
                            <CustomDropdown
                                buttonText={onlineStatus}
                                buttonProps={{
                                    style: { margin: "3px" },
                                    color: onlineColor,
                                }}
                                dropdownList={["Offline", "Online"]}
                                onClick={(e) => this.handlePublicationChoice(e)}
                            />
                            <Button
                                simple
                                onClick={() => this.handleOpenExpirationModal()}
                                className={expiredButtonClass}
                            >
                                {expiredWording} {expiredString}
                                {expiredTimeString}
                            </Button>
                        </div>
                    </CardHeader>
                    <br />
                    <div className={classes.contentDiv}>
                        <span className={classes.productInfoSpan}>
                            {product["art_number"] > 0 ? (
                                <div>
                                    <i>Art #: {product["art_number"]}</i>
                                </div>
                            ) : null}
                            <span className={classes.textHeavy}>
                                {product["title"]}
                            </span>
                            {this.state.selectedProduct.sales_rep && this.state.selectedProduct.sales_rep.length > 0 &&
                                 <div>
                                     <b>Sales Rep:&nbsp;
                                         {this.state.selectedProduct.sales_rep}
                                     </b>
                                 </div>
                             }
                            <span>
                                {product.variants.length} Variant
                                {product.variants.length > 1 && "s"}
                            </span>
                            {collections && collections.length > 0 ? (
                                <small
                                    className={classes.textSmall}
                                    dangerouslySetInnerHTML={{
                                        __html: collections,
                                    }}
                                ></small>
                            ) : (
                                <small className={classes.textSmall}>
                                    Not available in any collections
                                </small>
                            )}
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: product["body_html"],
                                }}
                            />
                        </span>
                    </div>
                    <CardFooter chart className={classes.notificationFooter}>
                        <CardText
                            color="rose"
                            className={classes.notificationCardText}
                        >
                            <h4 className={classes.notificationTitle}>
                                Admin Notifications
                            </h4>
                            <h5 className={classes.notificationSubtitle}>
                                {expNotificationString}
                            </h5>
                            <Button
                                simple
                                color="white"
                                onClick={() =>
                                    this.confirmExpirationNotification()
                                }
                                className={classes.notificationButton}
                            >
                                <Email />
                                Send Notification
                            </Button>
                        </CardText>
                        <CardText
                            color="primary"
                            className={classes.notificationCardText}
                        >
                            <h4 className={classes.notificationTitle}>
                                Extension Notifications
                            </h4>
                            <h5 className={classes.notificationSubtitle}>
                                {extNotificationString}
                            </h5>
                            <Button
                                simple
                                color="white"
                                onClick={() =>
                                    this.confirmExtensionNotification()
                                }
                                className={classes.notificationButton}
                            >
                                <Email />
                                Send Notification
                            </Button>
                        </CardText>
                        <CardText
                            color="success"
                            className={classes.notificationCardText}
                        >
                            <h4 className={classes.notificationTitle}>
                                New Product Notifications
                            </h4>
                            <h5 className={classes.notificationSubtitle}>
                                {newNotificationString}
                            </h5>
                            <Button
                                simple
                                color="white"
                                onClick={() =>
                                    this.confirmNewProductNotification()
                                }
                                className={classes.notificationButton}
                            >
                                <Smartphone />
                                Send Notification
                            </Button>
                        </CardText>
                        <CardText
                            color="warning"
                            className={classes.notificationCardText}
                        >
                            <h4 className={classes.notificationTitle}>
                                Deadline Notifications
                            </h4>
                            <h5 className={classes.notificationSubtitle}>
                                {deadNotificationString}
                            </h5>
                            <Button
                                simple
                                color="white"
                                onClick={() =>
                                    this.confirmDeadlineNotification()
                                }
                                className={classes.notificationButton}
                            >
                                <Smartphone />
                                Send Notification
                            </Button>
                        </CardText>
                        <CardText
                            color="info"
                            className={classes.notificationCardText}
                        >
                            <h4 className={classes.notificationTitle}>
                                "Orders Are In" Notifications
                            </h4>
                            <h5 className={classes.notificationSubtitle}>
                                {ordersInNotificationString}
                            </h5>
                            <Button
                                simple
                                color="white"
                                onClick={() =>
                                    this.confirmOrdersInNotification()
                                }
                                className={classes.notificationButton}
                            >
                                <Email />
                                Send Notification
                            </Button>
                        </CardText>
                    </CardFooter>
                </Card>
            </div>
        );
    }

    render() {
        const { classes } = this.props;
        let components = {
            agenda: {
                event: AgendaContainer({
                    onSelectEvent: this.onSelectEvent,
                    classes: classes,
                }),
            },
            daily: {
                event: AgendaContainer({
                    onSelectEvent: this.onSelectEvent,
                    classes: classes,
                }),
            },
            month: {
                event: MonthlyContainer({
                    onSelectEvent: this.onSelectEvent,
                    classes: classes,
                }),
            },
        };

        var permutations = [];
        if (this.state.selectedProduct) {
            Object.keys(this.state.selectedProduct.permutations).forEach(
                (key) => {
                    permutations.push(key);
                }
            );
        }

        return (
            <div>
                <Snackbar
                    place="br"
                    color="danger"
                    message={this.state.notification}
                    open={this.state.notification != null}
                />
                <div className={classes.center}>
                    <div className={classes.innerSearchContainer}>
                        <CustomInput
                            id="sku"
                            inputProps={{
                                inputProps: { className: classes.largeText },
                                onChange: (e) => this.handleSearch(e),
                                placeholder: "Search SKU or product",
                            }}
                            formControlProps={{
                                fullWidth: true,
                            }}
                        />
                    </div>
                </div>
                <div className={classes.legend}>
                    <div className={classes.legendInner}>
                        <Badge color="expiredPendingGradient">
                            Expired (Pending)
                        </Badge>
                        <Badge color="expiringTodayGradient">
                            Expiring Today
                        </Badge>
                        <Badge color="expiringTomorrowGradient">
                            Expiring Tomorrow
                        </Badge>
                        <Badge color="expiringLaterGradient">
                            Expiring Later
                        </Badge>
                        <Badge color="readyToProcessGradient">
                            Ready to Process
                        </Badge>
                        <Badge color="purchasingCompleteGradient">
                            Purchasing Complete
                        </Badge>
                        <Badge color="printTagsCompleteGradient">
                            Print Tags Complete
                        </Badge>
                        <Badge color="refundRequiredGradient">
                            Refund Required
                        </Badge>
                        <Badge color="refundCompleteGradient">
                            Refund Complete
                        </Badge>
                    </div>
                </div>
                <GridContainer justify="center">
                    {this.renderNotificationModal()}
                    {this.renderExpirationModal()}
                    {this.renderEtaModal()}
                    {this.renderHiddenModal()}
                    <GridItem xs={12} sm={12} md={10} className="calendar-grid">
                        <Card>
                            <CardBody calendar>
                                <BigCalendar
                                    selectable
                                    localizer={localizer}
                                    events={this.state.filteredProducts}
                                    components={components}
                                    defaultView={this.state.selectedView}
                                    views={{
                                        month: true,
                                        agenda: Agenda,
                                        daily: DailyAgenda,
                                    }}
                                    scrollToTime={new Date(1970, 1, 1, 6)}
                                    date={this.state.selectedDate}
                                    defaultDate={new Date()}
                                    onSelectEvent={(event) =>
                                        this.onSelectEvent(event)
                                    }
                                    onNavigate={(date) => this.onNavigate(date)}
                                    onView={(view) => this.onView(view)}
                                    eventPropGetter={this.eventColors}
                                    length={this.state.length}
                                    drilldownView="agenda"
                                    formats={{
                                        agendaHeaderFormat: ({
                                            start,
                                            end,
                                        }) => {
                                            return moment
                                                .utc(start)
                                                .format("MMMM YYYY");
                                        },
                                    }}
                                    popup="true"
                                    messages={{ daily: "Day" }}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                {this.state.selectedProduct && (
                    <div onClick={(e) => {
                            if (e.currentTarget === e.target) {
                                this.setState({ selectedProduct: null });
                            }
                        }}
                        className={classes.productContainer}>
                        <div className={classes.productInnerContainer}>
                            {this.renderSelectedProductCard()}
                        </div>
                        <div className={classes.productInnerContainer}>
                        {permutations.map((key) => (
                            <ProductCard
                                product={this.state.selectedProduct}
                                permutation={key}
                                onFulfillmentStatusChange={(e, i) =>
                                    this.handleFulfillmentStatusChoice(e, i)
                                }
                                onPrintTagStatusChange={(e, i) =>
                                    this.handlePrintTagStatusChoice(e, i)
                                }
                                onPurchasingStatusChange={(e, i) =>
                                    this.handlePurchasingStatusChoice(e, i)
                                }
                            />
                        ))}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

UpInHouseReport.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(
    combineStyles(productCardStyle, reportStyles, modalStyle)
)(withGracefulUnmount(UpInHouseReport));
